import store from '@/store'

export default class QuestionSelect {
  constructor({
    id,
    title,
    sampleIDs,
    tagIDs,
    assessmentIDs,
    alwaysShow,
    answer,
    showQuestion,
  }) {
    this.id = id
    this.title = title
    this.sampleIDs = sampleIDs
    this.tagIDs = tagIDs
    this.assessmentIDs = assessmentIDs
    this.alwaysShow = alwaysShow
    this.answer = answer
    this.showQuestion = showQuestion
  }

  static getChimneySootQuestion() {
    const { questionsAsObject } = store.getters

    const question = questionsAsObject['Q-6']
    return new QuestionSelect({
      id: question.id,
      title: question.getTitle(),
      sampleIDs: [...question.sampleIDs],
      tagIDs: question.getTagsIDs(),
      assessmentIDs: [...question.assessmentIDs],
      alwaysShow: true,
    })
  }
}
