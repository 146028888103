import { db } from '@/firebase/init'
import Account from '@/globals/javascript/models/Account'
import { DB_ACCOUNTS, DB_HUB_ACCOUNTS } from '@/globals/javascript/models/_helper'
import { keys } from '@/globals/javascript/_util/keys'

export const account = {
  state: {
    account: null,
    allAccounts: [],
    holdensenAccount: null,
    hubAccounts: [],

    // Snapshot listeners
    unsubscribeAccount: null,
    unsubscribeAccounts: null,
    unsubscribeHubAccounts: null,
  },
  mutations: {
    updateAccount: (state, { account, unsubscribe }) => {
      state.account = account
      if (!state.unsubscribeAccount) {
        state.unsubscribeAccount = unsubscribe
      }
    },
    updateAccounts: (state, { accounts, unsubscribe }) => {
      state.allAccounts = accounts
      if (!state.unsubscribeAccounts) {
        state.unsubscribeAccounts = unsubscribe
      }
    },
    updateHoldensenAccount: (state, holdensenAccount) => {
      state.holdensenAccount = holdensenAccount
    },
    updateHubAccounts: (state, { hubAccounts, unsubscribe }) => {
      state.hubAccounts = hubAccounts

      if (!state.unsubscribeHubAccounts) {
        state.unsubscribeHubAccounts = unsubscribe
      }
    },
    resetAccount: (state) => {
      state.account = null
      if (state.unsubscribeAccount) {
        state.unsubscribeAccount()
        state.unsubscribeAccount = null
      }
    },
    resetAccounts: (state) => {
      state.allAccounts = []
      window.localStorage.removeItem(keys.LS_OVERRIDDEN_ACCOUNT_ID)
      if (state.unsubscribeAccounts) {
        state.unsubscribeAccounts()
        state.unsubscribeAccounts = null
      }
      if (state.unsubscribeHubAccounts) {
        state.unsubscribeHubAccounts()
        state.unsubscribeHubAccounts = null
      }
    },
    resetHoldensenAccount: (state) => {
      state.holdensenAccount = null
    },
  },
  actions: {
    getAccount: ({ commit, getters }) => {
      if (getters.accountLoaded) {
        return
      }

      const accountID = getters.currentUserAccountId
      const unsubscribe = db
        .collection(DB_ACCOUNTS)
        .doc(accountID)
        .onSnapshot((doc) => {
          commit('updateAccount', {
            unsubscribe,
            account: new Account({
              ...doc.data(),
              id: doc.id,
            }),
          })
        })
    },
    getAllAccounts: ({ commit, getters }) => {
      if (getters.allAccounts.length) {
        return
      }

      const unsubscribe = db
        .collection(DB_ACCOUNTS)
        .onSnapshot((querySnapshot) => {
          const accounts = []
          querySnapshot.forEach((doc) => {
            accounts.push(new Account({
              ...doc.data(),
              id: doc.id,
            }))
          })
          commit('updateAccounts', { accounts, unsubscribe })
        })
    },
    getHoldensenAccount: ({ commit, getters }) => {
      if (getters.holdensenAccount) {
        return
      }

      db
        .collection(DB_ACCOUNTS)
        .doc('bpZulvjI5VnFL9zVO0zb')
        .get()
        .then((doc) => {
          const holdensenAccount = new Account({
            ...doc.data(),
            id: doc.id,
          })

          commit('updateHoldensenAccount', holdensenAccount)
        })
    },
    getHubAccounts: ({ commit, getters }) => {
      if (getters.hubAccountsLoaded) {
        return
      }

      const unsubscribe = db
        .collection(DB_HUB_ACCOUNTS)
        .where('proAccountIDs', 'array-contains', getters.currentUser.accountID)
        .onSnapshot((querySnapshot) => {
          const hubAccounts = querySnapshot.docs.map((x) => x.data())
          commit('updateHubAccounts', { hubAccounts, unsubscribe })
        })
    },
    updateAccount: (store, { data, id }) => db
      .collection(DB_ACCOUNTS)
      .doc(id)
      .set({
        ...data,
      }, { merge: true }),
    accountChange: ({ getters }, { accountID }) => {
      if (getters.currentUser.accountID === accountID) {
        window.localStorage.removeItem(keys.LS_OVERRIDDEN_ACCOUNT_ID)
      }
      else {
        window.localStorage.setItem(keys.LS_OVERRIDDEN_ACCOUNT_ID, accountID)
      }
      window.location = window.location.origin
    },
    resetAccount: () => {
      window.localStorage.removeItem(keys.LS_OVERRIDDEN_ACCOUNT_ID)
      window.location = window.location.origin
    },
  },
  getters: {
    accountLoaded: (state) => !!state.unsubscribeAccount,
    hubAccountsLoaded: (state) => !!state.unsubscribeHubAccounts,
    currentAccount: (state) => state.account,
    nextCaseNumber: (state) => state?.account?.nextCaseNumber || 1337,
    allAccounts: (state) => state.allAccounts,
    holdensenAccount: (state) => state.holdensenAccount,
    hubAccounts: (state) => state.hubAccounts,
    hubAccountsAsObject: (state, getters) => getters.hubAccounts.reduce((prev, hubAccount) => {
      prev[hubAccount.id] = hubAccount
      return prev
    }, {}),
  },
}
