/* eslint-disable no-use-before-define */
import { union } from 'lodash-es'
import { firebase, db } from '@/firebase/init'
import { keys } from '@/globals/javascript/_util/keys'
import Screening from '@/globals/javascript/models/Screening'
import EventBus from '@/EventBus'
import {
  DB_ACCOUNTS,
  DB_ADDRESS_IMAGES,
  DB_META_DATA,
  DB_PCB_SCREENINGS,
  DB_SAMPLES,
  DB_SCREENINGS,
  DB_TEST_RESULTS,
  DB_USERS,
  DB_UNITS,
  DB_TYPES,
  DB_STANDARD_SAMPLES_STATUS,
  DB_POSTPONED_SAMPLES_STATUS,
  DB_ASSESSED_SAMPLES_STATUS,
} from '@/globals/javascript/models/_helper'
import Type from '@/globals/javascript/models/Type'
import Sample from '@/globals/javascript/models/Sample'
import { Building } from '@/globals/javascript/models/units/Building'
import { Floor } from '@/globals/javascript/models/units/Floor'
import { Apartment } from '@/globals/javascript/models/units/Apartment'

export const actions = {
  resetAllScreenings: ({ commit }) => {
    commit('resetAllLoadedScreenings')
    commit('resetCurrentScreening')
    commit('updateCrrentScreeningSelectedFilterUnitID', null)
  },
  // Getters
  getCurrentScreening: ({ commit, getters, dispatch }, screeningID) => {
    if (getters.currentScreening.id === screeningID) {
      return
    }

    // Reset current screening data
    commit('resetCurrentScreening')

    // Get screening data as listener
    const unsubscribe = db.collection(DB_SCREENINGS).doc(screeningID)
      .onSnapshot((doc) => {
        const data = new Screening({
          ...doc.data(),
          id: doc.id,
        })
        commit('updateCurrentScreening', {
          screeningID,
          unsubscribe,
          data,
        })

        // Reset related screenings
        if (getters.currentEnterpriseID !== data.enterpriseID) {
          commit('resetRelatedScreenings')
          commit('updateCurrentEnterpriseID', data.enterpriseID)
        }

        // Get related screenings if needed
        if (!getters.relatedScreeningsLoaded && data.enterpriseID) {
          dispatch('getRelatedScreenings', {
            currentScreeningID: data.id,
            enterpriseID: data.enterpriseID,
          })
        }
      })
  },
  getRelatedScreenings: ({ commit }, { currentScreeningID, enterpriseID }) => {
    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .where('enterpriseID', '==', enterpriseID)
      .where('id', '!=', currentScreeningID)
      .onSnapshot(async (querySnapshot) => {
        const relatedScreeningsPromises = querySnapshot.docs.map(async (doc) => {
          const screening = {
            data: null,
            types: [],
            samples: [],
            units: [],
            pcbScreenings: [],
            testResults: [],
            standardSamplesStatus: null,
          }

          // Get screening data
          screening.data = new Screening({ ...doc.data() })

          // Get screening types
          const typesSnapshot = await db
            .collection(DB_SCREENINGS)
            .doc(screening.data.id)
            .collection(DB_TYPES)
            .get()
          typesSnapshot.docs.forEach((doc) => {
            screening.types.push(new Type({
              ...doc.data(),
              screeningID: screening.data.id,
            }))
          })

          // Get screening samples
          const samplesSnapshot = await db
            .collection(DB_SCREENINGS)
            .doc(screening.data.id)
            .collection(DB_SAMPLES)
            .get()
          samplesSnapshot.docs.forEach((doc) => {
            screening.samples.push(new Sample({
              ...doc.data(),
              screeningID: screening.data.id,
            }))
          })

          // Get screening units
          const unitsSnapshot = await db
            .collection(DB_SCREENINGS)
            .doc(screening.data.id)
            .collection(DB_UNITS)
            .get()
          unitsSnapshot.docs.forEach((doc) => {
            const data = doc.data()
            if (data.type === 'building') {
              screening.units.push(new Building({ ...data }))
            }
            if (data.type === 'floor') {
              screening.units.push(new Floor({ ...data }))
            }
            if (data.type === 'apartment') {
              screening.units.push(new Apartment({ ...data }))
            }
          })

          // Get PCB screenings
          const pcbScreeningsSnapshot = await db
            .collection(DB_SCREENINGS)
            .doc(screening.data.id)
            .collection(DB_PCB_SCREENINGS)
            .get()

          pcbScreeningsSnapshot.docs.forEach((doc) => {
            screening.pcbScreenings.push({
              ...doc.data(),
              id: doc.id,
            })
          })

          // Get screening test results
          const testResultsSnapshot = await db
            .collection(DB_SCREENINGS)
            .doc(screening.data.id)
            .collection(DB_TEST_RESULTS)
            .get()

          testResultsSnapshot.docs.forEach((doc) => {
            screening.testResults.push(doc.data())
          })

          // Get screening standard samples status
          const standardSamplesStatusDoc = await db
            .collection(DB_SCREENINGS)
            .doc(screening.data.id)
            .collection(DB_STANDARD_SAMPLES_STATUS)
            .doc(screening.data.id)
            .get()

          if (standardSamplesStatusDoc.exists) {
            screening.standardSamplesStatus = standardSamplesStatusDoc.data()
          }
          else {
            screening.standardSamplesStatus = null
          }

          return screening
        })

        const relatedScreenings = await Promise.all(relatedScreeningsPromises)

        commit('updateRelatedScreenings', { relatedScreenings, unsubscribe })
      })
  },
  getScreeningMetaData: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningMetaDataLoaded) {
      return
    }

    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .doc(screeningID)
      .collection(DB_META_DATA)
      .doc(screeningID)
      .onSnapshot((doc) => {
        commit('updateCurrentScreeningMetaData', {
          unsubscribe,
          data: doc.data(),
        })
      })
  },
  getScreeningAddressImages: ({ commit, getters }, screeningID) => {
    if (getters.addressImagesLoaded) {
      return
    }
    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .doc(screeningID)
      .collection(DB_ADDRESS_IMAGES)
      .doc(screeningID)
      .onSnapshot((doc) => {
        commit('updateCurrentScreeningAddressImages', {
          unsubscribe,
          addressImages: doc.data(),
        })
      })
  },
  getScreeningPCBScreenings: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningPCBScreeningLoaded) {
      return
    }

    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .doc(screeningID)
      .collection(DB_PCB_SCREENINGS)
      .onSnapshot((querySnapshot) => {
        const pcbScreenings = []
        querySnapshot.forEach((doc) => {
          pcbScreenings.push({
            ...doc.data(),
            id: doc.id,
          })
        })

        commit('updateCurrentScreeningPCBScreenings', { pcbScreenings, unsubscribe })
      })
  },
  getCurrentScreeningTestResults: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningTestResultsLoaded) {
      return
    }

    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .doc(screeningID)
      .collection(DB_TEST_RESULTS)
      .onSnapshot((snapshot) => {
        commit('updateCurrentScreeningTestResults', {
          unsubscribe,
          testResults: snapshot.docs.map((doc) => doc.data()),
        })
      })
  },
  getAllScreenings: ({ commit, getters }) => {
    const accountID = getters.currentUserAccountId
    const unsubscribe = db.collection(DB_SCREENINGS)
      .where('accountID', '==', accountID)
      .orderBy('lastUpdated', 'desc')
      .onSnapshot((querySnapshot) => {
        const allScreenings = []
        querySnapshot.forEach((doc) => {
          allScreenings.push(new Screening({
            ...doc.data(),
            id: doc.id,
          }))
        })
        commit('updateAllScreenings', { allScreenings, unsubscribe })
      })
  },
  getAllScreeningsAgain: ({ commit, dispatch }) => {
    commit('resetAllLoadedScreenings')
    dispatch('getAllScreenings')
  },

  // Setters
  setCurrentScreeningID: ({ commit }, id) => {
    commit('setCurrentScreeningID', id)
  },
  checkToSetSelectedUnit: ({ commit, getters }) => {
    const savedUnitID = window.sessionStorage.getItem(keys.SS_SELECTED_UNIT_ID)

    const {
      currentScreeningSelectedFilterUnitID,
      currentScreeningData,
      selectedUnitIDs,
    } = getters

    if (!savedUnitID) {
      return
    }

    if (currentScreeningSelectedFilterUnitID) {
      return
    }

    if (!currentScreeningData?.id) {
      commit('updateCrrentScreeningSelectedFilterUnitID', null)
      return
    }

    if (!selectedUnitIDs.includes(savedUnitID)) {
      commit('updateCrrentScreeningSelectedFilterUnitID', null)
      return
    }

    commit('updateCrrentScreeningSelectedFilterUnitID', savedUnitID)
  },
  setCurrentScreeningSelectedFilterUnitID: ({ commit }, unitID) => {
    commit('updateCrrentScreeningSelectedFilterUnitID', unitID)
  },
  addScreening: ({ getters }, {
    countryCode,
    projectAddressType,
    manualData,
    BBRData,
  }) => {
    let createdByUserID = getters.currentUser.id
    if (getters.overridenAccountID) {
      // Admin and on behalf of other account, we need to set createdByUserID
      // to a person actually in account
      createdByUserID = (getters.allUsers.find(
        (user) => user.isScreener,
      ) || getters.allUsers[0]
      )?.id
    }

    const caseNumber = `${ getters.currentAccount.companyInitials }-${ getters.nextCaseNumber }`

    const screening = new Screening({
      createdByUserID,
      accountID: getters.currentAccount.id,
      caseNumber,
      useMandatoryManualCaseNumbers: getters.currentAccount.useMandatoryManualCaseNumbers,
    })

    // Set project type and options
    // - SSG
    if (screening.accountID === 'uDQYwpFGwFrwkHfaVSWg') {
      screening.projectType = 'damage-related-screening'
      screening.isSkipAmount = true
    }

    // Set country
    screening.countryCode = countryCode

    // Set address
    if (projectAddressType === 'bbr-address') {
      screening.address.isBBRAddress = true
      screening.address.address = `${
        BBRData.selectedAddress.data.adresseringsvejnavn
      } ${
        BBRData.selectedAddress.data.husnr
      }`
      screening.address.postalCode = BBRData.selectedAddress.data.postnr
      screening.address.city = BBRData.selectedAddress.data.postnrnavn
    }

    if (projectAddressType === 'manual-address') {
      screening.address.isBBRAddress = false
      screening.address.address = manualData.address
      screening.address.postalCode = manualData.postalCode
      screening.address.city = manualData.city
    }

    // Update requisition data
    screening.requisition.laboratoryID = getters.currentAccount.primaryLabID
    screening.requisition.deliveryOptionID = getters.currentAccount.analysisDeliveryTimeOptionID

    try {
      // Add screening to firebase
      db.collection(DB_SCREENINGS).doc(screening.id).set({
        ...screening,
      })

      // Update next case number
      db.collection(DB_ACCOUNTS).doc(screening.accountID).set({
        nextCaseNumber: getters.nextCaseNumber + 1,
      }, { merge: true })

      // Add meta data to screening
      db.collection(DB_SCREENINGS).doc(screening.id).collection(DB_META_DATA).doc(screening.id)
        .set({
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
          address: projectAddressType === 'bbr-address' ? BBRData.selectedAddress : {},
          projectType: screening.projectType,
        })

      EventBus.$emit('screening-is-added', screening.id)
    }
    catch (err) {
      EventBus.$emit('screening-added-failed', err)
    }
  },
  setBuilderInfo: ({ getters, dispatch }, {
    fullName, phoneNumber, email, address, postalCode, city,
  }) => {
    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_META_DATA)
      .doc(getters.currentScreening.id)
      .set({
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        builder: {
          fullName,
          phoneNumber,
          email,
          address: {
            address,
            postalCode,
            city,
          },
        },
      }, { merge: true })

    // Update screening
    const screening = new Screening({
      ...getters.currentScreeningData,
    })

    screening.builderFullName = fullName

    dispatch('updateScreening', { screening })
  },
  setNewScreeningValues: ({ getters, dispatch }, newValues) => {
    // Update screening
    const screening = new Screening({
      ...getters.currentScreeningData,
    })

    newValues.forEach((newValue) => {
      screening[newValue.propName] = newValue.value
    })

    dispatch('updateScreening', { screening })
  },
  setProjectDetails: ({ getters, dispatch }, {
    customCaseNumber,
    enterpriseID,
    selectedScreener,
    projectType,
    isScreening,
    isSkipAmount,
    market,
  }) => {
    const screening = new Screening({
      ...getters.currentScreeningData,
    })

    // Save meta data
    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_META_DATA)
      .doc(getters.currentScreening.id)
      .set({
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        projectType,
      }, { merge: true })

    // Update screening
    screening.userID = selectedScreener
    screening.projectType = projectType
    screening.customCaseNumber = customCaseNumber
    screening.enterpriseID = enterpriseID
    screening.isScreening = isScreening
    screening.isSkipAmount = isSkipAmount
    screening.market = market
    dispatch('updateScreening', { screening })
  },
  updateScreeningHUBAccounts: (
    { dispatch, getters },
    { hubAccountIDs, hubAccountItems },
  ) => {
    const screening = new Screening({
      ...getters.currentScreeningData,
    })

    screening.hubAccountIDs = hubAccountIDs
    screening.hubAccountItems = hubAccountItems

    // Update project
    dispatch('updateScreening', { screening })
  },
  setAddressImages: ({ getters, dispatch }, {
    imageList,
    place,
    imageType,
  }) => {
    // Update screening
    const { currentScreeningData } = getters
    const screening = new Screening({
      ...currentScreeningData,
    })

    // Update screening cover image
    if (place === 'overview' && imageType === 'profile') {
      if (!imageList.length && currentScreeningData.frontImage) {
        screening.frontImage = null
      }
      if (imageList.length && !currentScreeningData.frontImage) {
        const image = imageList[0]
        screening.frontImage = image
      }
    }

    if (imageType === 'floorPlans' && !currentScreeningData.isStarted) {
      dispatch('updateScreening', { screening, updateScreeningTimeUsed: true })
    }
    else if (
      imageType === 'cadastralMap'
      || (imageType === 'floorPlans' && currentScreeningData.isCompleted)
    ) {
      dispatch('updateScreening', { screening })
    }
    else {
      dispatch('updateScreening', { screening, setAsStarted: true, setAsNotCompleted: true })
    }

    // Save address images
    const createdAt = getters.addressImages
      ? getters.addressImages.createdAt
      : firebase.firestore.FieldValue.serverTimestamp()
    const dataToSave = {}

    if (place === 'overview') {
      dataToSave.overview = {
        [imageType]: imageList,
      }
    }
    else {
      dataToSave.units = {
        [place]: {
          [imageType]: imageList,
        },
      }
    }

    db.collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_ADDRESS_IMAGES)
      .doc(getters.currentScreening.id)
      .set({
        ...dataToSave,
        createdAt,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      }, { merge: true })
  },
  setScreeningPCBScreening: ({ getters, dispatch }, { data, unitID, needsTesting }) => {
    const existingItem = getters.screeningPCBScreenings
      ? getters.screeningPCBScreenings.find((x) => x.id === unitID)
      : false

    const createdAt = existingItem
      ? existingItem.createdAt
      : firebase.firestore.FieldValue.serverTimestamp()

    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_PCB_SCREENINGS)
      .doc(unitID)
      .set({
        data,
        needsTesting,
        createdAt,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      })

    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })
  },
  setSkippedCategory({ getters, dispatch }, categoryID) {
    const {
      skippedCategories,
      currentScreeningSelectedFilterUnitID,
    } = getters

    // Update screening
    const screening = new Screening({
      ...getters.currentScreeningData,
    })

    if (currentScreeningSelectedFilterUnitID) {
      screening.skippedCategories[currentScreeningSelectedFilterUnitID] = union(
        skippedCategories[currentScreeningSelectedFilterUnitID], [categoryID],
      )
    }
    else {
      screening.skippedCategories.general = union(
        skippedCategories.general, [categoryID],
      )
    }

    dispatch('updateScreening', { screening, setAsStarted: true, setAsNotCompleted: true })
  },
  setRequisitionDeliveryDayOption: ({ dispatch, getters }, value) => {
    // Update screening
    const screening = new Screening({
      ...getters.currentScreeningData,
    })

    screening.requisition.deliveryDayOptions = value

    dispatch('updateScreening', { screening })
  },
  async asyncUpdateScreening({ getters }, props) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      await db
        .collection(DB_SCREENINGS)
        .doc(getters.currentScreening.id)
        .set({
          ...props,
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true })

      resolve()
    })
  },
  updateScreening: ({ getters, dispatch }, {
    screening,
    setAsStarted,
    setAsCompleted,
    setAsNotCompleted,
    setRequisitionSent,
    setAsArchived,
    setAsActive,
    updateTestResultStatus,
    updateScreeningTimeUsed,
  }) => {
    const {
      currentScreeningData,
      currentUser,
      allUsers,
      screeningSamples,
      currentAccount,
    } = getters

    if (!screening) {
      screening = new Screening({
        ...currentScreeningData,
      })
    }
    let updateSamplingID = false

    if (setAsStarted) {
      screening.updateScreeningTimeUsed()
      screening.isStarted = true

      if (!screening.userID) {
        screening.userID = currentUser.id
      }
      if (!screening.screeningStartTime) {
        screening.screeningStartTime = firebase.firestore.FieldValue.serverTimestamp()
      }

      if (screening.isArchived) {
        screening.isArchived = false
        screening.screeningArchivedTime = null
      }
    }

    if (setAsCompleted && !screening.isCompleted) {
      screening.updateScreeningTimeUsed()
      screening.isCompleted = true

      if (!screening.screeningEndTime) {
        screening.screeningEndTime = firebase.firestore.FieldValue.serverTimestamp()
      }
    }

    if (updateScreeningTimeUsed) {
      screening.updateScreeningTimeUsed()
    }

    if (setAsNotCompleted) {
      screening.isCompleted = false
    }

    if (setRequisitionSent) {
      screening.isRequisitionSent = true
    }

    if (setAsArchived) {
      screening.isArchived = true
      screening.screeningArchivedTime = firebase.firestore.FieldValue.serverTimestamp()
    }

    if (setAsActive) {
      screening.isArchived = false
      screening.screeningArchivedTime = null
    }

    if (updateTestResultStatus) {
      screening.setTestResultsUploadStatus()
    }

    // Set leet and sampling IDs
    const user = allUsers.find((x) => x.id === screening.userID)
    if (
      setAsStarted
      && !currentScreeningData.samplingID
      && currentAccount.useSamplingID
    ) {
      const samplingID = `${
        currentAccount.companyInitials
      }-${
        user.initials
      }-${
        user.nextPersonalSamplingID
      }`
      screening.samplingID = samplingID
      updateSamplingID = true
    }

    // Set last updated
    screening.lastUpdated = firebase.firestore.FieldValue.serverTimestamp()

    // Stats logic. Look if the screening enteres a state where we need to bump a stat
    const stats = {}
    if (!screening.hasStatsTracking('created')) {
      stats.created = true
      screening.trackStats('created')
    }
    if (screening.isStarted && !screening.hasStatsTracking('started')) {
      stats.started = true
      screening.trackStats('started')
    }
    if (screening.isCompleted && !screening.hasStatsTracking('completed')) {
      stats.completed = true
      screening.trackStats('completed')

      // Set screening af billable when screening is completed with no samples
      if (screeningSamples.length === 0 && !screening.hasStatsTracking('billable')) {
        stats.billable = true
        screening.trackStats('billable')
      }
    }
    if (screening.isRequisitionSent && !screening.hasStatsTracking('requisitionSent')) {
      stats.requisitionSent = true
      screening.trackStats('requisitionSent')
    }
    if (screening.isTestDataUploaded && !screening.hasStatsTracking('billable')) {
      stats.billable = true
      screening.trackStats('billable')
    }

    db.collection(DB_SCREENINGS).doc(screening.id).set({
      ...screening,
    })

    // Update user's personal sample number
    if (updateSamplingID) {
      db.collection(DB_USERS).doc(user.id).set({
        nextPersonalSamplingID: user.nextPersonalSamplingID + 1,
      }, { merge: true })
    }

    // Update standard samples status data
    dispatch('updateSamplesStatus')

    if (Object.entries(stats).length) {
      dispatch('updateStats', { screening, stats })
    }
  },
  updateSamplesStatus: async ({ getters, dispatch }) => {
    await dispatch('forceUpdateScreeningCollections')

    const {
      standardSamplesStatus, postponedSamplesStatus, assessedSamplesStatus, currentScreening,
    } = getters

    db
      .collection(DB_SCREENINGS)
      .doc(currentScreening.id)
      .collection(DB_POSTPONED_SAMPLES_STATUS)
      .doc(currentScreening.id)
      .set({
        ...JSON.parse(JSON.stringify(postponedSamplesStatus)),
      })

    db
      .collection(DB_SCREENINGS)
      .doc(currentScreening.id)
      .collection(DB_ASSESSED_SAMPLES_STATUS)
      .doc(currentScreening.id)
      .set({
        ...JSON.parse(JSON.stringify(assessedSamplesStatus)),
      })

    // Specific upload of standard samples status
    const copyOfstandardSamplesStatus = JSON.parse(JSON.stringify(standardSamplesStatus))
    const samplesDerivedDataList = []

    // - Split data
    copyOfstandardSamplesStatus.list.forEach((listItem) => {
      samplesDerivedDataList.push({
        sampleNumber: listItem.sampleNumber,
        analyses: listItem.analyses,
        analysisTestListResults: listItem.analysisTestListResults,
      })

      delete listItem.analyses
      delete listItem.analysisTestListResults
    })

    // - Upload data with only summarised list
    const standatdSamplesStatusColRef = db
      .collection(DB_SCREENINGS)
      .doc(currentScreening.id)
      .collection(DB_STANDARD_SAMPLES_STATUS)

    await standatdSamplesStatusColRef.doc(currentScreening.id).set({
      ...JSON.parse(JSON.stringify(copyOfstandardSamplesStatus)),
    })

    // - Upload list of derived samples data in batches
    const batchList = []
    const limit = 100
    let partOfList = samplesDerivedDataList.splice(0, limit)

    // - Create batches
    while (partOfList.length) {
      const batch = db.batch()

      partOfList.forEach((listItem) => {
        batch.set(
          standatdSamplesStatusColRef
            .doc(`sampleNumber-${ listItem.sampleNumber }`),
          listItem,
        )
      })

      batchList.push(batch)

      partOfList = samplesDerivedDataList.splice(0, limit)
    }

    // - Run all batches
    const batchPromises = []
    batchList.forEach((batch) => {
      const batchPromise = batch.commit()
      batchPromises.push(batchPromise)
    })

    const results = await Promise.all(batchPromises.map((p) => p.catch((e) => e)))

    // - Detect any errors
    const errorFound = results.find((result) => (result instanceof Error))

    if (errorFound) {
      throw new Error('ERROR: Unable to create units')
    }
  },

  // Archivers
  archiveScreening: (store, id) => {
    db.collection(DB_SCREENINGS).doc(id).set({
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: true,
    }, { merge: true })
  },

  // Stats
  updateStats: ({ getters }, { screening, stats }) => {
    const account = getters.currentAccount
    let user = null
    if (screening.userID) {
      user = getters.allUsers.find((user) => user.id === screening.userID)
    }
    else if (screening.createdByUserID) {
      user = getters.allUsers.find((user) => user.id === screening.createdByUserID)
    }

    Object.keys(stats).forEach((key) => {
      stats[key] = firebase.firestore.FieldValue.increment(1)
    })

    const year = (new Date()).getFullYear()
    const month = (new Date()).getMonth() + 1
    const monthYear = `${ year }-${ month }`
    // Bump any stats on account and screener user.
    db.collection(DB_ACCOUNTS).doc(screening.accountID).set({
      overallStats: {
        ...account.overallStats || {},
        ...stats,
      },
      monthlyStats: {
        ...account.monthlyStats || {},
        [monthYear]: {
          ...account.monthlyStats?.[monthYear],
          ...stats,
        },
      },
    }, { merge: true })
    db.collection(DB_USERS).doc(user.id).set({
      overallStats: {
        ...user.overallStats || {},
        ...stats,
      },
      monthlyStats: {
        ...user.monthlyStats || {},
        [monthYear]: {
          ...user.monthlyStats?.[monthYear],
          ...stats,
        },
      },
    }, { merge: true })
  },
}
