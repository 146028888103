<template>
  <div>
    <!-- Tool bar -->
    <div class="ToolBar">
      <!-- Menu icon -->
      <div
        class="IconWrap"
        @click="onBurgerMenuClick">
        <BurgerMenuIcon />
      </div>

      <!-- Logo -->
      <router-link :to="{ name: 'AllScreenings' }">
        <img
          class="Logo"
          src="@/assets/svg/logos/milva-logo-white.svg" />
        <span
          class="DevLabel"
          v-if="isDev">Development</span>
      </router-link>

      <!-- Placeholder -->
      <div class="Placeholder"/>
    </div>
    <div/>

    <!-- Menu -->
    <SidePane
      :use-component="MobileMenu"
      :is-showing="showMobileMenu"
      @close-menu="onCloseMenu" />

  </div>
</template>

<script>
import BurgerMenuIcon from '@/components/Icons/BurgerMenuIcon.vue'
import SidePane from '@/components/SidePane.vue'
import MobileMenu from '@/components/MobileMenu/MobileMenu.vue'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      MobileMenu,
      showMobileMenu: false,
    }
  },
  computed: {
    ...mapGetters([
      'isDev',
    ]),
  },
  methods: {
    onBurgerMenuClick() {
      this.showMobileMenu = true
    },
    onCloseMenu() {
      this.showMobileMenu = false
    },
  },
  components: {
    BurgerMenuIcon,
    SidePane,
  },
}
</script>

<style lang="stylus" scoped>
  .ToolBar
    width 100%
    height 52px
    background-color var(--next-color, $color_primary)
    display flex
    align-items center
    justify-content space-between

  .IconWrap
    height 100%
    width 52px
    display flex
    align-items center
    padding-left 20px
    width 70px
    cursor pointer

  .Logo
    width 80px

  .Placeholder
    width 52px

  .DevLabel
    font-size 8px
    font-weight 700
    text-transform uppercase
    color white

  @media print
    .ToolBar
      display none
</style>
