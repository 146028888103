import { singleScreeningChecklist } from './_checklists'

export const resultRoutes = [
  {
    path: '/result/:screeningID',
    name: 'ResultOverview',
    meta: {
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/Result/Overview.vue'),
  },
  {
    path: '/result/:screeningID/requisition',
    name: 'ResultRequisition',
    meta: {
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/Result/OverviewRequisition.vue'),
  },
  {
    path: '/result/:screeningID/samples',
    name: 'ResultSamples',
    meta: {
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/Result/Samples.vue'),
  },
  {
    path: '/result/:screeningID/file-upload',
    name: 'FileUpload',
    meta: {
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/Result/FileUpload.vue'),
  },
]
