import { db } from '@/firebase/init'
import RCategoryType from '@/globals/javascript/models/resources/RCategoryType'
import RQuestion from '@/globals/javascript/models/resources/RQuestion'
import RType from '@/globals/javascript/models/resources/RType'
import { DB_RESOURCES } from '@/globals/javascript/models/_helper'
import { sortBy } from 'lodash-es'

export const resourceStore = {
  state: {
    // Loaders
    allResourcesLoaded: false,

    // Other
    areasAsArray: null,
    categoriesAsArray: null,
    categoryTypesAsArray: null,
    ewcCodesAsArray: null,
    fractionsAsArray: null,
    groupQuestionsAsArray: null,
    materialsAsArray: null,
    questionsAsArray: null,
    sampleTypesAsArray: null,
    analysisTests: null,
    analysisTestGroups: null,
    laboratories: null,
    analysisDeliveryTimeOptions: null,
    ecoToxAnalysisTestIDList: null,
    tagsAsArray: null,
    typesAsArray: null,
    wdgAsArray: null,
    categoryTypeGroupsAsArray: null,
    coatingTypesAsArray: null,
  },
  mutations: {
    updateAllResourcesLoadedState: (state, status) => {
      state.allResourcesLoaded = status
    },
    updateResource: (state, { id, data }) => {
      if (id === '-areas-') {
        state.areasAsArray = data
      }
      if (id === '-categories-') {
        state.categoriesAsArray = data
      }
      if (id === '-category-types-') {
        state.categoryTypesAsArray = data.map((x) => new RCategoryType({ ...x }))
      }
      if (id === '-ewc-codes-') {
        state.ewcCodesAsArray = data
      }
      if (id === '-fractions-') {
        state.fractionsAsArray = data
      }
      if (id === '-group-questions-') {
        state.groupQuestionsAsArray = data
      }
      if (id === '-materials-') {
        state.materialsAsArray = data
      }
      if (id === '-questions-') {
        state.questionsAsArray = data.map((x) => new RQuestion({ ...x }))
      }
      if (id === '-sample-types-') {
        state.sampleTypesAsArray = data
      }
      if (id === '-analysis-tests-') {
        state.analysisTests = data
      }
      if (id === '-analysis-test-groups-') {
        state.analysisTestGroups = data
      }
      if (id === '-laboratories-') {
        state.laboratories = data
      }
      if (id === '-analysis-delivery-time-options-') {
        state.analysisDeliveryTimeOptions = data
      }
      if (id === '-eco-tox-analysis-test-id-list-') {
        state.ecoToxAnalysisTestIDList = data
      }
      if (id === '-tags-') {
        state.tagsAsArray = data
      }
      if (id === '-types-') {
        state.typesAsArray = data.map((x) => new RType({ ...x }))
      }
      if (id === '-work-description-groups-') {
        state.wdgAsArray = data
      }
      if (id === '-category-type-groups-') {
        state.categoryTypeGroupsAsArray = data
      }
      if (id === '-coating-types-') {
        state.coatingTypesAsArray = data
      }
    },
  },
  actions: {
    async getAllResources({ commit, getters }) {
      if (getters.allResourcesLoaded) {
        return
      }

      // Get all docs
      const snapshot = await db
        .collection(DB_RESOURCES)
        .get()

      // Loop through each doc
      snapshot.docs.forEach((doc) => {
        const docData = doc.data()
        commit('updateResource', { id: doc.id, data: docData.data })
      })

      // Update loaded state
      commit('updateAllResourcesLoadedState', true)
    },
  },
  getters: {
    // Loaders
    allResourcesLoaded: (state) => state.allResourcesLoaded,

    // Other
    areasAsArray: (state) => state.areasAsArray,
    areasAsObject: (state) => state.areasAsArray.reduce((prev, area) => {
      prev[area.id] = area
      return prev
    }, {}),
    categoriesAsArray: (state) => sortBy(state.categoriesAsArray, ['areaID', 'position']),
    categoriesAsObject: (state) => state.categoriesAsArray.reduce((prev, category) => {
      prev[category.id] = category
      return prev
    }, {}),
    categoryTypesAsArray: (state) => state.categoryTypesAsArray,
    categoryTypesAsObject: (state) => state.categoryTypesAsArray.reduce((prev, categoryType) => {
      prev[categoryType.id] = categoryType
      return prev
    }, {}),
    ewcCodesAsArray: (state) => state.ewcCodesAsArray,
    fractionsAsArray: (state) => state.fractionsAsArray,
    groupQuestionsAsArray: (state) => state.groupQuestionsAsArray,
    groupQuestionsAsObject: (state) => state.groupQuestionsAsArray.reduce((prev, groupQuestion) => {
      prev[groupQuestion.id] = groupQuestion
      return prev
    }, {}),
    materialsAsArray: (state) => state.materialsAsArray,
    materialsAsObject: (state) => state.materialsAsArray.reduce((prev, material) => {
      prev[material.id] = material
      return prev
    }, {}),
    questionsAsArray: (state) => state.questionsAsArray,
    questionsAsObject: (state) => state.questionsAsArray.reduce((prev, question) => {
      prev[question.id] = question
      return prev
    }, {}),
    sampleTypesAsArray: (state) => sortBy(state.sampleTypesAsArray, ['order']),
    sampleTypesAsObject: (state) => state.sampleTypesAsArray.reduce((prev, sampleType) => {
      prev[sampleType.id] = sampleType
      return prev
    }, {}),
    analysisTestsAsArray: (state) => state.analysisTests,
    analysisTestGroupsAsArray: (state) => state.analysisTestGroups,
    laboratoriesAsArray: (state) => state.laboratories,
    activeLaboratoriesAsArray: (state, getters) => {
      // Return all active laboratories + any inactive that is selected by the account
      const { currentAccount } = getters
      const accountPrimaryLabID = currentAccount?.primaryLabID

      // Add normal labs
      const activeLabs = state.laboratories.filter((lab) => {
        if (!lab.isActive && lab.id !== accountPrimaryLabID) {
          return false
        }

        return true
      })

      // Add Milva EuroFins
      if (currentAccount.allowMilvaEuroFins) {
        const euroFinsLab = state.laboratories.find((x) => x.id === 'euroFins')
        const milvaLab = {
          ...JSON.parse(JSON.stringify(euroFinsLab)),
          id: 'milvaEuroFins',
          translation: 'LAB_MILVA_EUROFINS_TITLE',
        }
        activeLabs.unshift(milvaLab)
      }

      return activeLabs
    },
    analysisDeliveryTimeOptionsAsArray: (state) => sortBy(state.analysisDeliveryTimeOptions, ['order']),
    ecoToxAnalysisTestIDListAsArray: (state) => state.ecoToxAnalysisTestIDList,
    tagsAsArray: (state) => state.tagsAsArray,
    typesAsArray: (state) => state.typesAsArray,
    typesAsObject: (state) => state.typesAsArray.reduce((prev, type) => {
      prev[type.id] = type
      return prev
    }, {}),
    wdgAsArray: (state) => state.wdgAsArray,
    wdgAsObject: (state) => state.wdgAsArray.reduce((prev, wdg) => {
      prev[wdg.id] = wdg
      return prev
    }, {}),
    categoryTypeGroupsAsArray: (state) => state.categoryTypeGroupsAsArray,
    coatingTypesAsArray: (state) => state.coatingTypesAsArray,
    coatingTypesAsObject: (state) => state.coatingTypesAsArray.reduce((prev, coatingType) => {
      prev[coatingType.id] = coatingType
      return prev
    }, {}),
  },
}
