import moment from 'moment-with-locales-es6'
import { mixWB } from './mixins'

export const getElementDocumentPos = (elem) => {
  let offsetTop = 0
  let offsetLeft = 0
  let offsetBottom = 0
  let offsetRight = 0
  const savedElem = elem

  if (!elem) {
    return {
      top: offsetTop, left: offsetLeft, bottom: offsetBottom, right: offsetRight,
    }
  }

  do {
    if (!Number.isNaN(elem.offsetTop)) offsetTop += elem.offsetTop
    if (!Number.isNaN(elem.offsetLeft)) offsetLeft += elem.offsetLeft
  // eslint-disable-next-line no-cond-assign
  } while ((elem = elem.offsetParent) !== null)

  if (offsetTop) {
    const { height, width } = savedElem.getBoundingClientRect()
    offsetBottom = offsetTop + height
    offsetRight = offsetLeft + width
  }

  return {
    top: offsetTop, left: offsetLeft, bottom: offsetBottom, right: offsetRight,
  }
}

export const scrollTo = ({
  yPos = null,
  duration = 250,
  element = null,
  offset = 0,
  checkBetween = false,
  goToBottom = false,
}) => {
  const startingY = window.pageYOffset
  const screenEnd = startingY + window.innerHeight
  let endingY = null

  // Use yPos
  if (yPos !== null) endingY = yPos + offset

  // Use element
  if (element) {
    const elementPos = getElementDocumentPos(element)
    endingY = elementPos.top + offset

    if (goToBottom) {
      endingY = endingY + elementPos.bottom - elementPos.top - window.innerHeight
    }
  }

  if (endingY === null) {
    endingY = 0
  }

  if (checkBetween && endingY - offset > startingY && endingY - offset < screenEnd) return

  const diff = endingY - startingY
  // eslint-disable-next-line no-plusplus
  const easing = (t) => --t * t * t + 1 // Easing from: https://gist.github.com/gre/1650294
  let start

  if (!diff) return

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp
    // Elapsed miliseconds since start of scrolling.
    const time = timestamp - start
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / duration, 1)
    // Apply the easing.
    // It can cause bad-looking slow frames in browser performance tool, so be careful.
    percent = easing(percent)

    window.scrollTo(0, startingY + diff * percent)

    // Proceed with animation as long as we wanted it to.
    if (time < duration) window.requestAnimationFrame(step)
  })
}

let scrollbarWidth
export const getScrollbarWidth = () => {
  if (scrollbarWidth === undefined) {
    const div = document.createElement('div')
    div.style.width = '100px'
    div.style.height = '100px'
    div.style.position = 'absolute'
    div.style.top = '-9999'
    div.style.overflow = 'scroll'
    div.style.msOverflowStyle = 'scrollbar'

    document.body.appendChild(div)
    scrollbarWidth = div.offsetWidth - div.clientWidth
    document.body.removeChild(div)
  }

  return scrollbarWidth
}

let bodyScrollTop
// Locks the body at its current position so you can't scroll
export const preventBodyScroll = (hideScrollBar = false) => {
  document.body.noSrcoll = true
  bodyScrollTop = window.pageYOffset
  document.body.style.position = 'fixed'
  document.body.style.top = `-${ bodyScrollTop }px`

  if (hideScrollBar) {
    const htmlTag = document.getElementsByTagName('html')[0]
    htmlTag.style.overflowY = 'hidden'
    document.body.style.paddingRight = `${ getScrollbarWidth() }px`
  }
}

// Enables the body to be scrolled again
export const enableBodyScroll = () => {
  if (document.body.noSrcoll) {
    document.body.noSrcoll = false
    document.body.style.position = 'relative'
    document.body.style.top = null
    window.scrollTo(0, bodyScrollTop)

    const htmlTag = document.getElementsByTagName('html')[0]
    htmlTag.style.overflowY = 'scroll'
    document.body.style.paddingRight = '0px'
  }
}

export const getLocalStorageValue = ({ key, defaultValue }) => {
  const localValue = window.localStorage.getItem(key)

  if (localValue) {
    return JSON.parse(localValue)
  }

  return defaultValue
}

export const setLocalStorageValue = ({ key, value }) => {
  window.localStorage.setItem(key, value)
}

export const getTimeSpendText = ({
  start,
  end,
  duration = null,
}) => {
  let momentDuration = null
  if (duration) {
    momentDuration = moment.duration(duration)
  }
  else {
    const startTime = moment(start.toDate())
    const endTime = moment(end.toDate())
    momentDuration = moment.duration(endTime.diff(startTime))
  }

  const days = momentDuration.days()
  const hours = momentDuration.hours()
  const minutes = momentDuration.minutes()
  const seconds = momentDuration.seconds()

  let text = ''
  if (days) {
    text += days === 1 ? `${ mixWB('1_DAY_SHORT') } ` : `${ mixWB('X_DAYS_SHORT', [days]) } `
  }
  if (days || hours) {
    text += hours === 1 ? `${ mixWB('1_HOUR_SHORT') } ` : `${ mixWB('X_HOURS_SHORT', [hours]) } `
  }
  text += minutes === 1 ? `${ mixWB('1_MINUTE_SHORT') } ` : `${ mixWB('X_MINUTES_SHORT', [minutes]) } `
  text += seconds === 1 ? `${ mixWB('1_SECOND_SHORT') } ` : `${ mixWB('X_SECONDS_SHORT', [seconds]) } `

  return text
}

export const areArraysEqual = (a, b) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  const aCopy = JSON.parse(JSON.stringify(a)).sort()
  const bCopy = JSON.parse(JSON.stringify(b)).sort()

  for (let i = 0; i < aCopy.length; i += 1) {
    if (aCopy[i] !== bCopy[i]) return false
  }
  return true
}

export const isTouchDevice = window.matchMedia('(any-hover: none)').matches
