import { DB_CHANGELOGS, setIDOnCreate } from '@/globals/javascript/models/_helper'

export default class Changelog {
  constructor({
    id,
    version,
    versionSum,
    features,
    bugFixes,
  }) {
    // Raw data
    this.id = setIDOnCreate(id, DB_CHANGELOGS)
    this.version = version || ''
    this.versionSum = versionSum || ''
    this.features = features || ''
    this.bugFixes = bugFixes || ''
  }
}
