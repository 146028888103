<template>
  <div class="SplashScreen">
    <div class="Content">
      <img
        class="Logo"
        src="@/assets/svg/logos/milva-logo-green.svg" />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="stylus" scoped>
  .SplashScreen
    position fixed
    top 0
    left 0
    z-index $splash_screen
    display flex
    justify-content center
    align-items center
    flex-direction column
    width 100%
    height 100vh
    background-color #fff

  .Logo
    max-width 200px
    margin -40px 0px 10px 20px
</style>
