/* eslint-disable no-use-before-define */
import { sortBy, union } from 'lodash-es'

export const assessedSamplesStatus = {
  assessedSamplesStatus: (state, getters) => {
    const {
      isPageLoading,
      screeningSamples,
      sampleTypesAsArray,
      screeningTypes,
      analysisTestGroupsAsArray,
      ecoToxAnalysisTestIDListAsArray,
      analysisTestsAsArray,
    } = getters

    const {
      allResourcesLoaded,
      currentScreeningLoaded,
      currentScreeningTypesLoaded,
      currentScreeningSamplesLoaded,
      currentScreeningPCBScreeningLoaded,
      currentScreeningTestResultsLoaded,
      currentScreeningUnitsLoaded,
    } = getters

    // Find all assessed samples
    const assessedSamples = sortBy(screeningSamples.filter(
      (x) => x.assessNumber,
    ), 'assessNumber')

    // Build data structure
    const data = {
      list: [],
      usedAnalysisIDs: [],
      usedAnalysisTestGroupsIDs: [],
    }

    // Return if data is not yet ready
    if (isPageLoading) {
      return data
    }

    // Return if some data is missing
    if (
      !allResourcesLoaded
      || !currentScreeningLoaded
      || !currentScreeningTypesLoaded
      || !currentScreeningSamplesLoaded
      || !currentScreeningPCBScreeningLoaded
      || !currentScreeningTestResultsLoaded
      || !currentScreeningUnitsLoaded
    ) {
      return data
    }

    // Add each sample and its required analyzes
    addSamplesAndRequiredAnalyzes(
      data,
      assessedSamples,
      screeningTypes,
      sampleTypesAsArray,
      analysisTestGroupsAsArray,
      ecoToxAnalysisTestIDListAsArray,
    )

    // Set required analysis test group ids for each sample
    setRequiredAnalysisTestGroupIDs(data)

    // Set wdc ID and result for each sample
    setWDCIDAndResultForEachSample(data)

    // Set overlay used analysis test groups ids
    setUsedAnalysisTestGroupIDs(data, sampleTypesAsArray)

    // Set analysis test list results
    setAnalysisTestListResults(data, analysisTestsAsArray)

    return data
  },
}

function addSamplesAndRequiredAnalyzes(
  data,
  assessedSamples,
  screeningTypes,
  sampleTypesAsArray,
  analysisTestGroupsAsArray,
  ecoToxAnalysisTestIDListAsArray,
) {
  // Create analyses data object
  const analysesListTemplate = sortBy(analysisTestGroupsAsArray.map((analysisTestGroup) => {
    const analysisListItem = {
      sampleTypeID: analysisTestGroup.sampleTypeID,
      order: analysisTestGroup.order,
      groupID: analysisTestGroup.id,
      requiredTestIDs: analysisTestGroup.analysisTestIDs,
      status: {
        required: 0,
      },
    }

    return analysisListItem
  }), ['order'])

  // Populate assessed samples list
  assessedSamples.forEach((sample) => {
    const sampleItem = {
      assessNumber: sample.assessNumber,
      analyses: JSON.parse(JSON.stringify(analysesListTemplate)),
      analysisTestListResults: [],
      requiredAnalysisTestGroupIDs: [],
      result: null,
      wdcID: '',
    }

    data.list.push(sampleItem)

    const type = screeningTypes.find((x) => x.id === sample.typeID)

    if (!type) {
      // TODO: Report to sentry
      return
    }

    let sampleIDs

    // Material sample
    if (sample.kindOfSample === 'material') {
      sampleIDs = type.getMaterialSampleList({ onlyIDs: true })
    }

    // Coating sample
    if (sample.kindOfSample === 'coating') {
      sampleIDs = type.getCoatingSampleList({ onlyIDs: true })
    }

    // Dust sample
    if (sample.kindOfSample === 'dust') {
      sampleIDs = type.getDustSampleList({ onlyIDs: true })
    }

    sampleIDs.forEach((sampleID) => {
      // Add to sample item
      const sampleStatus = sampleItem.analyses.find((x) => x.sampleTypeID === sampleID)
      sampleStatus.status.required += 1

      // Add to used analyses
      data.usedAnalysisIDs = union(data.usedAnalysisIDs, [sampleID])

      // Check to set CP as required
      if (sampleID === 'ST-4' && !sampleIDs.includes('ST-5')) {
        // Add to sample entry
        const cpSampleStatus = sampleItem.analyses.find((x) => x.sampleTypeID === 'ST-5')
        cpSampleStatus.status.required += 1

        // Add to used analyses
        data.usedAnalysisIDs = union(data.usedAnalysisIDs, ['ST-5'])
      }
    })

    // Check to set eco tox as required
    sampleItem.analyses.forEach((analysis) => {
      if (!analysis.status.required) {
        return
      }

      const isFound = analysis.requiredTestIDs.find(
        (x) => ecoToxAnalysisTestIDListAsArray.includes(x),
      )

      if (isFound) {
        const ecoToxSampleStatus = sampleItem.analyses.find((x) => x.sampleTypeID === 'ecoTox')
        ecoToxSampleStatus.status.required = 1

        // Add to used analyses
        data.usedAnalysisIDs = union(data.usedAnalysisIDs, ['ecoTox'])
      }
    })
  })
}

function setRequiredAnalysisTestGroupIDs(data) {
  data.list.forEach((sampleItem) => {
    sampleItem.analyses.forEach((analysis) => {
      if (!analysis.status.required) {
        return
      }

      sampleItem.requiredAnalysisTestGroupIDs.push(analysis.groupID)
    })
  })
}

function setWDCIDAndResultForEachSample(data) {
  data.list.forEach((sampleItem) => {
    // Set wdcID
    const wdcID = sampleItem.requiredAnalysisTestGroupIDs.reduce((prev, analysisTestGroupID) => {
      if (['ASB', 'ASB_GEL_TAPE'].includes(analysisTestGroupID)) {
        if (prev === '') {
          prev = 'WDC-4'
        }
        else if (prev === 'ST-3') {
          prev = 'WDC-5'
        }

        return prev
      }

      if (prev === '') {
        prev = 'WDC-3'
      }
      else if (prev === 'WDC-4') {
        prev = 'WDC-5'
      }

      return prev
    }, '')
    sampleItem.wdcID = wdcID

    // Set result
    sampleItem.result = 2
  })
}

function setUsedAnalysisTestGroupIDs(data, sampleTypesAsArray) {
  let setUsedAnalysisTestGroupIDs = []

  data.usedAnalysisIDs.forEach((sampleTypeID) => {
    if (sampleTypeID === 'ecoTox') {
      setUsedAnalysisTestGroupIDs = union(setUsedAnalysisTestGroupIDs, ['ecoTox'])
      return
    }

    const sampleType = sampleTypesAsArray.find((x) => x.id === sampleTypeID)
    setUsedAnalysisTestGroupIDs = union(
      setUsedAnalysisTestGroupIDs, [sampleType.analysisTestGroupID],
    )
  })

  data.usedAnalysisTestGroupsIDs = setUsedAnalysisTestGroupIDs
}

function setAnalysisTestListResults(data, analysisTestsAsArray) {
  const testListItemTemplate = {
    id: '',
    translation: '',
    testType: '',
    unitType: '',
    value: '',
    result: null,
    options: {
      isTooLowToMeasure: false,
      isBelowValue: false,
      isAboveValue: false,
      isAwaiting: false,
      isPostponed: false,
      isAssessed: false,
      isNotAnalysed: false,
      isProven: false,
    },
  }

  data.list.forEach((sampleItem) => {
    sampleItem.analysisTestListResults = analysisTestsAsArray.reduce((prev, analysisTest) => {
      const testListItem = JSON.parse(JSON.stringify(testListItemTemplate))
      prev.push(testListItem)

      testListItem.id = analysisTest.id
      testListItem.translation = analysisTest.translation
      testListItem.unitType = analysisTest.unit
      testListItem.testType = analysisTest.type

      const isRequired = !!sampleItem.analyses.find(
        (x) => x.requiredTestIDs.includes(analysisTest.id) && x.status.required,
      )

      if (isRequired) {
        testListItem.result = 2
        testListItem.options.isAssessed = true
      }
      else {
        testListItem.options.isNotAnalysed = true
      }

      return prev
    }, [])
  })
}
