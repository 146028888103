/* eslint-disable no-unreachable */
import { firebase, db } from '@/firebase/init'
import {
  DB_ADDRESS_IMAGES,
  DB_INTER_CONNECTIONS,
  DB_META_DATA,
  DB_PCB_SCREENINGS,
  DB_SAMPLES,
  DB_SCREENINGS,
  DB_TEST_RESULTS,
  DB_TYPES,
  DB_UNITS,
} from '@/globals/javascript/models/_helper'
import { mixWB } from '@/globals/javascript/_util/mixins'

export const copyProjectOptions = {
  async makeDemoProject({ getters }) {
    const { currentScreeningData } = getters
    const projectID = currentScreeningData?.id

    if (!projectID) {
      return
    }

    // Get new project ID
    const docRef = db
      .collection(DB_SCREENINGS)
      .doc()
    const docID = docRef.id
    const originalProjectRef = db
      .collection(DB_SCREENINGS)
      .doc(projectID)

    // Copy and update main project doc
    const demoProject = JSON.parse(JSON.stringify(currentScreeningData))
    demoProject.id = docID
    demoProject.accountID = 'fsw2qcnTnElz0Wd546be'
    demoProject.caseNumber = 'MILVA-DEMO-PROJECT'
    demoProject.customCaseNumber = ''
    demoProject.isArchived = true
    demoProject.lastUpdated = firebase.firestore.FieldValue.serverTimestamp()
    demoProject.createdAt = {
      _nanoseconds: demoProject.createdAt.nanoseconds,
      _seconds: demoProject.createdAt.seconds,
    }
    demoProject.screeningStartTime = {
      _nanoseconds: demoProject.screeningStartTime.nanoseconds,
      _seconds: demoProject.screeningStartTime.seconds,
    }
    demoProject.screeningEndTime = {
      _nanoseconds: demoProject.screeningEndTime.nanoseconds,
      _seconds: demoProject.screeningEndTime.seconds,
    }
    demoProject.files = []
    demoProject.generatedFiles = {}
    demoProject.userID = '6oyMaKsjzjdqHAiPaGzCNukOUA13'

    // Get sub collections
    // - Address images
    const addressImagesSnapshot = await originalProjectRef
      .collection(DB_ADDRESS_IMAGES)
      .doc(projectID)
      .get()
    const addressImagesData = addressImagesSnapshot.data()

    // - Meta data
    const metaDataSnapshot = await originalProjectRef
      .collection(DB_META_DATA)
      .doc(projectID)
      .get()
    const metaDataData = metaDataSnapshot.data()

    // - Inter connections
    const interconnectionSnapshot = await originalProjectRef
      .collection(DB_INTER_CONNECTIONS)
      .get()

    // - PCB screenings
    const pcbScreeningsSnapshot = await originalProjectRef
      .collection(DB_PCB_SCREENINGS)
      .get()

    // - Samples
    const samplesSnapshot = await originalProjectRef
      .collection(DB_SAMPLES)
      .get()

    // - Test results
    const testResultsSnapshot = await originalProjectRef
      .collection(DB_TEST_RESULTS)
      .get()

    // - Types
    const typesSnapshot = await originalProjectRef
      .collection(DB_TYPES)
      .get()

    // - Units
    const unitsSnapshot = await originalProjectRef
      .collection(DB_UNITS)
      .get()

    // Update data - Anonymize
    demoProject.address.address = 'Eksempelvej 1'
    demoProject.address.postalCode = '7100'
    demoProject.address.city = 'Vejle'
    demoProject.builderFullName = ''
    delete demoProject.requisition
    metaDataData.builder.fullName = ''
    delete metaDataData.address

    // Add screening
    const batch = db.batch()
    const demoProjectRef = db.collection(DB_SCREENINGS).doc(docID)

    // - Main
    batch.set(demoProjectRef, {
      ...demoProject,
    })

    // - Address images
    batch.set(demoProjectRef.collection(DB_ADDRESS_IMAGES).doc(docID), {
      ...addressImagesData,
    })

    // - Meta data
    batch.set(demoProjectRef.collection(DB_META_DATA).doc(docID), {
      ...metaDataData,
    })

    // - Inter Connections
    interconnectionSnapshot.forEach((doc) => {
      batch.set(demoProjectRef.collection(DB_INTER_CONNECTIONS).doc(doc.id), {
        ...doc.data(),
      })
    })

    // - PCB screenings
    pcbScreeningsSnapshot.forEach((doc) => {
      batch.set(demoProjectRef.collection(DB_PCB_SCREENINGS).doc(doc.id), {
        ...doc.data(),
      })
    })

    // - Samples
    samplesSnapshot.forEach((doc) => {
      batch.set(demoProjectRef.collection(DB_SAMPLES).doc(doc.id), {
        ...doc.data(),
      })
    })

    // - Test results
    testResultsSnapshot.forEach((doc) => {
      batch.set(demoProjectRef.collection(DB_TEST_RESULTS).doc(doc.id), {
        ...doc.data(),
      })
    })

    // - Types
    typesSnapshot.forEach((doc) => {
      batch.set(demoProjectRef.collection(DB_TYPES).doc(doc.id), {
        ...doc.data(),
      })
    })

    // - Units
    unitsSnapshot.forEach((doc) => {
      batch.set(demoProjectRef.collection(DB_UNITS).doc(doc.id), {
        ...doc.data(),
      })
    })

    // Commit batch to create project
    await batch.commit()

    alert(`${ mixWB('THE_DEMO_PROJECT_WAS_CREATED') }: ${ docID }`)
  },
}
