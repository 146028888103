import pcbScreeningQuestions from '@/globals/database/PCBScreeningQuestions.json'
import workDescriptionCases from '@/globals/database/WorkDescriptionCases.json'
import places from '@/globals/database/Places.json'
import colors from '@/globals/database/Colors.json'
import { sortBy } from 'lodash-es'

export const materialData = {
  state: {
    pcbScreeningQuestions,
    workDescriptionCases,
    places,
    colors,
  },
  mutations: {},
  actions: {},
  getters: {
    pcbScreeningQuestions: (state) => state.pcbScreeningQuestions,
    workDescriptionCases: (state) => state.workDescriptionCases,
    places: (state) => state.places,
    colors: (state) => state.colors,
    // Mutated
    workDescriptionCasesAsArray: (state) => Object.keys(state.workDescriptionCases).reduce(
      (prev, key) => {
        const item = state.workDescriptionCases[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ),
    placesAsArray: (state) => Object.keys(state.places).reduce(
      (prev, key) => {
        const item = state.places[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ),
    colorsAsArray: (state) => sortBy(Object.keys(state.colors).reduce(
      (prev, key) => {
        const item = state.colors[key]
        prev.push({
          ...item,
          id: key,
        })
        return prev
      }, [],
    ), ['position']),
  },
}
