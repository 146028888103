import InterConnection from '@/globals/javascript/models/InterConnection'
import { db } from '@/firebase/init'
import {
  DB_INTER_CONNECTIONS,
  DB_SCREENINGS,
} from '@/globals/javascript/models/_helper'
import Screening from '@/globals/javascript/models/Screening'

export const interConnections = {
  getScreeningInterconnections: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningInterConnectionsLoaded) {
      return
    }

    const unsubscribe = db.collection(DB_SCREENINGS)
      .doc(screeningID).collection(DB_INTER_CONNECTIONS)
      .onSnapshot((querySnapshot) => {
        const interConnections = []
        querySnapshot.forEach((doc) => {
          interConnections.push(new InterConnection({
            ...doc.data(),
            id: doc.id,
          }))
        })

        commit('updateCurrentScreeningInterConnections', { interConnections, unsubscribe })
      })
  },
  setScreeningInterConnection: ({ getters, dispatch }, interConnection) => {
    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_INTER_CONNECTIONS)
      .doc(interConnection.id)
      .set({
        ...interConnection,
      })

    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })
  },
  deleteInterConnection: ({ getters, dispatch }, interConnectionID) => {
    db.collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_INTER_CONNECTIONS)
      .doc(interConnectionID)
      .delete()

    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })
  },
  setScreeningInterConnectionGroupAmount: ({ getters, dispatch }, groupAmounts) => {
    const { currentScreeningData } = getters
    const { interConnectionGroupAmounts } = currentScreeningData

    let newList = interConnectionGroupAmounts.filter((group) => {
      const isGoingToBeUpdated = groupAmounts.find((x) => x.id === group.id)

      return !isGoingToBeUpdated
    })

    newList = newList.concat(groupAmounts)

    const screening = new Screening({
      ...currentScreeningData,
      interConnectionGroupAmounts: newList,
    })

    dispatch('updateScreening', { screening, setAsStarted: true, setAsNotCompleted: true })
  },
  deleteScreeningInterConnectionGroupAmount: ({ getters, dispatch }, groupAmount) => {
    const { currentScreeningData } = getters
    const { interConnectionGroupAmounts } = currentScreeningData

    const newList = interConnectionGroupAmounts.filter((x) => x.id !== groupAmount.id)

    const screening = new Screening({
      ...currentScreeningData,
      interConnectionGroupAmounts: newList,
    })

    dispatch('updateScreening', { screening, setAsStarted: true, setAsNotCompleted: true })
  },
}
