import { mixWB } from '@/globals/javascript/_util/mixins'
import store from '@/store'
import QuestionSelect from '@/globals/javascript/models/QuestionSelect'

export default class RType {
  constructor({
    id,
    translation,
    wdgID,
    materialIDs,
    materialData,
    questions,
    sampleIDs,
    options,
  }) {
    this.id = id
    this.translation = translation || ''
    this.wdgID = wdgID || ''
    this.materialIDs = materialIDs || []
    this.materialData = materialData || {}
    this.questions = questions || []
    this.sampleIDs = sampleIDs || []
    this.options = {
      isNeverAlone: options.isNeverAlone || false,
      isNotRecyclable: options.isNotRecyclable || false,
      isArchived: options.isArchived || false,
    }
  }

  getFirstResourceMaterial() {
    const { materialsAsObject } = store.getters

    return materialsAsObject[this.materialIDs[0]]
  }

  getTitle() {
    return mixWB(this.translation)
  }

  getQuestionsToAsk() {
    const { questionsAsObject, groupQuestionsAsObject } = store.getters

    const questions = this.questions.reduce((prev, typeQuestion) => {
      // Group question
      if (typeQuestion.questionID.includes('GQ-')) {
        const groupQuestion = groupQuestionsAsObject[typeQuestion.questionID]
        groupQuestion.questions.forEach((questionFromGroup, index) => {
          const question = questionsAsObject[questionFromGroup.questionID]
          prev.push(new QuestionSelect({
            id: question.id,
            title: question.getTitle(),
            sampleIDs: [...question.sampleIDs],
            tagIDs: question.getTagsIDs(),
            assessmentIDs: [...question.assessmentIDs],
            alwaysShow: index === 0 ? typeQuestion.alwaysShow : questionFromGroup.alwaysShow,
          }))
        })
        return prev
      }

      // Single question
      const question = questionsAsObject[typeQuestion.questionID]
      prev.push(new QuestionSelect({
        id: question.id,
        title: question.getTitle(),
        sampleIDs: [...question.sampleIDs],
        tagIDs: question.getTagsIDs(),
        assessmentIDs: [...question.assessmentIDs],
        alwaysShow: typeQuestion.alwaysShow,
      }))
      return prev
    }, [])

    return questions
  }
}
