import { db } from '@/firebase/init'
import { DB_COUNTRIES } from '@/globals/javascript/models/_helper'

export const countryStore = {
  state: {
    countries: [],
  },
  mutations: {
    updateCountries: (state, countries) => {
      state.countries = countries
    },
  },
  actions: {
    async getCountries({ getters, commit }) {
      if (getters.countriesLoaded) {
        return
      }

      const snapshot = await db.collection(DB_COUNTRIES).get()
      const countries = snapshot.docs.map((doc) => ({
        ...doc.data(),
      }))
      commit('updateCountries', countries)
    },
  },
  getters: {
    countriesLoaded: (state) => !!state.countries.length,
    allCountries: (state) => state.countries,
    activeCountries: (state) => state.countries.filter((x) => x.isActive),
  },
}
