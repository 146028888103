<template>
  <div
    v-if="text"
    class="DemoNotice">
    <span v-html="text"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DemoNotice',
  computed: {
    ...mapGetters([
      'currentUser',
      'isDemoUser',
    ]),
    daysRemaining() {
      if (this.isDemoUser && this.currentUser?.expiryDate) {
        const now = new Date().getTime()
        const end = new Date(this.currentUser.expiryDate)

        if (now > end) {
          return 0
        }

        const remainingTime = end - now
        const days = remainingTime / 1000 / 60 / 60 / 24
        return Math.ceil(days)
      }
      return 0
    },
    text() {
      if (!this.daysRemaining) {
        return ''
      }
      if (this.daysRemaining === 1) {
        return this.mixWB('ACCESS_EXPIRES_TODAY_TEXT')
      }
      if (this.daysRemaining === 2) {
        return this.mixWB('ACCESS_EXPIRES_TOMORROW_TEXT')
      }
      return this.mixWB('ACCESS_EXPIRES_X_DAYS_TEXT', [this.daysRemaining])
    },
  },
}
</script>

<style lang="stylus" scoped>
  .DemoNotice
    display block
    height 30px
    background-color $color_yellow_light
    flex-center-children()
</style>
