import { db } from '@/firebase/init'
import Type from '@/globals/javascript/models/Type'
import {
  DB_PCB_SCREENINGS,
  DB_SAMPLES, DB_SCREENINGS, DB_TEST_RESULTS, DB_TYPES, DB_UNITS,
} from '@/globals/javascript/models/_helper'

export const forceUpdateStore = {
  forceUpdateScreeningCollections: async ({ commit, getters }) => {
    const {
      currentScreening,
    } = getters

    const promises = []

    // Types
    const typesPromise = await db
      .collection(DB_SCREENINGS)
      .doc(currentScreening.id)
      .collection(DB_TYPES)
      .get({
        source: 'cache',
      })

    promises.push({
      id: 'types',
      promise: typesPromise,
    })

    // Samples
    const samplesPromise = await db
      .collection(DB_SCREENINGS)
      .doc(currentScreening.id)
      .collection(DB_SAMPLES)
      .get({
        source: 'cache',
      })

    promises.push({
      id: 'samples',
      promise: samplesPromise,
    })

    // Test results
    const testResultsPromise = await db
      .collection(DB_SCREENINGS)
      .doc(currentScreening.id)
      .collection(DB_TEST_RESULTS)
      .get({
        source: 'cache',
      })

    promises.push({
      id: 'testResults',
      promise: testResultsPromise,
    })

    // Units
    const unitsPromise = await db
      .collection(DB_SCREENINGS)
      .doc(currentScreening.id)
      .collection(DB_UNITS)
      .get({
        source: 'cache',
      })

    promises.push({
      id: 'units',
      promise: unitsPromise,
    })

    // PCB screenings
    const pcbScreeningsPromise = await db
      .collection(DB_SCREENINGS)
      .doc(currentScreening.id)
      .collection(DB_PCB_SCREENINGS)
      .get({
        source: 'cache',
      })

    promises.push({
      id: 'pcbScreenings',
      promise: pcbScreeningsPromise,
    })

    const results = await Promise.all(promises.map((x) => x.promise))

    results.forEach((result, index) => {
      const resultID = promises[index].id

      // Types
      if (resultID === 'types') {
        const types = []
        result.docs.forEach((doc) => {
          types.push(new Type({
            ...doc.data(),
            id: doc.id,
          }))
        })

        commit('updateCurrentScreeningTypes', { types })
      }

      // Samples
      if (resultID === 'samples') {
        const samples = []
        result.docs.forEach((doc) => {
          samples.push({
            ...doc.data(),
            id: doc.id,
          })
        })

        commit('updateCurrentScreeningSamples', { samples })
      }

      // Test results
      if (resultID === 'testResults') {
        commit('updateCurrentScreeningTestResults', {
          testResults: result.docs.map((doc) => doc.data()),
        })
      }

      // Units
      if (resultID === 'units') {
        const units = []
        result.forEach((doc) => {
          units.push(doc.data())
        })
        commit('updateCurrentScreeningUnits', { units })
      }

      // PCB screenings
      if (resultID === 'pcbScreenings') {
        const pcbScreenings = []
        result.forEach((doc) => {
          pcbScreenings.push({
            ...doc.data(),
            id: doc.id,
          })
        })

        commit('updateCurrentScreeningPCBScreenings', { pcbScreenings })
      }
    })

    return true
  },
}
