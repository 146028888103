import Vue from 'vue'
import { firebase, db } from '@/firebase/init'
import * as Sentry from '@sentry/browser'
import { DB_USERS } from '@/globals/javascript/models/_helper'
import User from '@/globals/javascript/models/User'
import { keys } from '@/globals/javascript/_util/keys'
import router from '@/router'

export const user = {
  state: {
    allUsers: [],
    currentUser: null,

    // Snapshot listeners
    unsubscribeCurrentUser: null,
    unsubscribeAllUsers: null,
  },
  mutations: {
    resetCurrentUser: (state) => {
      state.currentUser = null
      state.isLoggedIn = false
      if (state.unsubscribeCurrentUser) {
        state.unsubscribeCurrentUser()
        state.unsubscribeCurrentUser = null
      }
    },
    resetAllUsers: (state) => {
      state.allUsers = []
      if (state.unsubscribeAllUsers) {
        state.unsubscribeAllUsers()
        state.unsubscribeAllUsers = null
      }
    },
    updateAllUsers: (state, { allUsers, unsubscribe }) => {
      state.allUsers = allUsers
      if (!state.unsubscribeAllUsers) {
        state.unsubscribeAllUsers = unsubscribe
      }
    },
    updateCurrentUser: (state, { currentUser, unsubscribe }) => {
      Vue.set(state, 'currentUser', currentUser)
      if (!state.unsubscribeCurrentUser) {
        state.unsubscribeCurrentUser = unsubscribe
      }

      // Sentry
      Sentry.setTag('user-id', state.currentUser.userID || null)
      Sentry.setTag('user-email', state.currentUser.email || null)
    },

  },
  actions: {
    getAllUsers: ({ commit, getters }) => {
      if (getters.allUsersLoaded) {
        return
      }

      const accountID = getters.currentUserAccountId
      const unsubscribe = db.collection(DB_USERS)
        .where('accountID', '==', accountID ?? null)
        .onSnapshot((querySnapshot) => {
          const allUsers = []
          querySnapshot.forEach((doc) => {
            allUsers.push(new User({
              ...doc.data(),
              id: doc.id,
            }))
          })
          commit('updateAllUsers', { allUsers, unsubscribe })
        })
    },
    getCurrentUser: ({ commit, getters, dispatch }, user) => {
      if (getters.currentUserLoaded) {
        return
      }

      const unsubscribe = db.collection(DB_USERS).doc(user.uid).onSnapshot((doc) => {
        const currentUser = new User({
          ...doc.data(),
          id: user.uid,
        })

        commit('updateCurrentUser', { currentUser, unsubscribe })
        dispatch('getHoldensenAccount')

        if (currentUser.isAdmin) {
          dispatch('getAllAccounts')
        }

        if (!doc.metadata.fromCache && !currentUser.isActive) {
          router.push({ name: 'LogOut' })
        }
      })
    },
    updateUser: (store, { user }) => db.collection(DB_USERS)
      .doc(user.id)
      .set({
        ...user,
      }, { merge: true }),
    updateUserLastSeen: ({ getters, dispatch }) => {
      dispatch('updateUser', {
        user: {
          id: getters.currentUser.id,
          lastSeen: firebase.firestore.FieldValue.serverTimestamp(),
        },
      })
    },
  },
  getters: {
    allUsersLoaded: (state) => !!state.unsubscribeAllUsers,
    currentUserLoaded: (state) => !!state.unsubscribeCurrentUser,
    allUsers: (state) => state.allUsers,
    currentUser: (state) => state.currentUser,
    isDemoUser: (state) => {
      const { currentUser } = state

      if (currentUser?.restrictions?.isAdmin || currentUser?.restrictions?.isSuperUser) {
        return false
      }

      return currentUser?.accountID === 'fsw2qcnTnElz0Wd546be'
    },
    overridenAccountID: (state) => state.currentUser?.isAdmin
      && window.localStorage.getItem(keys.LS_OVERRIDDEN_ACCOUNT_ID),
    currentUserAccountId: (state, getters) => {
      if (getters.overridenAccountID) {
        return getters.overridenAccountID
      }

      return state.currentUser.accountID ?? null
    },
    currentUserRestrictions: (state) => state.currentUser.restrictions,
  },
}
