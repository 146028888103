/* eslint-disable no-use-before-define */
import { sortBy, union } from 'lodash-es'

export const standardSamplesStatus = {
  standardSamplesStatus: (state, getters) => {
    const {
      isPageLoading,
      currentScreeningData,
      screeningSamples,
      sampleTypesAsArray,
      screeningTypes,
      screeningTestResults,
      analysisTestGroupsAsArray,
      analysisTestsAsArray,
      isRunningMigration,
    } = getters

    const {
      allResourcesLoaded,
      currentScreeningLoaded,
      currentScreeningTypesLoaded,
      currentScreeningSamplesLoaded,
      currentScreeningPCBScreeningLoaded,
      currentScreeningTestResultsLoaded,
      currentScreeningUnitsLoaded,
    } = getters

    // Find all standard samples
    const standardSamples = sortBy(screeningSamples.filter((x) => x.sampleNumber), 'sampleNumber')

    // Build data structure
    // - Summary opbject
    const summary = sortBy(analysisTestGroupsAsArray.map((analysisTestGroup) => ({
      sampleTypeID: analysisTestGroup.sampleTypeID,
      order: analysisTestGroup.order,
      groupID: analysisTestGroup.id,
      translation: analysisTestGroup.translation,
      status: {
        required: [],
        complete: [],
        missing: [],
        unintentional: [],
      },
    })), ['order'])

    // - Main data object
    const data = {
      list: [],
      summary,
      totals: {
        required: 0,
        complete: 0,
        missing: 0,
        unintentional: 0,
      },
      usedAnalysisIDs: [],
      usedAnalysisTestGroupsIDs: [],
      isCompleted: false,
    }

    // Return if data is not yet ready
    if (isPageLoading) {
      return data
    }

    // Return if some data is missing
    if (
      !allResourcesLoaded
      || !currentScreeningLoaded
      || !currentScreeningTypesLoaded
      || !currentScreeningSamplesLoaded
      || !currentScreeningPCBScreeningLoaded
      || !currentScreeningTestResultsLoaded
      || !currentScreeningUnitsLoaded
    ) {
      return data
    }

    // Check if project is old and is already completed
    const isMigrationAndCompletedProject = isRunningMigration
      && currentScreeningData.isTestDataUploaded

    // Add each sample and its required analyzes
    addSamplesAndRequiredAnalyzes(
      data,
      standardSamples,
      screeningTypes,
      analysisTestGroupsAsArray,
    )

    // Check each test result and add status
    checkTestResultsAndAddStatus(
      data,
      screeningTestResults,
      isMigrationAndCompletedProject,
    )

    // Sort summary lists
    sortSummaryLists(data.summary)

    // Make summary
    makeAnalyzesStatusSummary(data)

    // Set required analysis test group ids for each sample
    setRequiredAnalysisTestGroupIDs(data)

    // Set used analysis test groups ids
    setUsedAnalysisTestGroupIDs(data, sampleTypesAsArray)

    // Set overall completed status
    setOverallCompletedStatus(data)

    // Set analysis test list results
    setAnalysisTestListResults(data, analysisTestsAsArray, screeningTestResults)

    return data
  },
}

function addSamplesAndRequiredAnalyzes(
  data,
  standardSamples,
  screeningTypes,
  analysisTestGroupsAsArray,
) {
  // Create analyses data object
  const analysesListTemplate = sortBy(analysisTestGroupsAsArray.map((analysisTestGroup) => {
    const analysisListItem = {
      sampleTypeID: analysisTestGroup.sampleTypeID,
      order: analysisTestGroup.order,
      groupID: analysisTestGroup.id,
      requiredTestIDs: analysisTestGroup.analysisTestIDs,
      result: null,
      status: {
        required: 0,
        complete: 0,
        missing: 0,
        unintentional: 0,
      },
    }

    return analysisListItem
  }), ['order'])

  // Populate standard samples list
  standardSamples.forEach((sample) => {
    const sampleItem = {
      sampleNumber: sample.sampleNumber,
      analyses: JSON.parse(JSON.stringify(analysesListTemplate)),
      analysisTestListResults: [],
      requiredAnalysisTestGroupIDs: [],
      isCompleted: false,
      result: null,
      wdcID: '',
    }

    data.list.push(sampleItem)

    const type = screeningTypes.find((x) => x.id === sample.typeID)

    if (!type) {
      // TODO: Report to sentry
      return
    }

    let sampleIDs

    // Material sample
    if (sample.kindOfSample === 'material') {
      sampleIDs = type.getMaterialSampleList({ onlyIDs: true })
    }

    // Coating sample
    if (sample.kindOfSample === 'coating') {
      sampleIDs = type.getCoatingSampleList({ onlyIDs: true })
    }

    // Dust sample
    if (sample.kindOfSample === 'dust') {
      sampleIDs = type.getDustSampleList({ onlyIDs: true })
    }

    sampleIDs.forEach((sampleID) => {
      // Add to sample item
      const sampleStatus = sampleItem.analyses.find((x) => x.sampleTypeID === sampleID)
      sampleStatus.status.required += 1
      sampleStatus.status.missing += 1

      // Add to summary
      const summaryAnalysis = data.summary.find((x) => x.sampleTypeID === sampleID)
      summaryAnalysis.status.required.push(sampleItem.sampleNumber)
      summaryAnalysis.status.missing.push(sampleItem.sampleNumber)

      // Add to used analyses
      data.usedAnalysisIDs = union(data.usedAnalysisIDs, [sampleID])

      // Check to set CP screening as required
      if (sampleID === 'ST-4' && !sampleIDs.includes('ST-5')) {
        // Add to sample entry
        const cpScreeningSampleStatus = sampleItem.analyses.find((x) => x.sampleTypeID === 'cpScreening')
        cpScreeningSampleStatus.status.required += 1
        cpScreeningSampleStatus.status.missing += 1

        // Add to summary
        const cpScreeningSummaryAnalysis = data.summary.find((x) => x.sampleTypeID === 'cpScreening')
        cpScreeningSummaryAnalysis.status.required.push(sampleItem.sampleNumber)
        cpScreeningSummaryAnalysis.status.missing.push(sampleItem.sampleNumber)

        // Add to used analyses
        data.usedAnalysisIDs = union(data.usedAnalysisIDs, ['cpScreening'])
      }
    })
  })
}

function checkTestResultsAndAddStatus(
  data,
  screeningTestResults,
  isMigrationAndCompletedProject,
) {
  data.list.forEach((sampleItem) => {
    const testResult = screeningTestResults.find((x) => x.sampleNumber === sampleItem.sampleNumber)

    if (!testResult) {
      return
    }

    // Update each analysis
    sampleItem.analyses.forEach((analysis) => {
      // Case: 7-metals - Check to ignore when 8-metals are selected
      if (analysis.sampleTypeID === 'ST-2') {
        const analysis8Metals = sampleItem.analyses.find((x) => x.sampleTypeID === 'ST-8')

        if (analysis8Metals.status.required) {
          return
        }
      }

      let isComplete = true
      let result = null
      analysis.requiredTestIDs.forEach((testID) => {
        const test = testResult.results.find((x) => x.id === testID)

        if (!test || (test && test.result === null)) {
          isComplete = false
          return
        }

        // Update result
        if (result === null) {
          result = test.result
        }
        else if (test.result > result) {
          result = test.result
        }
      })

      if (isComplete) {
        const summaryAnalysis = data.summary.find((x) => x.sampleTypeID === analysis.sampleTypeID)

        if (analysis.status.required) {
          // Add to used analyses
          data.usedAnalysisIDs = union(data.usedAnalysisIDs, [analysis.sampleTypeID])

          // Add result
          analysis.result = result

          // Add to sample entry
          analysis.status.complete += 1
          analysis.status.missing -= 1

          // Add to summary
          summaryAnalysis.status.complete.push(sampleItem.sampleNumber)
          const sampleNumberIndex = summaryAnalysis.status.missing.findIndex(
            (x) => x === sampleItem.sampleNumber,
          )
          if (sampleNumberIndex > -1) {
            summaryAnalysis.status.missing.splice(sampleNumberIndex, 1)
          }
        }
        else if (isMigrationAndCompletedProject) {
          // Add to used analyses
          data.usedAnalysisIDs = union(data.usedAnalysisIDs, [analysis.sampleTypeID])

          // Add result
          analysis.result = result

          // Add to sample entry
          analysis.status.required = 1
          analysis.status.complete = 1
          analysis.status.missing = 0
          analysis.status.unintentional = 0
        }
        else if (analysis.sampleTypeID !== 'cpScreening') {
          // Add to used analyses
          data.usedAnalysisIDs = union(data.usedAnalysisIDs, [analysis.sampleTypeID])

          // Add result
          analysis.result = result

          // Add to sample entry
          if (analysis.sampleTypeID === 'ecoTox') {
            analysis.status.required += 1
            analysis.status.complete += 1
          }
          else {
            analysis.status.unintentional += 1
          }

          // Add to summary
          if (analysis.sampleTypeID === 'ecoTox') {
            summaryAnalysis.status.required.push(sampleItem.sampleNumber)
            summaryAnalysis.status.complete.push(sampleItem.sampleNumber)
          }
          else {
            summaryAnalysis.status.unintentional.push(sampleItem.sampleNumber)
          }
        }

        // Check to set CP short and medium as required
        if (
          analysis.sampleTypeID === 'cpScreening'
          && analysis.status.required
        ) {
          const cpScreeningTest = testResult.results.find((x) => x.id === 'cpScreening')

          if (cpScreeningTest?.value === 'Påvist') {
            // Add to sample entry
            const cpAnalysis = sampleItem.analyses.find((x) => x.sampleTypeID === 'ST-5')
            cpAnalysis.status.required += 1
            cpAnalysis.status.missing += 1

            // Add to summary
            const cpSummaryAnalysis = data.summary.find((x) => x.sampleTypeID === 'ST-5')
            cpSummaryAnalysis.status.required.push(sampleItem.sampleNumber)
            cpSummaryAnalysis.status.missing.push(sampleItem.sampleNumber)

            // Add to used analyses
            data.usedAnalysisIDs = union(data.usedAnalysisIDs, ['ST-5'])
          }
        }
      }
      else {
        result = null
      }
    })

    // Legacy - Check to use cpScreeningAutoProven
    // - This is only to acommidate older screenings that use cpScreeningAutoProven
    if (testResult.cpScreeningAutoProven) {
      const cpAnalysisItem = sampleItem.analyses.find((x) => x.sampleTypeID === 'ST-5')

      if (cpAnalysisItem.status.missing) {
        cpAnalysisItem.status.missing = 0
        cpAnalysisItem.status.complete = 1

        cpAnalysisItem.result = 2

        // Update summary
        const cpSummaryAnalysis = data.summary.find((x) => x.sampleTypeID === 'ST-5')
        const index = cpSummaryAnalysis.status.missing.findIndex(
          (x) => x === sampleItem.sampleNumber,
        )
        if (index > -1) {
          cpSummaryAnalysis.status.missing.splice(index, 1)
          cpSummaryAnalysis.status.complete.push(sampleItem.sampleNumber)
        }
      }
    }

    // Set sample item completed status
    sampleItem.isCompleted = sampleItem.analyses.every((analysis) => {
      const {
        missing,
        unintentional,
      } = analysis.status

      if (missing) {
        return false
      }
      if (unintentional) {
        return false
      }

      return true
    }) || isMigrationAndCompletedProject

    if (sampleItem.isCompleted) {
      // Set sample item result
      sampleItem.result = sampleItem.analyses.reduce((prev, analysis) => {
        if (prev === null) {
          prev = analysis.result
        }
        else if (analysis.result > prev) {
          prev = analysis.result
        }
        return prev
      }, null)

      // Set sample WDC-ID
      sampleItem.wdcID = testResult.wdcID || ''
    }
  })
}

function sortSummaryLists(summary) {
  summary.forEach((analysis) => {
    analysis.status.required.sort()
    analysis.status.missing.sort()
    analysis.status.complete.sort()
    analysis.status.unintentional.sort()
  })
}

function makeAnalyzesStatusSummary(data) {
  data.list.forEach((sampleItem) => {
    sampleItem.analyses.forEach((analysis) => {
      if (['cpScreening', 'ecoTox'].includes(analysis.sampleTypeID)) {
        return
      }
      data.totals.required += analysis.status.required
      data.totals.complete += analysis.status.complete
      data.totals.missing += analysis.status.missing
      data.totals.unintentional += analysis.status.unintentional
    })
  })
}

function setRequiredAnalysisTestGroupIDs(data) {
  data.list.forEach((sampleItem) => {
    sampleItem.analyses.forEach((analysis) => {
      if (!analysis.status.required) {
        return
      }
      sampleItem.requiredAnalysisTestGroupIDs = union(
        sampleItem.requiredAnalysisTestGroupIDs, [analysis.groupID],
      )

      if (analysis.sampleTypeID === 'cpScreening') {
        sampleItem.requiredAnalysisTestGroupIDs = union(
          sampleItem.requiredAnalysisTestGroupIDs, ['CP'],
        )
      }
    })
  })
}

function setUsedAnalysisTestGroupIDs(data, sampleTypesAsArray) {
  let setUsedAnalysisTestGroupIDs = []

  data.usedAnalysisIDs.forEach((sampleTypeID) => {
    if (sampleTypeID === 'cpScreening') {
      setUsedAnalysisTestGroupIDs = union(setUsedAnalysisTestGroupIDs, ['CP'])
      return
    }

    if (sampleTypeID === 'ecoTox') {
      setUsedAnalysisTestGroupIDs = union(setUsedAnalysisTestGroupIDs, ['ecoTox'])
      return
    }

    const sampleType = sampleTypesAsArray.find((x) => x.id === sampleTypeID)
    setUsedAnalysisTestGroupIDs = union(
      setUsedAnalysisTestGroupIDs, [sampleType.analysisTestGroupID],
    )
  })

  data.usedAnalysisTestGroupsIDs = setUsedAnalysisTestGroupIDs
}

function setOverallCompletedStatus(data) {
  const cpScreeningSummary = data.summary.find(
    (x) => x.sampleTypeID === 'cpScreening',
  )

  // No analyzes required
  if (!data.totals.required && !cpScreeningSummary.status.required.length) {
    data.isCompleted = true
    return
  }

  // Has missing analyzes
  if (data.totals.missing || cpScreeningSummary.status.missing.length) {
    data.isCompleted = false
    return
  }

  // Has unintentional analyzes
  if (data.totals.unintentional || cpScreeningSummary.status.unintentional.length) {
    data.isCompleted = false
    return
  }

  data.isCompleted = true
}

function setAnalysisTestListResults(data, analysisTestsAsArray, screeningTestResults) {
  const testListItemTemplate = {
    id: '',
    translation: '',
    testType: '',
    unitType: '',
    value: '',
    result: null,
    options: {
      isTooLowToMeasure: false,
      isBelowValue: false,
      isAboveValue: false,
      isAwaiting: false,
      isPostponed: false,
      isAssessed: false,
      isNotAnalysed: false,
      isProven: false,
    },
  }

  data.list.forEach((sampleItem) => {
    sampleItem.analysisTestListResults = analysisTestsAsArray.reduce((prev, analysisTest) => {
      const testListItem = JSON.parse(JSON.stringify(testListItemTemplate))
      prev.push(testListItem)

      testListItem.id = analysisTest.id
      testListItem.translation = analysisTest.translation
      testListItem.unitType = analysisTest.unit
      testListItem.testType = analysisTest.type

      // Check if analysis test is required
      let isRequired = !!sampleItem.analyses.find(
        (x) => x.requiredTestIDs.includes(analysisTest.id) && x.status.required,
      )

      // When sample item is not completed
      if (!sampleItem.isCompleted) {
        // Check if cp short and medium is required based on cp screening
        if (!isRequired && ['cpShort', 'cpMedium'].includes(analysisTest.id)) {
          const cpScreeningAnalysisItem = sampleItem.analyses.find(
            (x) => x.sampleTypeID === 'cpScreening',
          )
          isRequired = !!cpScreeningAnalysisItem.status.required
        }

        testListItem.options.isAwaiting = isRequired
      }

      // Set values
      const sampleTestResultData = screeningTestResults.find(
        (x) => x.sampleNumber === sampleItem.sampleNumber,
      )

      // Set cp short and medium results based on negative cp screening
      if (!isRequired && ['cpShort', 'cpMedium'].includes(analysisTest.id)) {
        const cpScreeningTestResult = sampleTestResultData?.results.find(
          (x) => x.id === 'cpScreening',
        )

        if (cpScreeningTestResult?.value === 'Ikke påvist') {
          testListItem.testType = 'prove-based'
          testListItem.unitType = ''
          testListItem.result = 0
          testListItem.options.isProven = false

          return prev
        }
      }

      // Set analysed state
      testListItem.options.isNotAnalysed = !isRequired

      if (!sampleTestResultData) {
        return prev
      }

      // Legacy - Set cp short and medium results based on cpScreeningAutoProven
      // - This is only to acommidate older screenings that use cpScreeningAutoProven
      if (
        isRequired
        && ['cpShort', 'cpMedium'].includes(analysisTest.id)
        && sampleTestResultData.cpScreeningAutoProven
      ) {
        testListItem.testType = 'prove-based'
        testListItem.unitType = ''
        testListItem.result = 2
        testListItem.options.isProven = true

        return prev
      }

      if (!isRequired) {
        testListItem.options.isNotAnalysed = true
        return prev
      }

      const testResult = sampleTestResultData.results.find((x) => x.id === analysisTest.id)

      if (!testResult) {
        testListItem.result = null
        testListItem.value = null

        return prev
      }

      // Set result
      testListItem.result = testResult.result

      // Set value
      testListItem.value = testResult.value

      // Set options
      if (testResult.isTooLowToMeasure) {
        testListItem.options.isTooLowToMeasure = true
      }
      if (testResult.isBelowValue) {
        testListItem.options.isBelowValue = true
      }
      if (testResult.isAboveValue) {
        testListItem.options.isAboveValue = true
      }
      if (analysisTest.type === 'prove-based' && testResult.result === 2) {
        testListItem.options.isProven = true
      }

      // Set proven status for cpScreening
      if (analysisTest.id === 'cpScreening') {
        const cpScreeningTestResult = sampleTestResultData.results.find(
          (x) => x.id === 'cpScreening',
        )

        if (cpScreeningTestResult?.value === 'Påvist') {
          testListItem.options.isProven = true
        }
      }

      return prev
    }, [])
  })
}

