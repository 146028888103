<template>
  <div class="LanguageSelectModal">
    <span class="Title">{{ mixWB('SELECT_LANGUAGE') }}</span>

    <div class="List">
      <div
        class="LangButton"
        v-for="lang in languages"
        :key="lang.countryCode"
        @click="onLangClick(lang)">
        <div class="LangIcon">
          <FlagIcon :countryCode="lang.countryCode" />
        </div>
        <span class="LangTitle">{{ lang.countryCode }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import FlagIcon from '@/components/FlagIcon.vue'
import { keys } from '@/globals/javascript/_util/keys'
import { mapGetters } from 'vuex'

export default {
  name: 'LanguageSelectModal',
  computed: {
    ...mapGetters([
      'languages',
    ]),
  },
  methods: {
    onLangClick(lang) {
      window.localStorage.setItem(keys.LS_SAVED_LOCALE, lang.locale)
      window.location.reload()
    },
  },
  components: { FlagIcon },
}
</script>

<style lang="stylus" scoped>
  .LanguageSelectModal
    display block

  .Title
    modalTitle()

  .List
    display flex

  .LangButton
    display flex
    align-items center
    padding 10px
    border-radius 8px
    cursor pointer
    transition: all 0.15s ease;
    .LangIcon
      circle(24px)
      overflow hidden
    .LangTitle
      margin-left 6px
    &:hover
      background-color $color_grey_lightest
</style>
