import { db } from '@/firebase/init'

export const setIDOnCreate = (id, collection) => {
  if (id) {
    return id
  }
  const docRef = db
    .collection(collection)
    .doc()
  return docRef.id
}

export const createSampleID = (id, typeID, kindOfSample) => {
  if (id) {
    return id
  }

  return `${ typeID }-${ kindOfSample }`
}

const DB_ACCOUNTS = 'Accounts'
const DB_HUB_ACCOUNTS = 'HubAccounts'
const DB_ADDRESS_IMAGES = 'AddressImages'
const DB_CHANGELOGS = 'Changelogs'
const DB_INTER_CONNECTIONS = 'InterConnections'
const DB_META_DATA = 'MetaData'
const DB_PCB_SCREENINGS = 'PCBScreenings'
const DB_REPORTS = 'Reports'
const DB_SAMPLES = 'Samples'
const DB_SCREENINGS = 'Screenings'
const DB_STATS = 'Stats'
const DB_TEST_RESULTS = 'TestResults'
const DB_TYPES = 'Types'
const DB_UNITS = 'Units'
const DB_USERS = 'Users'
const DB_LANGUAGES = 'Languages'
const DB_TRANSLATIONS = 'Translations'
const DB_COUNTRIES = 'Countries'
const DB_RESOURCES = 'Resources'
const DB_STANDARD_SAMPLES_STATUS = 'StandardSamplesStatus'
const DB_POSTPONED_SAMPLES_STATUS = 'PostponedSamplesStatus'
const DB_ASSESSED_SAMPLES_STATUS = 'AssessedSamplesStatus'

export {
  DB_ACCOUNTS,
  DB_HUB_ACCOUNTS,
  DB_ADDRESS_IMAGES,
  DB_CHANGELOGS,
  DB_INTER_CONNECTIONS,
  DB_META_DATA,
  DB_PCB_SCREENINGS,
  DB_REPORTS,
  DB_SAMPLES,
  DB_SCREENINGS,
  DB_STATS,
  DB_TEST_RESULTS,
  DB_TYPES,
  DB_UNITS,
  DB_USERS,
  DB_LANGUAGES,
  DB_TRANSLATIONS,
  DB_COUNTRIES,
  DB_RESOURCES,
  DB_STANDARD_SAMPLES_STATUS,
  DB_POSTPONED_SAMPLES_STATUS,
  DB_ASSESSED_SAMPLES_STATUS,
}
