import { db } from '@/firebase/init'
import Screening from '@/globals/javascript/models/Screening'
import {
  DB_ASSESSED_SAMPLES_STATUS,
  DB_POSTPONED_SAMPLES_STATUS,
  DB_SCREENINGS,
  DB_STANDARD_SAMPLES_STATUS,
} from '@/globals/javascript/models/_helper'
import { customLog } from '@/globals/javascript/_util/custom-log'

export const migrations = {
  state: {
    isRunningMigration: false,
    allVersion2Projects: [],
  },
  mutations: {
    updateMigrationRunningStatus: (state, status) => {
      state.isRunningMigration = status
    },
    updateAllVersion2Projects: (state, allVersion2Projects) => {
      state.allVersion2Projects = allVersion2Projects
    },
  },
  actions: {
    updateMigrationRunningStatus: ({ commit }, status) => {
      commit('updateMigrationRunningStatus', status)
    },
    getAllVersion2Projects: ({ commit }) => {
      db
        .collection(DB_SCREENINGS)
        .where('appVersion', '>=', '2')
        .orderBy('appVersion', 'asc')
        .get()
        .then((querySnapshot) => {
          const allVersion2Projects = []
          querySnapshot.forEach((doc) => {
            allVersion2Projects.push(new Screening({
              ...doc.data(),
              id: doc.id,
            }))
          })

          commit('updateAllVersion2Projects', allVersion2Projects)
        })
    },
    migrateSamplesStatusses: async ({ getters }) => {
      const {
        currentScreening,
        standardSamplesStatus,
        assessedSamplesStatus,
        postponedSamplesStatus,
      } = getters

      db
        .collection(DB_SCREENINGS)
        .doc(currentScreening.id)
        .collection(DB_STANDARD_SAMPLES_STATUS)
        .doc(currentScreening.id)
        .set({
          ...JSON.parse(JSON.stringify(standardSamplesStatus)),
        })
        .catch((err) => {
          customLog('migration', `Fejl: Standard samples - id: ${ currentScreening.id }`)
          console.error(err)
        })

      db
        .collection(DB_SCREENINGS)
        .doc(currentScreening.id)
        .collection(DB_POSTPONED_SAMPLES_STATUS)
        .doc(currentScreening.id)
        .set({
          ...JSON.parse(JSON.stringify(postponedSamplesStatus)),
        })
        .catch((err) => {
          customLog('migration', `Fejl: Postponed samples - id: ${ currentScreening.id }`)
          console.error(err)
        })

      db
        .collection(DB_SCREENINGS)
        .doc(currentScreening.id)
        .collection(DB_ASSESSED_SAMPLES_STATUS)
        .doc(currentScreening.id)
        .set({
          ...JSON.parse(JSON.stringify(assessedSamplesStatus)),
        })
        .catch((err) => {
          customLog('migration', `Fejl: Assessed samples - id: ${ currentScreening.id }`)
          console.error(err)
        })
    },
  },
  getters: {
    isRunningMigration: (state) => state.isRunningMigration,
    allVersion2Projects: (state) => state.allVersion2Projects,
  },
}
