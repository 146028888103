export const extractAnalysesResultsHojvang = (getters, rows) => new Promise((resolve, reject) => {
  const {
    laboratoriesAsArray,
    analysisTestsAsArray,
  } = getters

  const labItem = laboratoriesAsArray.find((x) => x.id === 'hojvang')
  const { excelFileColumnNames } = labItem

  // Rows
  const mainRow = rows[0]
  const allAnalysesRows = rows.splice(2)

  // TODO: Validate case number

  // Get column indexes of all present analyses
  const analysesColumns = mainRow.reduce((prev, value, index) => {
    let match = null

    // Special case for 9PAH as the title changes from 'Sum PAH (6 stk)' to 'Sum PAH (8 stk)'
    if (value.includes('Sum PAH')) {
      match = excelFileColumnNames.find((x) => x.columnName.includes('Sum PAH'))
    }
    else {
      match = excelFileColumnNames.find((x) => x.columnName === value)
    }

    // CP screening match
    if (['Chlorparaffin, (SCCP)', 'Chlorparaffin, (MCCP)'].includes(value)) {
      prev.push({
        columnIndex: index,
        columnName: value,
        analysisID: 'cpScreening',
      })
      return prev
    }

    if (match) {
      prev.push({
        columnIndex: index,
        columnName: match.columnName,
        analysisID: match.analysisID,
      })
    }
    return prev
  }, [])

  // Extract analyses results
  const analysesResults = allAnalysesRows.reduce((prev, row) => {
    const item = {
      sampleNumber: null,
      results: [],
    }

    // Get sample number
    const sampleIdentification = row[2] ?? ''
    const firstGroupOfWholeNumbersInName = sampleIdentification.match(/\d+/)?.[0] ?? undefined
    item.sampleNumber = Number(firstGroupOfWholeNumbersInName)

    // Check for sample number
    if (!item.sampleNumber) {
      return prev
    }

    // Get value of each analysis test
    analysisTestsAsArray.forEach((analysis) => {
      // Create item
      const resultItem = {
        id: analysis.id,
        value: null,
        isBelowValue: false,
        isAboveValue: false,
        isTooLowToMeasure: false,
        unit: analysis.unit || null,
        result: null,
        type: analysis.type,
      }

      // Add item
      item.results.push(resultItem)

      // Special flow for CP screening
      if (analysis.id === 'cpScreening') {
        const analysisColumnItems = analysesColumns.filter((x) => x.analysisID === analysis.id)

        if (!analysisColumnItems.length) {
          return
        }

        const values = analysisColumnItems.map((x) => row[x.columnIndex])

        let value = null
        if (values.some((x) => x === '+')) {
          value = 'Påvist'
        }
        else if (values.some((x) => x === '÷')) {
          value = 'Ikke påvist'
        }

        if (!value) {
          return
        }

        resultItem.value = value
        resultItem.result = 0
        return
      }

      // Check for analysis is represented in file
      const analysisColumnItem = analysesColumns.find((x) => x.analysisID === analysis.id)

      if (!analysisColumnItem) {
        return
      }

      // Get and check value
      // - Possible values: Påvist, Ikke påvist, 10, <10, >10, #, ia, null
      let value = row[analysisColumnItem.columnIndex]

      // - When no value
      if (!value || value === 'ia') {
        return
      }

      // - Make sure value is a string
      value = value.toString()

      // - Convert commas to dots
      value = value.replace(',', '.')

      // - When value is too low to measure
      if (value === '#') {
        resultItem.isTooLowToMeasure = true
      }

      // - When value is below a limit
      else if (value.includes('<')) {
        resultItem.value = Number(value.replace('<', ''))
        resultItem.isBelowValue = true
      }

      // - When value is above a limit
      else if (value.includes('>')) {
        resultItem.value = Number(value.replace('>', ''))
        resultItem.isAboveValue = true
      }

      // - When value is just a number
      else if (analysis.type === 'value-based') {
        resultItem.value = Number(value)
      }

      // - When value is text
      else {
        resultItem.value = value
      }

      // Get result
      // - Value based
      if (analysis.type === 'value-based') {
        if (analysis.maxValue && resultItem.value >= analysis.maxValue) {
          resultItem.result = 2
        }
        else if (analysis.minValue && resultItem.value >= analysis.minValue) {
          resultItem.result = 1
        }
        else {
          resultItem.result = 0
        }
      }

      // - Prove based
      if (analysis.type === 'prove-based') {
        if (value === 'Påvist') {
          resultItem.result = 2
        }
        else {
          resultItem.result = 0
        }
      }
    })

    prev.push(item)

    return prev
  }, [])

  resolve(analysesResults)
})
