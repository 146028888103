import EventBus from '@/EventBus'

export const statuses = {
  state: {},
  mutations: {},
  actions: {},
  getters: {
    samplesStatus: (state, getters) => {
      const {
        typesWithSamples,
        screeningSamples,
        currentScreeningSelectedFilterUnitID,
      } = getters

      const results = typesWithSamples.reduce((prev, type) => {
        if (
          currentScreeningSelectedFilterUnitID
          && !type.unitIDs.includes(currentScreeningSelectedFilterUnitID)
        ) {
          return prev
        }

        // Material auto assessed samples - we just assume the sample is there as it is
        // auto-created upon type creation.
        if (type.assessments.sampleIDs.length) {
          prev.total += 1
          prev.autoAssessed += 1
        }

        // Material samples
        else if (type.getMaterialSampleList({ onlyIDs: true }).length) {
          prev.total += 1
          const existingMaterialSample = screeningSamples.find(
            (x) => x.typeID === type.id && x.kindOfSample === 'material',
          )
          if (existingMaterialSample && existingMaterialSample.status === 'done') {
            prev.filled += 1
          }
          if (existingMaterialSample && existingMaterialSample.status === 'equivalent') {
            prev.equivalent += 1
          }
          if (existingMaterialSample && existingMaterialSample.status === 'existing-result') {
            prev.existingResults += 1
          }
          if (existingMaterialSample && existingMaterialSample.status.includes('skip-')) {
            prev.skipped += 1
          }
          if (existingMaterialSample && existingMaterialSample.status.includes('postpone-sample')) {
            prev.postponed += 1
          }
        }

        // Coating samples
        if (type.getCoatingSampleList({ onlyIDs: true }).length) {
          prev.total += 1
          const existingCoatingsample = screeningSamples.find(
            (x) => x.typeID === type.id && x.kindOfSample === 'coating',
          )
          if (existingCoatingsample && existingCoatingsample.status === 'done') {
            prev.filled += 1
          }
          if (existingCoatingsample && existingCoatingsample.status === 'equivalent') {
            prev.equivalent += 1
          }
          if (existingCoatingsample && existingCoatingsample.status === 'existing-result') {
            prev.existingResults += 1
          }
          if (existingCoatingsample && existingCoatingsample.status.includes('skip-')) {
            prev.skipped += 1
          }
          if (existingCoatingsample && existingCoatingsample.status.includes('postpone-sample')) {
            prev.postponed += 1
          }
        }

        // Dust samples
        if (type.getDustSampleList({ onlyIDs: true }).length) {
          prev.total += 1
          const existingDustSample = screeningSamples.find(
            (x) => x.typeID === type.id && x.kindOfSample === 'dust',
          )
          if (existingDustSample && existingDustSample.status === 'done') {
            prev.filled += 1
          }
          if (existingDustSample && existingDustSample.status === 'equivalent') {
            prev.equivalent += 1
          }
          if (existingDustSample && existingDustSample.status === 'existing-result') {
            prev.existingResults += 1
          }
          if (existingDustSample && existingDustSample.status.includes('skip-')) {
            prev.skipped += 1
          }
          if (existingDustSample && existingDustSample.status.includes('postpone-sample')) {
            prev.postponed += 1
          }
        }

        return prev
      }, {
        total: 0,
        skipped: 0,
        postponed: 0,
        equivalent: 0,
        existingResults: 0,
        filled: 0,
        autoAssessed: 0,
      })

      const done = results.skipped
        + results.postponed
        + results.equivalent
        + results.existingResults
        + results.filled
        + results.autoAssessed

      return {
        ...results,
        done,
        isCompleted: results.total === done,
        isActiveStep: getters.currentRoute.name === 'ScreeningsUpdateSamples',
      }
    },
    profileImageStatus: (state, getters) => {
      const addressImages = getters.addressImages || {}
      const {
        buildingsInUse,
        combinedListOfUnits,
        currentRoute,
        currentScreeningSelectedFilterUnitID,
      } = getters

      const status = {
        total: 0,
        done: 0,
        isCompleted: false,
        isActiveStep: currentRoute.name === 'ScreeningsUpdateProfileImages',
      }

      let buildings = []

      if (currentScreeningSelectedFilterUnitID) {
        const unit = combinedListOfUnits.find(
          (x) => x.id === currentScreeningSelectedFilterUnitID,
        )
        const buildingID = unit.getBuildingID()
        buildings = combinedListOfUnits.filter((x) => x.id === buildingID)
      }
      else {
        buildings = buildingsInUse
      }

      if (currentScreeningSelectedFilterUnitID) {
        status.total = 1
      }
      else {
        status.total = buildings.length + 1
      }

      // Check overview image
      if (
        !currentScreeningSelectedFilterUnitID
        && addressImages.overview?.profile?.length >= 1
      ) {
        status.done += 1
      }

      // Check building images
      buildings.forEach((building) => {
        if (addressImages.units?.[building.id]?.profile?.length >= 1) {
          status.done += 1
        }
      })

      status.isCompleted = status.done === status.total

      return status
    },
    floorPlanStatus: (state, getters) => {
      const {
        selectedUnits,
        noOfSelectedUnits,
        currentRoute,
        currentScreeningSelectedFilterUnitID,
      } = getters

      const status = {
        total: noOfSelectedUnits || 0,
        done: 0,
        isCompleted: false,
        isActiveStep: currentRoute.name === 'ScreeningsUpdateFloorPlans',
      }

      if (currentScreeningSelectedFilterUnitID) {
        status.total = 1
      }

      // Check units images
      selectedUnits.forEach((unit) => {
        if (
          currentScreeningSelectedFilterUnitID
           && unit.id !== currentScreeningSelectedFilterUnitID
        ) {
          return
        }

        if (unit.hasFloorPlanSkipped()) {
          status.done += 1
          return
        }

        if (unit.hasFloorPlan()) {
          status.done += 1
        }
      })

      status.isCompleted = status.done === status.total

      return status
    },
    amountStatus: (state, getters) => {
      const {
        screeningTypes,
        currentRoute,
        currentScreeningSelectedFilterUnitID,
      } = getters

      const status = {
        total: 0,
        done: 0,
        isCompleted: false,
        isActiveStep: currentRoute.name === 'ScreeningsUpdateAmounts',
      }

      screeningTypes.forEach((type) => {
        if (
          currentScreeningSelectedFilterUnitID
           && !type.unitIDs.includes(currentScreeningSelectedFilterUnitID)
        ) {
          return
        }

        status.total += 1

        const amountType = type?.amounts?.[0]?.data?.type

        if (amountType && amountType !== 'skip') {
          status.done += 1
        }
      })

      status.isCompleted = status.done === status.total

      return status
    },
    pcbScreeningStatus: (state, getters) => {
      const pcbScreenings = getters.screeningPCBScreenings || []
      const {
        buildingsInUse,
        combinedListOfUnits,
        currentRoute,
        currentScreeningSelectedFilterUnitID,
      } = getters

      const status = {
        total: 0,
        done: 0,
        isCompleted: false,
        isActiveStep: currentRoute.name === 'ScreeningsUpdatePSCScreeningSchema',
      }

      let buildings = []

      if (currentScreeningSelectedFilterUnitID) {
        const unit = combinedListOfUnits.find(
          (x) => x.id === currentScreeningSelectedFilterUnitID,
        )
        const buildingID = unit.getBuildingID()
        buildings = combinedListOfUnits.filter((x) => x.id === buildingID)
      }
      else {
        buildings = buildingsInUse
      }

      status.total = buildings.length

      buildings.forEach((building) => {
        const pcbScreening = pcbScreenings.find(
          (screening) => screening.id === building.id,
        )

        if (pcbScreening) {
          status.done += 1
        }
      })

      status.isCompleted = status.done === status.total

      return status
    },
    categoryStatus: () => EventBus.nextCategory,
  },
}
