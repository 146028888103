import { mixWB } from '@/globals/javascript/_util/mixins'

export default class RQuestion {
  constructor({
    id,
    translation,
    year,
    sampleIDs,
    tagIDs,
    assessmentIDs,
  }) {
    this.id = id
    this.translation = translation || ''
    this.year = year || 0
    this.sampleIDs = sampleIDs || []
    this.tagIDs = tagIDs || []
    this.assessmentIDs = assessmentIDs || []
  }

  getTitle() {
    if (this.year) {
      return mixWB('FROM_BEFORE_X', [this.year.toString()])
    }

    return mixWB(this.translation)
  }

  getTagsIDs() {
    const tagsIDs = [...this.tagIDs]

    if (this.year) {
      tagsIDs.push(`TAG-YEAR-${ this.year }`)
    }

    return tagsIDs
  }
}
