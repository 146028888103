/* eslint-disable no-use-before-define */
import { sortBy, union } from 'lodash-es'

export const postponedSamplesStatus = {
  postponedSamplesStatus: (state, getters) => {
    const {
      isPageLoading,
      screeningSamples,
      sampleTypesAsArray,
      screeningTypes,
      analysisTestGroupsAsArray,
      analysisTestsAsArray,
    } = getters

    const {
      allResourcesLoaded,
      currentScreeningLoaded,
      currentScreeningTypesLoaded,
      currentScreeningSamplesLoaded,
      currentScreeningPCBScreeningLoaded,
      currentScreeningTestResultsLoaded,
      currentScreeningUnitsLoaded,
    } = getters

    // Find all postponed samples
    const postponedSamples = sortBy(screeningSamples.filter(
      (x) => x.postponeNumber,
    ), 'postponeNumber')

    // Build data structure
    const data = {
      list: [],
      usedAnalysisIDs: [],
      usedAnalysisTestGroupsIDs: [],
    }

    // Return if data is not yet ready
    if (isPageLoading) {
      return data
    }

    // Return if some data is missing
    if (
      !allResourcesLoaded
      || !currentScreeningLoaded
      || !currentScreeningTypesLoaded
      || !currentScreeningSamplesLoaded
      || !currentScreeningPCBScreeningLoaded
      || !currentScreeningTestResultsLoaded
      || !currentScreeningUnitsLoaded
    ) {
      return data
    }

    // Add each sample and its required analyzes
    addSamplesAndRequiredAnalyzes(
      data,
      postponedSamples,
      screeningTypes,
      sampleTypesAsArray,
      analysisTestGroupsAsArray,
    )

    // Set required analysis test group ids for each sample
    setRequiredAnalysisTestGroupIDs(data)

    // Set overlay used analysis test groups ids
    setUsedAnalysisTestGroupIDs(data, sampleTypesAsArray)

    // Set analysis test list results
    setAnalysisTestListResults(data, analysisTestsAsArray)

    return data
  },
}

function addSamplesAndRequiredAnalyzes(
  data,
  postponedSamples,
  screeningTypes,
  sampleTypesAsArray,
  analysisTestGroupsAsArray,
) {
  // Create analyses data object
  const analysesListTemplate = sortBy(analysisTestGroupsAsArray.map((analysisTestGroup) => {
    const analysisListItem = {
      sampleTypeID: analysisTestGroup.sampleTypeID,
      order: analysisTestGroup.order,
      groupID: analysisTestGroup.id,
      requiredTestIDs: analysisTestGroup.analysisTestIDs,
      status: {
        required: 0,
      },
    }

    return analysisListItem
  }), ['order'])

  // Populate postponed samples list
  postponedSamples.forEach((sample) => {
    const sampleItem = {
      postponeNumber: sample.postponeNumber,
      analyses: JSON.parse(JSON.stringify(analysesListTemplate)),
      analysisTestListResults: [],
      requiredAnalysisTestGroupIDs: [],
    }

    data.list.push(sampleItem)

    const type = screeningTypes.find((x) => x.id === sample.typeID)

    if (!type) {
      // TODO: Report to sentry
      return
    }

    let sampleIDs

    // Material sample
    if (sample.kindOfSample === 'material') {
      sampleIDs = type.getMaterialSampleList({ onlyIDs: true })
    }

    // Coating sample
    if (sample.kindOfSample === 'coating') {
      sampleIDs = type.getCoatingSampleList({ onlyIDs: true })
    }

    // Dust sample
    if (sample.kindOfSample === 'dust') {
      sampleIDs = type.getDustSampleList({ onlyIDs: true })
    }

    sampleIDs.forEach((sampleID) => {
      // Add to sample item
      const sampleStatus = sampleItem.analyses.find((x) => x.sampleTypeID === sampleID)
      sampleStatus.status.required += 1

      // Add to used analyses
      data.usedAnalysisIDs = union(data.usedAnalysisIDs, [sampleID])

      // Check to set CP screening as required
      if (sampleID === 'ST-4' && !sampleIDs.includes('ST-5')) {
        // Add to sample entry
        const cpScreeningSampleStatus = sampleItem.analyses.find((x) => x.sampleTypeID === 'cpScreening')
        cpScreeningSampleStatus.status.required += 1

        // Add to used analyses
        data.usedAnalysisIDs = union(data.usedAnalysisIDs, ['cpScreening'])
      }
    })
  })
}

function setRequiredAnalysisTestGroupIDs(data) {
  data.list.forEach((sampleItem) => {
    sampleItem.analyses.forEach((analysis) => {
      if (!analysis.status.required) {
        return
      }

      sampleItem.requiredAnalysisTestGroupIDs.push(analysis.groupID)
    })
  })
}

function setUsedAnalysisTestGroupIDs(data, sampleTypesAsArray) {
  let setUsedAnalysisTestGroupIDs = []

  data.usedAnalysisIDs.forEach((sampleTypeID) => {
    if (sampleTypeID === 'cpScreening') {
      setUsedAnalysisTestGroupIDs = union(setUsedAnalysisTestGroupIDs, ['CP'])
      return
    }

    if (sampleTypeID === 'ecoTox') {
      setUsedAnalysisTestGroupIDs = union(setUsedAnalysisTestGroupIDs, ['ecoTox'])
      return
    }

    const sampleType = sampleTypesAsArray.find((x) => x.id === sampleTypeID)
    setUsedAnalysisTestGroupIDs = union(
      setUsedAnalysisTestGroupIDs, [sampleType.analysisTestGroupID],
    )
  })

  data.usedAnalysisTestGroupsIDs = setUsedAnalysisTestGroupIDs
}

function setAnalysisTestListResults(data, analysisTestsAsArray) {
  const testListItemTemplate = {
    id: '',
    translation: '',
    testType: '',
    unitType: '',
    value: '',
    result: null,
    options: {
      isTooLowToMeasure: false,
      isBelowValue: false,
      isAboveValue: false,
      isAwaiting: false,
      isPostponed: false,
      isAssessed: false,
      isNotAnalysed: false,
      isProven: false,
    },
  }

  data.list.forEach((sampleItem) => {
    sampleItem.analysisTestListResults = analysisTestsAsArray.reduce((prev, analysisTest) => {
      const testListItem = JSON.parse(JSON.stringify(testListItemTemplate))
      prev.push(testListItem)

      testListItem.id = analysisTest.id
      testListItem.translation = analysisTest.translation
      testListItem.unitType = analysisTest.unit
      testListItem.testType = analysisTest.type

      let isRequired = !!sampleItem.analyses.find(
        (x) => x.requiredTestIDs.includes(analysisTest.id) && x.status.required,
      )

      // Set cp short and medium as postponed when cp screening is required
      if (!isRequired && ['cpShort', 'cpMedium'].includes(analysisTest.id)) {
        const cpScreeningAnalysisItem = sampleItem.analyses.find(
          (x) => x.sampleTypeID === 'cpScreening',
        )
        isRequired = !!cpScreeningAnalysisItem.status.required
      }

      testListItem.options.isPostponed = isRequired
      testListItem.options.isNotAnalysed = !isRequired

      return prev
    }, [])
  })
}
