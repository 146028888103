import { assessedSamplesStatus } from '@/store/screenings/getters/samplesAndAnalysesStatus/assessedSamplesStatus'
import { externalSamplesStatus } from '@/store/screenings/getters/samplesAndAnalysesStatus/externalSamplesStatus'
import { postponedSamplesStatus } from '@/store/screenings/getters/samplesAndAnalysesStatus/postponedSamplesStatus'
import { standardSamplesStatus } from '@/store/screenings/getters/samplesAndAnalysesStatus/standardSamplesStatus'

export const samplesAndAnalysesStatus = {
  ...standardSamplesStatus,
  ...externalSamplesStatus,
  ...postponedSamplesStatus,
  ...assessedSamplesStatus,
}
