import Type from '@/globals/javascript/models/Type'
import InterConnection from '@/globals/javascript/models/InterConnection'
import { firebase, db } from '@/firebase/init'
import {
  DB_INTER_CONNECTIONS, DB_REPORTS, DB_SCREENINGS, DB_TYPES, setIDOnCreate,
} from '@/globals/javascript/models/_helper'

export const types = {
  getScreeningTypes: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningTypesLoaded) {
      return
    }

    const unsubscribe = db.collection(DB_SCREENINGS)
      .doc(screeningID).collection(DB_TYPES)
      .orderBy('createdAt', 'asc')
      .onSnapshot((querySnapshot) => {
        const types = []
        querySnapshot.forEach((doc) => {
          types.push(new Type({
            ...doc.data(),
            id: doc.id,
          }))
        })

        commit('updateCurrentScreeningTypes', { types, unsubscribe })
      })
  },
  setScreeningType: ({ getters, dispatch }, { type, isNew = false }) => {
    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_TYPES)
      .doc(type.id)
      .set({
        ...type,
      })

    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })

    // Don't report anything if Milva Demo
    if (getters.currentAccount.id === 'fsw2qcnTnElz0Wd546be') {
      return
    }

    // Report other type if any
    if (isNew && type.type.isOtherType) {
      const docName = type.type.typeID ? '-reused-types-' : '-custom-types-'
      db.collection(DB_REPORTS).doc(docName).set({
        data: firebase.firestore.FieldValue.arrayUnion({
          customTypeName: type.type.customTypeName,
          customTypeWDGID: type.type.customTypeWDGID,
          sampleIDs: type.type.customTypeName ? type.samples.sampleIDs : [],
          categoryID: type.categoryID,
          proTypeID: type.id,
          screeningID: getters.currentScreening.id,
          accountID: getters.currentAccount.id,
          userID: getters.currentUser.id,
          resourceTypeID: type.type.typeID,
          hasCoating: !!type.coating.coatingTypeIDs.length,
          amountType: type.amounts?.[0]?.data?.type,
          createdAt: Date.now(),
        }),
      }, { merge: true })
    }

    // Report other places
    if (isNew && type.places.otherPlaces.length) {
      type.places.otherPlaces.forEach((place) => {
        db.collection(DB_REPORTS).doc('-custom-rooms-').set({
          data: firebase.firestore.FieldValue.arrayUnion({
            name: place,
            categoryID: type.categoryID,
            proTypeID: type.id,
            screeningID: getters.currentScreening.id,
            accountID: getters.currentAccount.id,
            userID: getters.currentUser.id,
            createdAt: Date.now(),
          }),
        }, { merge: true })
      })
    }
  },
  setTypeProps: ({ getters, dispatch }, { typeID, props, updateScreening = true }) => {
    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_TYPES)
      .doc(typeID)
      .set({
        ...props,
      }, { merge: true })

    if (updateScreening) {
      dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })
    }
  },
  deleteAddedType: ({ getters, dispatch }, typeID) => {
    const {
      screeningInterConnections,
      screeningSamples,
    } = getters

    // Delete type inter connections and reassign group ID if a group is split in two
    // 1. Find all inter connections used by type to be deleted
    const interConnections = screeningInterConnections.filter(
      (x) => x.innerTypeID === typeID || x.outerTypeID === typeID,
    )

    // 2. Give relevant outer groups a new ID
    interConnections.forEach((interConnection) => {
      if (interConnection.innerTypeID !== typeID) {
        return
      }

      const innerInterConnections = InterConnection.getGroupInterConnectionsFromTypeID({
        typeID,
        interConnectionGroupID: interConnection.groupID,
        side: 'inner',
      })
      const outerInterConnections = InterConnection.getGroupInterConnectionsFromTypeID({
        typeID: interConnection.outerTypeID,
        interConnectionGroupID: interConnection.groupID,
        side: 'outer',
      })

      if (innerInterConnections.length > 1 && outerInterConnections.length) {
        const newGroupID = setIDOnCreate(null, DB_INTER_CONNECTIONS)
        outerInterConnections.forEach((outerInterConnection) => {
          const updatedInterConnection = new InterConnection({
            ...outerInterConnection,
            groupID: newGroupID,
          })
          dispatch('setScreeningInterConnection', updatedInterConnection)
        })
      }
    })

    // 3. Delete inter connections
    interConnections.forEach((interConnection) => {
      dispatch('deleteInterConnection', interConnection.id)
    })

    // 4. Delete type samples
    const samples = screeningSamples.filter((x) => x.typeID === typeID)
    samples.forEach((sample) => {
      dispatch('deleteScreeningSample', sample)
    })

    // 5. Delete type
    db.collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_TYPES)
      .doc(typeID)
      .delete()

    // 6. Update screening
    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })
  },
  handleAutoTypes: ({ dispatch }, { mainType, autoTypes }) => {
    autoTypes.forEach((autoType) => {
      // Add/update auto type
      if (autoType.answer === 'yes') {
        // Set type data
        autoType.type.unitIDs = mainType.unitIDs
        autoType.type.categoryID = mainType.categoryID
        autoType.type.places = mainType.places

        dispatch('setScreeningType', {
          type: autoType.type,
          isNew: false,
        })
      }
    })
  },
}
