import store from '@/store'
import {
  mixGetListAsText, mixWB, mixFormatNumber, mixWBBase,
} from '@/globals/javascript/_util/mixins'
import { firebase } from '@/firebase/init'
import { sortBy, union } from 'lodash-es'
import { checkToAddPCBToType } from '@/globals/javascript/_util/check-to-add-pcb'
import { DB_TYPES, setIDOnCreate } from './_helper'
import Unit from './units/Unit'

export default class Type {
  constructor({
    id,
    screeningID,
    autoTypeBaseID,
    categoryID,
    unitIDs,
    images,
    type,
    questionAnswers = {},
    coating,
    amounts,
    samples,
    dust,
    tags,
    assessments,
    resale, // Deprecated: Resale logic
    recycling,
    places,
    condition,
    courseOfAction, // 'demolition' | 'reuse' | 'prevention'
    comments,
    isOwnTypeAsWell,

    // Timings
    createdAt = null,
    lastUpdated = null,
  }) {
    this.id = setIDOnCreate(id, DB_TYPES)
    this.screeningID = screeningID || store.getters.currentScreening.id
    this.autoTypeBaseID = autoTypeBaseID || ''
    this.categoryID = categoryID
    this.unitIDs = unitIDs || []
    this.images = images || []
    this.type = {
      categoryTypeID: type?.categoryTypeID || '',
      typeID: type?.typeID || '',
      isOtherType: type?.isOtherType || false,
      customTypeName: type?.customTypeName || '',
      customTypeWDGID: type?.customTypeWDGID || '',
    }
    this.questionAnswers = {
      ...questionAnswers,
    }
    this.coating = {
      version: coating?.version || null,
      mainOptionID: coating?.mainOptionID || null,
      colorIDs: coating?.colorIDs || [],
      answers: coating?.answers || {},
      coatingTypeIDs: coating?.coatingTypeIDs || [],
      samples: {
        sampleIDs: coating?.samples?.sampleIDs || [],
        items: coating?.samples?.items || [],
        manualSampleIDs: coating?.samples?.manualSampleIDs || [],
      },
      tags: {
        tagIDs: coating?.tags?.tagIDs || [],
        items: coating?.tags?.items || [],
      },
      courseOfAction: coating?.courseOfAction || 'demolition',
    }
    this.dust = {
      samples: {
        manualSampleIDs: dust?.samples?.manualSampleIDs || [],
      },
    }
    this.amounts = amounts || []
    this.samples = {
      items: samples?.items || [],
      sampleIDs: samples?.sampleIDs || [],
      manualSampleIDs: samples?.manualSampleIDs || [],
    }
    this.tags = {
      items: tags?.items || [],
      tagIDs: tags?.tagIDs || [],
    }
    this.assessments = {
      items: assessments?.items || [],
      sampleIDs: assessments?.sampleIDs || [],
    }
    this.resale = resale || ''
    this.recycling = {
      isHighlighted: recycling?.isHighlighted || false,
    }
    this.places = {
      placeIDs: places?.placeIDs || [],
      otherPlaces: places?.otherPlaces || [],
    }
    this.condition = condition || ''
    this.courseOfAction = courseOfAction || 'demolition'
    this.comments = comments || ''
    this.isOwnTypeAsWell = isOwnTypeAsWell ?? null

    this.createdAt = createdAt || firebase.firestore.FieldValue.serverTimestamp()
    this.lastUpdated = lastUpdated || firebase.firestore.FieldValue.serverTimestamp()
  }

  // Getters
  getCategory() {
    const { categoriesAsObject } = store.getters
    return categoriesAsObject[this.categoryID] || false
  }

  getResourceCategoryType() {
    const {
      categoryTypesAsObject,
    } = store.getters

    return categoryTypesAsObject[this.type.categoryTypeID] || false
  }

  getResourceType() {
    const {
      typesAsObject,
      categoryTypesAsObject,
    } = store.getters

    if (this.type.typeID) {
      return typesAsObject[this.type.typeID]
    }
    if (this.type.categoryTypeID) {
      const categoryType = categoryTypesAsObject[this.type.categoryTypeID]
      return typesAsObject[categoryType.typeID]
    }
    return false
  }

  getMaterial() {
    const { materialsAsObject } = store.getters
    const resourceType = this.getResourceType()

    return materialsAsObject[resourceType?.materialIDs?.[0]] || false
  }

  getWDGID() {
    if (this.type.customTypeWDGID) {
      return this.type.customTypeWDGID
    }

    const type = this.getResourceType()
    if (type) {
      return type.wdgID
    }

    return ''
  }

  getTitle(options = {}) {
    const {
      categoriesAsObject,
    } = store.getters

    const category = options.category || false
    const details = options.details || false
    const useBaseTranslation = options.useBaseTranslation || false

    let title = ''

    // Add category
    if (category) {
      const category = categoriesAsObject[this.categoryID]

      title += `${ useBaseTranslation ? mixWBBase(category.translation) : mixWB(category.translation) } - `
    }

    // Add type title
    const resourceCategoryType = this.getResourceCategoryType()
    const resourceType = this.getResourceType()
    if (resourceCategoryType && resourceCategoryType.translation) {
      title += useBaseTranslation
        ? mixWBBase(resourceCategoryType.translation)
        : mixWB(resourceCategoryType.translation)
    }
    else if (resourceType) {
      title += useBaseTranslation
        ? mixWBBase(resourceType.translation)
        : mixWB(resourceType.translation)
    }
    else {
      title += this.type.customTypeName
    }

    // Add details
    if (details) {
      // - Adhesive and joints
      let hasMultipleDetails = false
      if (this.hasTags({ tags: ['TAG-2'] })) {
        title += ` ${ useBaseTranslation ? mixWBBase('WITH_JOINTS_AND_ADHESIVES') : mixWB('WITH_JOINTS_AND_ADHESIVES') }`
        hasMultipleDetails = true
      }
      if (this.getCoatingText(useBaseTranslation)) {
        title += ` ${
          hasMultipleDetails ? `${ useBaseTranslation ? mixWBBase('AND') : mixWB('AND') } ` : ''
        } ${
          useBaseTranslation ? mixWBBase('WITH_SHORT') : mixWB('WITH_SHORT')
        } ${
          this.getCoatingText(useBaseTranslation).toLowerCase()
        }`
      }
    }

    return title
  }

  getUnits(options = {}) {
    const {
      unitsList,
      returnIDsOnly = false,
    } = options

    const {
      currentScreening,
      selectedUnits,
      screeningRelatedScreenings,
    } = store.getters

    if (returnIDsOnly) {
      return this.unitIDs
    }

    let unitsToUse = []

    if (unitsList) {
      unitsToUse = unitsList
    }
    else if (this.screeningID !== currentScreening.id) {
      const otherProjekt = screeningRelatedScreenings.find(
        (x) => x.data.id === this.screeningID,
      )

      unitsToUse = otherProjekt?.units || []
    }
    else {
      unitsToUse = selectedUnits
    }

    return unitsToUse.filter(
      (unit) => this.unitIDs.includes(unit.id),
    )
  }

  getUnitsList(options = {}) {
    return this.getUnits(options).map(
      (unit) => unit.getFullTitle(),
    ).join(', ')
  }

  // Unit IDs to be used when matching for inter connections
  getAllowedUnitIDsAsObject(options = {}) {
    const {
      noParent = false,
    } = options
    const { selectedUnits } = store.getters
    const data = {
      buildingIDs: [],
      floorIDs: [],
      apartmentIDs: [],
    }

    const allUnitIDs = this.getUnits({ returnIDsOnly: true })
    allUnitIDs.forEach((unitID) => {
      const unit = selectedUnits.find((x) => x.id === unitID)
      if (unit.type === 'building') {
        data.buildingIDs = union(data.buildingIDs, [unitID])
      }
      if (unit.type === 'floor') {
        data.floorIDs = union(data.floorIDs, [unitID])
      }
      if (unit.type === 'apartment') {
        data.apartmentIDs = union(data.apartmentIDs, [unitID])
      }

      if (!noParent && unit.data.floorID) {
        data.floorIDs = union(data.floorIDs, [unit.data.floorID])
      }
      if (!noParent && unit.data.buildingID) {
        data.buildingIDs = union(data.buildingIDs, [unit.data.buildingID])
      }
    })

    return data
  }

  getAmount() {
    if (this.amounts.length !== 1) {
      return ''
    }

    const amount = this.amounts[0]
    const material = this.getMaterial()

    let density = 0
    if (amount.isCustomType) {
      density = amount.data.customDensity
    }
    else if (material) {
      density = material.metaData.density.value
    }

    if (amount.data.type === 'm2') {
      const amountText = `${ amount.data.m2 } ${ mixWB('M2') }`

      if (!density) {
        return amountText
      }

      const tons = mixFormatNumber(
        amount.data.m2
        * (amount.data.thickness / 1000)
        * density, 2,
      )

      return `${ amountText } ≈ ${ tons } ${ mixWB('TONS') }`
    }

    if (amount.data.type === 'm3') {
      const amountText = `${ amount.data.m3 } ${ mixWB('M3') }`
      if (!density) {
        return amountText
      }

      const tons = mixFormatNumber(
        amount.data.m3 * density, 2,
      )
      return `${ amountText } ≈ ${ tons } ${ mixWB('TONS') }`
    }

    if (amount.data.type === 'meters') {
      const amountText = `${ amount.data.meters } ${ mixWB('METERS') }`
      if (!density) {
        return amountText
      }

      const tons = mixFormatNumber(
        amount.data.meters
        * (amount.data.width / 1000)
        * (amount.data.height / 1000)
        * density, 2,
      )
      return `${ amountText } ≈ ${ tons } ${ mixWB('TONS') }`
    }

    if (amount.data.type === 'pcs') {
      const amountText = `${ amount.data.pcs } ${ mixWB('PCS') }`

      const tons = mixFormatNumber(
        amount.data.pcs
        * amount.data.weight, 2,
      )
      return `${ amountText } ≈ ${ tons } ${ mixWB('TONS') }`
    }

    if (amount.data.type === 'skip') {
      return mixWB('NO_AMOUNT_TEXT')
    }

    return ''
  }

  getAmountFromM2(m2) {
    const tons = this.getAmountInTons({ type: 'm2', value: m2 })

    return {
      m2,
      tons,
    }
  }

  getAmountInTons({
    type,
    value,
  }) {
    const amount = this.amounts[0]
    const material = this.getMaterial()

    let density = 0
    if (amount.isCustomType) {
      density = amount.data.customDensity
    }
    else if (material) {
      density = material.metaData.density.value
    }

    if (!value || !amount || !density) {
      return 0
    }

    if (type === 'm2') {
      return value
        * (amount.data.thickness / 1000)
        * density
    }

    return 0
  }

  getM2() {
    const amount = this.amounts[0]

    if (amount?.data?.type === 'm2') {
      return amount?.data?.m2 || 0
    }

    return 0
  }

  getPlacesList() {
    const { places } = store.getters

    let allPlaces = this.places.placeIDs.map(
      (id) => mixWB(places[id].translation),
    )
    allPlaces = allPlaces.concat(this.places.otherPlaces).sort()
    allPlaces = allPlaces.reduce((prev, place, index) => {
      if (index === 0) {
        prev.push(place)
        return prev
      }
      prev.push(place.toLowerCase())
      return prev
    }, [])

    if (allPlaces.length > 1) {
      const lastItem = allPlaces.pop()
      const secondLast = allPlaces.pop()
      allPlaces.push(`${ secondLast } ${ mixWB('AND') } ${ lastItem }`)
    }

    return allPlaces.join(', ')
  }

  getCoatingText(useBaseTranslation = false) {
    let coatingText = ''
    let colorsText = ''
    let finalText = ''

    if (this.coating.coatingTypeIDs.length) {
      coatingText = mixGetListAsText({
        idList: this.coating.coatingTypeIDs,
        objectWithAllIDs: store.getters.coatingTypesAsObject,
        includeAnd: true,
        useBaseTranslation,
      })

      colorsText = mixGetListAsText({
        idList: this.coating.colorIDs,
        objectWithAllIDs: store.getters.colors,
        useBaseTranslation,
      })
    }

    if (coatingText) {
      if (finalText) {
        finalText += ` + ${ coatingText }`
      }
      else {
        finalText = coatingText
      }
    }
    if (colorsText) {
      if (finalText) {
        finalText += ` (${ colorsText })`
      }
      else {
        finalText = colorsText
      }
    }

    return finalText
  }

  getMaterialSampleList(options = {}) {
    // Options
    const asArray = !!options.asArray
    const onlyIDs = !!options.onlyIDs
    const onlyAutoSamples = !!options.onlyAutoSamples

    const sampleIDs = [...this.samples.sampleIDs]
    const manualSampleIDs = onlyAutoSamples ? [] : [...this.samples.manualSampleIDs]

    // Check to add PCB
    const addPCBAnalysis = checkToAddPCBToType(this, 'material')
    if (addPCBAnalysis && sampleIDs.length) {
      sampleIDs.push('ST-4')
    }

    // Check to remove CP when PCB is present
    if (sampleIDs.indexOf('ST-4') > -1 || manualSampleIDs.indexOf('ST-4') > -1) {
      const index = sampleIDs.indexOf('ST-5')
      if (index > -1) {
        sampleIDs.splice(index, 1)
      }
    }

    // Check if 8 metals are present
    const has8Metals = sampleIDs.includes('ST-8') || manualSampleIDs.includes('ST-8')

    if (asArray) {
      const sampleList = []

      sampleIDs.forEach((sampleID) => {
        if (has8Metals && sampleID === 'ST-2') {
          return
        }
        sampleList.push({
          id: sampleID,
          title: mixWB(store.getters.sampleTypesAsObject[sampleID].translation),
          isManual: false,
        })
      })

      manualSampleIDs.forEach((sampleID) => {
        if (sampleIDs.includes(sampleID)) {
          return
        }

        if (has8Metals && sampleID === 'ST-2') {
          return
        }

        sampleList.push({
          id: sampleID,
          title: mixWB(store.getters.sampleTypesAsObject[sampleID].translation),
          isManual: true,
        })
      })

      return sampleList
    }

    // Only return ID's
    const combinedIDs = union(sampleIDs, manualSampleIDs).filter(
      (sampleID) => !has8Metals || (has8Metals && sampleID !== 'ST-2'),
    )
    if (onlyIDs) {
      return combinedIDs
    }

    return mixGetListAsText({
      idList: combinedIDs,
      objectWithAllIDs: store.getters.sampleTypesAsObject,
    })
  }

  getCoatingSampleList(options = {}) {
    // Options
    const asArray = !!options.asArray
    const onlyIDs = !!options.onlyIDs
    const onlyAutoSamples = !!options.onlyAutoSamples

    const hasCoating = !!this.coating.coatingTypeIDs.length
    const sampleIDs = hasCoating ? [...this.coating.samples.sampleIDs] : []
    const manualSampleIDs = hasCoating && !onlyAutoSamples
      ? [...this.coating.samples.manualSampleIDs]
      : []

    // Check to add PCB
    const addPCBAnalysis = checkToAddPCBToType(this, 'coating')
    if (addPCBAnalysis && sampleIDs.length) {
      sampleIDs.push('ST-4')
    }

    // Check to remove CP when PCB is present
    if (sampleIDs.indexOf('ST-4') > -1 || manualSampleIDs.indexOf('ST-4') > -1) {
      const index = sampleIDs.indexOf('ST-5')
      if (index > -1) {
        sampleIDs.splice(index, 1)
      }
    }

    // Check if 8 metals are present
    const has8Metals = sampleIDs.includes('ST-8') || manualSampleIDs.includes('ST-8')

    if (asArray) {
      const sampleList = []

      sampleIDs.forEach((sampleID) => {
        if (has8Metals && sampleID === 'ST-2') {
          return
        }
        sampleList.push({
          id: sampleID,
          title: mixWB(store.getters.sampleTypesAsObject[sampleID].translation),
          isManual: false,
        })
      })

      manualSampleIDs.forEach((sampleID) => {
        if (sampleIDs.includes(sampleID)) {
          return
        }

        if (has8Metals && sampleID === 'ST-2') {
          return
        }

        sampleList.push({
          id: sampleID,
          title: mixWB(store.getters.sampleTypesAsObject[sampleID].translation),
          isManual: true,
        })
      })

      return sampleList
    }

    // Only return ID's
    const combinedIDs = union(sampleIDs, manualSampleIDs).filter(
      (sampleID) => !has8Metals || (has8Metals && sampleID !== 'ST-2'),
    )
    if (onlyIDs) {
      return combinedIDs
    }

    return mixGetListAsText({
      idList: combinedIDs,
      objectWithAllIDs: store.getters.sampleTypesAsObject,
    })
  }

  getDustSampleList(options = {}) {
    // Options
    const asArray = !!options.asArray
    const onlyIDs = !!options.onlyIDs

    const { manualSampleIDs } = this.dust.samples

    if (asArray) {
      const sampleList = []

      manualSampleIDs.forEach((sampleID) => {
        sampleList.push({
          id: sampleID,
          title: mixWB(store.getters.sampleTypesAsObject[sampleID].translation),
          isManual: true,
        })
      })

      return sampleList
    }

    // Only return ID's
    if (onlyIDs) {
      return manualSampleIDs
    }

    return mixGetListAsText({
      idList: manualSampleIDs,
      objectWithAllIDs: store.getters.sampleTypesAsObject,
    })
  }

  getAllSampleAndAssessmentIDs() {
    return union(
      this.getMaterialSampleList({ onlyIDs: true }),
      this.getCoatingSampleList({ onlyIDs: true }),
      this.getDustSampleList({ onlyIDs: true }),
      this.assessments.sampleIDs,
    )
  }

  getAllFloorPlans() {
    const floorPlans = Object.values(this.unitIDs.reduce((prev, unitID) => {
      const currentUnit = Unit.getUnitById(unitID)
      const parents = currentUnit.getParents()
      const children = currentUnit.getChildren()

      children.concat([currentUnit], parents).forEach((unit) => {
        if (!prev[unit.id] && unit.hasFloorPlan()) {
          prev[unit.id] = {
            id: unit.id,
            unit,
            name: unit.getFullTitle(),
            floorPlan: unit.getFloorPlan()[0],
          }
        }
      })

      return prev
    }, {}))

    return sortBy(floorPlans, ['unit.typeSorting', 'unit.data.numericFloorValue', 'name'])
  }

  getAssessmentList() {
    const { sampleTypesAsArray } = store.getters

    let names = ''

    this.assessments.sampleIDs.forEach((sampleID, index) => {
      const sample = sampleTypesAsArray.find((x) => x.id === sampleID)

      if (index === 0) {
        names = mixWB(sample.translation)
        return
      }

      names += `, ${ mixWB(sample.translation) }`
    })

    return names
  }

  hasTags({ tags = [] }) {
    return tags.every((tag) => this.tags.tagIDs.includes(tag))
  }

  // Resets
  resetCoating() {
    this.coating = {
      version: null,
      mainOptionID: null,
      colorIDs: [],
      answers: {},
      coatingTypeIDs: [],
      samples: {
        sampleIDs: [],
        items: [],
      },
      tags: {
        tagIDs: [],
        items: [],
      },
    }
  }

  // Statics
  static getCleanAmountEntry() {
    return {
      materialID: '',
      isCustomType: false,
      data: this.getCleanAmountData(),
    }
  }

  static getCleanAmountData() {
    return {
      type: '',
      m2: 0,
      thickness: 0,
      meters: 0,
      width: 0,
      height: 0,
      pcs: 0,
      weight: 0,
      m3: 0,
      customDensity: 0,
    }
  }
}
