import EventBus from '@/EventBus'
import { storage } from '@/firebase/init'
import Screening from '@/globals/javascript/models/Screening'
import * as Sentry from '@sentry/browser'

export const fileUpload = {
  state: {},
  mutations: {},
  actions: {
    async uploadFile(store, {
      fileObject,
      file,
    }) {
      const fileRef = storage.ref().child(fileObject.path).put(file)

      fileRef.on('state_changed', () => {
        // Do something while uploading
      }, (error) => {
        // Do something when it fails
        EventBus.$emit('file-upload-error', { path: fileObject.path })

        // Report to sentry
        Sentry.withScope((scope) => {
          scope.setLevel('error')
          scope.setExtra('path', fileObject.path)
          scope.setExtra('code', error.code)
          Sentry.captureMessage('ERROR: Unable to delete file')
        })
      }, () => {
        // Do something on succesful upload
        EventBus.$emit('file-uploaded', { path: fileObject.path })
      })
    },
    async deleteFile(store, path) {
      storage.ref().child(path).delete()
        .then(() => {
          // File deleted successfully
          EventBus.$emit('file-deleted', { path })
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          EventBus.$emit('file-deleted', { path })

          if (error.code === 'storage/object-not-found') {
            return
          }

          // Report to sentry
          Sentry.withScope((scope) => {
            scope.setLevel('warning')
            scope.setExtra('path', path)
            scope.setExtra('code', error.code)
            Sentry.captureMessage('ERROR: Unable to delete file')
          })
        })
    },
    addFileToScreening: ({ getters, dispatch }, fileObject) => {
      // Update screening
      const screening = new Screening({
        ...getters.currentScreeningData,
      })

      screening.files.push({ ...fileObject })

      dispatch('updateScreening', { screening })
    },
    removeFileFromScreening: ({ getters, dispatch }, path) => {
      // Update screening
      const screening = new Screening({
        ...getters.currentScreeningData,
      })

      screening.files = screening.files.filter((x) => x.path !== path)

      dispatch('updateScreening', { screening })
    },
  },
  getters: {},
}
