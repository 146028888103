import { db } from '@/firebase/init'
import { DB_SCREENINGS, DB_TEST_RESULTS } from '@/globals/javascript/models/_helper'
import { uploadAnalyses } from '@/store/analyses/uploadAnalyses'
import * as Sentry from '@sentry/browser'

export const analyses = {
  state: {
    analysesUploadStatus: '',
  },
  mutations: {
    updateAnalysesUploadStatus: (state, status) => {
      state.analysesUploadStatus = status
    },
  },
  actions: {
    updateAnalysesUploadStatus: ({ commit }, status) => {
      commit('updateAnalysesUploadStatus', status)
    },
    async resetAnalyzes({ getters }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const limit = 250
          const colRef = db
            .collection(DB_SCREENINGS)
            .doc(getters.currentScreening.id)
            .collection(DB_TEST_RESULTS)
          let snapshot = await colRef.limit(limit).get()
          let amountRead = snapshot.docs.length

          while (amountRead > 0) {
            const batch = db.batch()

            snapshot.docs.forEach((doc) => {
              batch.delete(doc.ref)
            })

            // eslint-disable-next-line no-await-in-loop
            await batch.commit()

            // eslint-disable-next-line no-await-in-loop
            snapshot = await colRef.limit(limit).get()
            amountRead = snapshot.docs.length
          }

          resolve()
        }
        catch (err) {
          Sentry.captureException(err)
          reject(err)
        }
      })
    },
    ...uploadAnalyses,
  },
  getters: {
    analysesUploadStatus: (state) => state.analysesUploadStatus,
  },
}
