/* eslint-disable prefer-promise-reject-errors */
import { mixWB } from '@/globals/javascript/_util/mixins'

export const extractAnalysesResultsEuroFins = (getters, rows) => new Promise((resolve, reject) => {
  const {
    caseNumber,
    personalSamplingID,
    laboratoriesAsArray,
    analysisTestsAsArray,
  } = getters

  const labItem = laboratoriesAsArray.find((x) => x.id === 'euroFins')
  const { excelFileColumnNames } = labItem

  // Rows
  const caseNumberRow = rows[2]
  const analysesNameRow = rows[6]
  const analysesUnitRow = rows[7]
  const allAnalysesRows = rows.splice(9)

  // Validate case number
  if (![caseNumber, personalSamplingID].includes(caseNumberRow[1])) {
    const answer = window.confirm(
      mixWB(
        'CASE_NUMBER_DOES_NOT_MATCH_TEXT_2',
        [caseNumberRow[1]],
      ),
    )
    if (!answer) {
      reject('wrong-case-number')
      return
    }
  }

  // Get column indexes of all present analyses
  const analysesColumns = analysesNameRow.reduce((prev, columnName, index) => {
    // Exclude columns with % as the unit
    const isPercentageUnit = analysesUnitRow[index] === '%'
    if (isPercentageUnit) {
      return prev
    }

    // Find matches
    const matches = excelFileColumnNames.filter((item) => {
      if (item.analysisID === 'cpShort') {
        return typeof columnName === 'string' && columnName.includes(item.columnName)
      }
      if (item.analysisID === 'cpMedium') {
        return typeof columnName === 'string' && columnName.includes(item.columnName)
      }

      return item.columnName === columnName
    })

    if (matches.length) {
      // Check if already existing
      // - This is required as older analysis results had 2 columns of 'Spor af Chlorparaffiner'
      const existingItem = prev.find((item) => item.columnName === columnName)

      // Update existing entry
      if (existingItem) {
        existingItem.columnIndex = index
        return prev
      }

      // Add new entry
      prev.push({
        columnIndex: index,
        columnName: matches[matches.length - 1].columnName,
        analysisID: matches[matches.length - 1].analysisID,
      })
    }
    return prev
  }, [])

  // Extract analyses results
  const analysesResults = allAnalysesRows.reduce((prev, row) => {
    const item = {
      sampleNumber: null,
      results: [],
    }

    // Get sample number
    const sampleIdentification = row[1] ?? ''
    const firstGroupOfWholeNumbersInName = sampleIdentification.match(/\d+/)?.[0] ?? undefined
    item.sampleNumber = Number(firstGroupOfWholeNumbersInName)

    // Check for sample number
    if (!item.sampleNumber) {
      return prev
    }

    // Get value of each analysis test
    analysisTestsAsArray.forEach((analysis) => {
      // Create item
      const resultItem = {
        id: analysis.id,
        value: null,
        isBelowValue: false,
        isAboveValue: false,
        isTooLowToMeasure: false,
        unit: analysis.unit || null,
        result: null,
        type: analysis.type,
      }

      // Add item
      item.results.push(resultItem)

      // Check for analysis is represented in file
      const analysisColumnItem = analysesColumns.find((x) => x.analysisID === analysis.id)
      if (!analysisColumnItem) {
        return
      }

      // Get and check value
      // - Possible values: Påvist, Ikke påvist, 10, <10, >10, #, null
      let value = row[analysisColumnItem.columnIndex]

      // - When no value
      if (!value) {
        return
      }

      // - Make sure value is a string
      value = value.toString()

      // - Convert commas to dots
      value = value.replace(',', '.')

      // - When value is too low to measure
      if (value === '#') {
        resultItem.isTooLowToMeasure = true
      }

      // - When value is below a limit
      else if (value.includes('<')) {
        resultItem.value = Number(value.replace('<', ''))
        resultItem.isBelowValue = true
      }

      // - When value is above a limit
      else if (value.includes('>')) {
        resultItem.value = Number(value.replace('>', ''))
        resultItem.isAboveValue = true
      }

      // - When value is just a number
      else if (analysis.type === 'value-based') {
        resultItem.value = Number(value)
      }

      // - When value is text
      else {
        resultItem.value = value
      }

      // Get result
      // - Value based
      if (analysis.type === 'value-based') {
        if (analysis.maxValue && resultItem.value >= analysis.maxValue) {
          resultItem.result = 2
        }
        else if (analysis.minValue && resultItem.value >= analysis.minValue) {
          resultItem.result = 1
        }
        else {
          resultItem.result = 0
        }
      }

      // - Prove based
      if (analysis.type === 'prove-based') {
        if (value === 'Påvist') {
          resultItem.result = 2
        }
        else {
          resultItem.result = 0
        }
      }

      // - Reset CP screening result
      // - We reset this, as a CP screening will never in it self contaminate anything
      if (analysis.id === 'cpScreening') {
        resultItem.result = 0
      }
    })

    prev.push(item)

    return prev
  }, [])

  resolve(analysesResults)
})
