import { roles } from '@/globals/javascript/roles.js'
import store from '@/store'
import Stats from './Stats'
import { DB_USERS, setIDOnCreate } from './_helper'

export default class User extends Stats {
  constructor({
    id,
    fullName,
    initials,
    email = '',
    phoneNumber = '',
    roles = [],
    accountID,
    nextPersonalSamplingID = 1337,
    isActive = true,
    mustUpdatePassword = false,
    firstTimeLogin = false,
    lastSeen = null,
    expiryDate,
    // Stats properties
    ...rest
  }) {
    super(rest)
    // Raw data
    this.id = setIDOnCreate(id, DB_USERS)
    this.fullName = fullName
    this.initials = initials
    this.email = email
    this.phoneNumber = phoneNumber
    this.roles = roles
    this.accountID = accountID
    this.nextPersonalSamplingID = nextPersonalSamplingID
    this.language = 'DK'
    this.isActive = isActive
    this.mustUpdatePassword = mustUpdatePassword
    this.firstTimeLogin = firstTimeLogin
    this.lastSeen = lastSeen
    this.expiryDate = expiryDate ?? ''
  }

  get isAdmin() {
    return this.roles.includes(roles.ROLE_ADMIN)
  }

  get isSuperUser() {
    if (this.isAdmin) {
      return true
    }
    return this.roles.includes(roles.ROLE_SUPER_USER)
  }

  get isScreener() {
    return this.roles.includes(roles.ROLE_SCREENER)
  }

  get isDeveloper() {
    return this.roles.includes(roles.ROLE_DEVELOPER)
  }

  get restrictions() {
    const restrictions = {
      isAdmin: this.isAdmin,
      isScreener: this.isScreener,
      isSuperUser: this.isSuperUser,
      isDeveloper: this.isDeveloper,
      canStartScreenings: false,
    }

    const account = store.getters.currentAccount

    if ((restrictions.isSuperUser || restrictions.isScreener) && account?.isActive) {
      restrictions.canStartScreenings = true
    }

    return restrictions
  }
}
