export const externalSamplesStatus = {
  externalSamplesStatus: (state, getters) => {
    const {
      screeningRelatedScreenings,
      screeningSamples,
    } = getters

    const data = {
      externalProjects: [],
      isUsed: false,
      isCompleted: false,
    }

    // Set data for each external project
    screeningRelatedScreenings.forEach((project) => {
      const {
        data: relatedProjectData,
        standardSamplesStatus: relatedProjectRelatedProjectData,
      } = project

      const projectData = {
        isUsed: false,
        projectID: relatedProjectData.id,
        caseNumber: relatedProjectData.getCaseNumber(),
        samples: [],
        isCompleted: false,
      }

      data.externalProjects.push(projectData)

      const samplesUsedFromThisExternalProject = screeningSamples.filter(
        (x) => x.otherScreeningID === relatedProjectData.id,
      )

      if (!samplesUsedFromThisExternalProject.length) {
        return
      }

      projectData.isUsed = true

      samplesUsedFromThisExternalProject.forEach((sample) => {
        const sampleData = {
          equivalentNumber: sample.equivalentNumber,
          equivalentSampleNumber: sample.equivalentSampleNumber,
          result: '',
          isCompleted: false,
        }

        projectData.samples.push(sampleData)

        const { samples: externalSamples } = project
        const externalSample = externalSamples.find(
          (x) => x.sampleNumber === sample.equivalentSampleNumber,
        )

        // Check if the sample from the other project exists
        if (!externalSample) {
          sampleData.result = 'not-found'
          return
        }

        // Check for standard samples status from other projekt
        if (!relatedProjectRelatedProjectData) {
          sampleData.result = 'awaiting-results'
          return
        }

        const externalSampleStatus = relatedProjectRelatedProjectData.list.find(
          (x) => x.sampleNumber === sample.equivalentSampleNumber,
        )

        if (!externalSampleStatus || !externalSampleStatus.isCompleted) {
          sampleData.result = 'awaiting-results'
          return
        }

        sampleData.result = 'ok'
        sampleData.isCompleted = true
      })

      projectData.isCompleted = projectData.samples.every((x) => x.isCompleted)
    })

    // Set overall is used status
    data.isUsed = data.externalProjects.some((x) => x.isUsed)

    // Set overall completed status
    if (!data.externalProjects.length) {
      data.isCompleted = true
    }
    else {
      let allIsCompleted = true

      data.externalProjects.forEach((project) => {
        if (!project.isUsed) {
          return
        }

        if (!project.isCompleted && allIsCompleted) {
          allIsCompleted = false
        }
      })

      data.isCompleted = allIsCompleted
    }

    return data
  },
}
