/* eslint-disable no-use-before-define */
import readXlsxFileBrowser from 'read-excel-file/commonjs/readXlsxFileBrowser'
import * as Sentry from '@sentry/browser'
import { extractAnalysesResultsEuroFins } from '@/store/analyses/extractAnalysesResultsEuroFins'
import { extractAnalysesResultsHojvang } from '@/store/analyses/extractAnalysesResultsHojvang'
import { DB_SCREENINGS, DB_TEST_RESULTS, setIDOnCreate } from '@/globals/javascript/models/_helper'
import { firebase, db } from '@/firebase/init'

export const uploadAnalyses = {
  uploadAnalyses: async ({ dispatch, getters }, { file, labID }) => {
    // 1. Convert excel file to rows
    let rows
    try {
      dispatch('updateAnalysesUploadStatus', 'converting-file')
      rows = await readXlsxFileBrowser(file)
    }
    catch (err) {
      dispatch('updateAnalysesUploadStatus', 'error-converting')
      Sentry.captureException(err)
      return
    }

    // 2. Extract analyses results
    dispatch('updateAnalysesUploadStatus', 'generating-results')

    let analysesResults
    try {
      if (['euroFins', 'milvaEuroFins'].includes(labID)) {
        analysesResults = await extractAnalysesResultsEuroFins(getters, rows)
      }
      if (labID === 'hojvang') {
        analysesResults = await extractAnalysesResultsHojvang(getters, rows)
      }
    }
    catch (err) {
      if (err === 'wrong-case-number') {
        dispatch('updateAnalysesUploadStatus', 'error-wrong-case-number')
        return
      }
      dispatch('updateAnalysesUploadStatus', 'error-extract-data')
      Sentry.captureException(err)
      return
    }

    // 3. Merge new and old analyses results
    analysesResults = mergeTestResults(analysesResults, getters)

    // 4. Save data
    dispatch('updateAnalysesUploadStatus', 'uploading-results')
    try {
      const batch = db.batch()
      const collectionRef = db.collection(DB_SCREENINGS)
        .doc(getters.currentScreening.id)
        .collection(DB_TEST_RESULTS)

      analysesResults.forEach((analysisResult) => {
        batch.set(collectionRef.doc(analysisResult.id), {
          ...analysisResult,
        })
      })

      await batch.commit()

      dispatch('updateAnalysesUploadStatus', 'upload-complete')
      dispatch('updateScreening', { updateTestResultStatus: true })
    }
    catch (err) {
      dispatch('updateAnalysesUploadStatus', 'error-upload-error')
      Sentry.captureException(err)
    }
  },
}

const mergeTestResults = (analysesResults, getters) => {
  const {
    screeningTestResults,
    currentUser,
    analysisTestsAsArray,
    analysisTestGroupsAsArray,
    ecoToxAnalysisTestIDListAsArray,
  } = getters

  const mergedAnalysesResults = []

  // Loop through each new test result
  analysesResults.forEach((testResult) => {
    // Find old test result for current test result
    const oldResults = screeningTestResults.find(
      (x) => x.sampleNumber === testResult.sampleNumber,
    )

    // Just add new results when no old results exists
    if (!oldResults) {
      mergedAnalysesResults.push(testResult)
      return
    }

    // Merge old and new results
    const combinedResult = {
      ...testResult,
      results: [],
    }

    testResult.results.forEach((result) => {
      // Add new result if present
      if (result.result !== null) {
        combinedResult.results.push(result)
        return
      }

      // Add old result if present
      const oldResult = oldResults.results.find((x) => x.id === result.id)
      if (oldResult && oldResult.result !== null) {
        combinedResult.results.push(oldResult)
        return
      }

      // Add empty result
      combinedResult.results.push(result)
    })

    mergedAnalysesResults.push(combinedResult)
  })

  // Make final object
  const finalTestResults = []
  mergedAnalysesResults.forEach((testResult) => {
    // Find existing test result if there
    const existingTestResult = screeningTestResults.find(
      (x) => x.sampleNumber === testResult.sampleNumber,
    )

    // Set eco tox
    setEcoToxTestResult(testResult, analysisTestsAsArray, ecoToxAnalysisTestIDListAsArray)

    // Get results
    const {
      wdcID,
      contaminatedGroupIDs,
      hazardiousGroupIDs,
    } = getWDCID(testResult, analysisTestGroupsAsArray)

    // Final object
    const finalResult = {
      ...testResult,
      id: setIDOnCreate(existingTestResult?.id, DB_TEST_RESULTS),
      wdcID,
      contaminatedGroupIDs,
      hazardiousGroupIDs,
      uploadDate: firebase.firestore.FieldValue.serverTimestamp(),
      userID: currentUser.id,
    }

    finalTestResults.push(finalResult)
  })

  return finalTestResults
}

const setEcoToxTestResult = (testResult, analysisTestsAsArray, ecoToxAnalysisTestIDListAsArray) => {
  const ecoToxItem = testResult.results.find((x) => x.id === 'eco-tox')
  const ecoToxAnalysesItem = analysisTestsAsArray.find((x) => x.id === 'eco-tox')

  let areAnyValuesFound = false
  const ecoToxValue = testResult.results.reduce((prev, result) => {
    if (!ecoToxAnalysisTestIDListAsArray.includes(result.id)) {
      return prev
    }

    if (!result.value) {
      return prev
    }

    areAnyValuesFound = true

    if (result.value > 1000) {
      prev += result.value
    }
    return prev
  }, 0)
  if (areAnyValuesFound) {
    ecoToxItem.value = ecoToxValue
    if (ecoToxValue > ecoToxAnalysesItem.maxValue) {
      ecoToxItem.result = 2
    }
    else if (ecoToxValue > ecoToxAnalysesItem.minValue) {
      ecoToxItem.result = 1
    }
    else {
      ecoToxItem.result = 0
    }
  }
}

const getWDCID = (testResult, analysisTestGroupsAsArray) => {
  const { hazardiousGroupIDs, contaminatedGroupIDs } = getContaminationGroups(
    testResult,
    analysisTestGroupsAsArray,
  )

  if (hazardiousGroupIDs.includes('ASB')) {
    // Asbestos + hazardous waste OR Asbestos only
    return {
      wdcID: hazardiousGroupIDs.length > 1 ? 'WDC-5' : 'WDC-4',
      contaminatedGroupIDs,
      hazardiousGroupIDs,
    }
  }

  // Hazardious
  if (hazardiousGroupIDs.length) {
    return {
      wdcID: 'WDC-3',
      contaminatedGroupIDs,
      hazardiousGroupIDs,
    }
  }

  // Contaminated
  if (contaminatedGroupIDs.length) {
    return {
      wdcID: 'WDC-2',
      contaminatedGroupIDs,
      hazardiousGroupIDs,
    }
  }

  // Clean / Safe
  return {
    wdcID: 'WDC-1',
    contaminatedGroupIDs,
    hazardiousGroupIDs,
  }
}

const getContaminationGroups = (
  testResult, analysisTestGroupsAsArray,
) => analysisTestGroupsAsArray.reduce(
  (prev, analysisGroup) => {
    const groupID = analysisGroup.id

    const supportedSamples = analysisGroup.analysisTestIDs
      .map((analysisID) => testResult.results.find((x) => x.id === analysisID))
      .filter(Boolean)

    const contaminationLevel = supportedSamples.reduce((prev, sample) => {
      if (!sample.result) {
        return prev
      }

      if (sample.result > prev) {
        prev = sample.result
      }

      return prev
    }, 0)

    switch (contaminationLevel) {
    case 1:
      prev.contaminatedGroupIDs.push(groupID)
      break

    case 2:
      prev.hazardiousGroupIDs.push(groupID)
      break

    default:
      break
    }

    return prev
  },
  {
    hazardiousGroupIDs: [],
    contaminatedGroupIDs: [],
  },
)
