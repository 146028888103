import { createSampleID } from '@/globals/javascript/models/_helper'
import { firebase } from '@/firebase/init'
import store from '@/store/index'
import { mixWB } from '@/globals/javascript/_util/mixins'

export default class Sample {
  constructor({
    id,
    screeningID = '',
    otherScreeningID = '',
    typeID = null,
    kindOfSample,
    status = 'not-done',
    images = {},

    // Booleans
    isAutoAssessment,

    // Results
    assessNumber = null,
    postponeNumber = null,
    equivalentNumber = null,
    equivalentSampleID = null,
    equivalentSampleNumber = null,
    sampleNumber = null,
    existingResultNumber = null,
    existingResultWDCID = null,
    existingResultExplanation = null,

    // Floor plan marker
    floorPlanUnit = null,
    floorPlanPosition = { x: null, y: null },

    // Timings
    createdAt = null,
    lastUpdated = null,
  }) {
    this.id = createSampleID(id, typeID, kindOfSample)
    this.screeningID = screeningID || store.getters.currentScreening.id
    this.otherScreeningID = otherScreeningID
    this.typeID = typeID
    this.kindOfSample = kindOfSample || 'material' // 'material' | 'coating' | 'dust'
    this.status = status
    this.images = {
      close: images.close || null,
      away: images.away || null,
    }

    // Booleans
    this.isAutoAssessment = isAutoAssessment || false

    // Numbers
    this.assessNumber = assessNumber
    this.postponeNumber = postponeNumber
    this.equivalentNumber = equivalentNumber
    this.equivalentSampleID = equivalentSampleID
    this.equivalentSampleNumber = equivalentSampleNumber
    this.sampleNumber = sampleNumber
    this.existingResultNumber = existingResultNumber
    this.existingResultWDCID = existingResultWDCID
    this.existingResultExplanation = existingResultExplanation

    this.floorPlanUnit = floorPlanUnit
    this.floorPlanPosition = {
      x: floorPlanPosition?.x ?? null,
      y: floorPlanPosition?.y ?? null,
    }

    // Timings
    this.createdAt = createdAt || firebase.firestore.FieldValue.serverTimestamp()
    this.lastUpdated = lastUpdated || firebase.firestore.FieldValue.serverTimestamp()
  }

  reset(status = 'not-done') {
    this.status = status
    this.images = {
      close: null,
      away: null,
    }

    this.otherScreeningID = ''
    this.assessNumber = null
    this.postponeNumber = null
    this.equivalentNumber = null
    this.equivalentSampleID = null
    this.equivalentSampleNumber = null
    this.existingResultNumber = null
    this.existingResultWDCID = null
    this.existingResultExplanation = null
    this.sampleNumber = null
    this.resetFloorPlan()
  }

  resetFloorPlan() {
    this.floorPlanPosition = { x: null, y: null }
    this.floorPlanUnit = null
  }

  getNumber() {
    if (this.sampleNumber) {
      return `P${ this.sampleNumber }`
    }

    if (this.assessNumber) {
      return `V${ this.assessNumber }`
    }

    if (this.postponeNumber) {
      return `U${ this.postponeNumber }`
    }

    if (this.equivalentNumber) {
      return `T${ this.equivalentNumber }`
    }

    if (this.existingResultNumber) {
      return `E${ this.existingResultNumber }`
    }

    return false
  }

  getFullTitle() {
    const { screeningTypes } = store.getters
    const type = screeningTypes.find((x) => x.id === this.typeID)

    let title = ''

    title += `${
      type.getTitle({ category: true, details: true })
    }`

    return title
  }

  getKindOfSampleText() {
    if (this.assessNumber) {
      if (this.kindOfSample === 'material') {
        return mixWB('ASSESSMENT_OF_MATERIAL')
      }
      if (this.kindOfSample === 'coating') {
        return mixWB('ASSESSMENT_OF_COATING')
      }
      if (this.kindOfSample === 'dust') {
        return mixWB('ASSESSMENT_OF_DUST')
      }
    }

    if (this.kindOfSample === 'material') {
      return mixWB('SAMPLE_OF_MATERIAL')
    }
    if (this.kindOfSample === 'coating') {
      return mixWB('SAMPLE_OF_COATING')
    }
    if (this.kindOfSample === 'dust') {
      return mixWB('SAMPLE_OF_DUST')
    }

    return ''
  }

  getRelatedScreeningCaseNumber() {
    const {
      screeningRelatedScreenings,
    } = store.getters

    const relatedScreening = screeningRelatedScreenings.find(
      (x) => x.data.id === this.otherScreeningID,
    )

    return relatedScreening?.data?.getCaseNumber() || '-'
  }

  getRiskOfText() {
    const { screeningTypes } = store.getters
    const type = screeningTypes.find((x) => x.id === this.typeID)

    if (this.kindOfSample === 'material') {
      return type.getMaterialSampleList()
    }
    if (this.kindOfSample === 'coating') {
      return type.getCoatingSampleList()
    }
    return mixWB('UNKNOWN')
  }

  getSampleIDs(options = {}) {
    const {
      screeningTypes,
      currentScreening,
      screeningRelatedScreenings,
    } = store.getters

    const isSampleFromOtherProjekt = this.screeningID !== currentScreening.id

    // Find type to use
    let type = null

    // - Find type in other projekt
    if (isSampleFromOtherProjekt) {
      const otherProjekt = screeningRelatedScreenings.find(
        (x) => x.data.id === this.screeningID,
      )

      if (!otherProjekt) {
        return []
      }

      type = otherProjekt.types.find((x) => x.id === this.typeID)
    }

    // - Find type in this projekt
    else {
      type = screeningTypes.find((x) => x.id === this.typeID)
    }

    if (!type) {
      return []
    }

    if (this.kindOfSample === 'material') {
      return type.getMaterialSampleList(options)
    }
    if (this.kindOfSample === 'coating') {
      return type.getCoatingSampleList(options)
    }
    if (this.kindOfSample === 'dust') {
      return type.getDustSampleList(options)
    }

    return []
  }
}
