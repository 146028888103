const isDevelopment = process.env.NODE_ENV === 'development'
const isStage = process.env.VUE_APP_MODE === 'stage'
const isProduction = process.env.NODE_ENV === 'production'
const isLocalhost = window.location.host.indexOf('localhost') > -1

export const ENV = Object.freeze({
  isDevelopment,
  isStage,
  isProduction,
  isLocalhost,
})
