import { firebase } from '@/firebase/init'
import { DB_ACCOUNTS, setIDOnCreate } from '@/globals/javascript/models/_helper'
import { mixWB } from '@/globals/javascript/_util/mixins'
import store from '@/store'
import Stats from './Stats'

export default class Account extends Stats {
  constructor({
    id,
    name,
    baseCountry,
    address,
    postalCode,
    city,
    contactPerson,
    companyInitials,
    nextCaseNumber = 1337,
    primaryLabID,
    analysisDeliveryTimeOptionID,
    euroFinsNumber,
    hojvangNumber,
    analysisReportEmail,
    useMandatoryManualCaseNumbers = false,
    useSamplingID = false,
    isActive = true,
    allowMilvaEuroFins = false,
    billPerRequisition = false,
    historyLog = [],
    createdDate = firebase.firestore.FieldValue.serverTimestamp(),
    images,
    // Stats properties
    ...rest
  }) {
    super(rest)

    // Raw data
    this.id = setIDOnCreate(id, DB_ACCOUNTS)
    this.name = name
    this.baseCountry = baseCountry
    this.address = address
    this.postalCode = postalCode
    this.city = city
    this.contactPerson = {
      name: contactPerson?.name ?? '',
      phoneNumber: contactPerson?.phoneNumber ?? '',
      email: contactPerson?.email ?? '',
    }
    this.companyInitials = companyInitials
    this.nextCaseNumber = nextCaseNumber
    this.primaryLabID = primaryLabID ?? 'euroFins'
    this.analysisDeliveryTimeOptionID = analysisDeliveryTimeOptionID ?? 'ADO-6'
    this.euroFinsNumber = euroFinsNumber ?? ''
    this.hojvangNumber = hojvangNumber ?? ''
    this.analysisReportEmail = analysisReportEmail ?? ''
    this.useSamplingID = useSamplingID
    this.useMandatoryManualCaseNumbers = useMandatoryManualCaseNumbers
    this.isActive = isActive
    this.allowMilvaEuroFins = allowMilvaEuroFins
    this.billPerRequisition = billPerRequisition
    this.historyLog = historyLog
    this.createdDate = createdDate
    this.images = {
      mainLogo: images?.mainLogo ?? '',
      emailLogo: images?.emailLogo ?? '',
    }
  }

  // Getters
  getCountryName() {
    const { allCountries } = store.getters

    const country = allCountries.find((x) => x.countryCode === this.baseCountry)
    return mixWB(country.translation)
  }
}
