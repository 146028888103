/* eslint-disable no-use-before-define */
import { mixWB } from '@/globals/javascript/_util/mixins'
import store from '@/store/index'
import { firebase } from '@/firebase/init'
import { getTimeSpendText } from '@/globals/javascript/_util/util'
import { DB_SCREENINGS, setIDOnCreate } from './_helper'
import { HOST } from '../_util/host'

const TRACKING_MAP = {
  created: 'isCreatedSet',
  started: 'isStartedSet',
  completed: 'isCompletedSet',
  requisitionSent: 'isRequisitionSentSet',
  billable: 'isBillableSet',
}

export default class Screening {
  constructor({
    id = null,
    enterpriseID = '',
    createdByUserID = null,
    userID = null,
    appVersion = null,
    accountID = null,
    caseNumber = '',
    customCaseNumber = '',
    samplingID = null,
    countryCode = 'DK',

    // Derived data
    address,
    projectType = null,
    builderFullName = null,
    selectedUnits = {
      count: 0,
      ids: [],
      buildings: [],
    },
    frontImage = null,
    skippedCategories = {
      general: [],
    },
    requisition,
    requisitionFiles = [],
    interConnectionGroupAmounts = [],
    reportNotes = '',
    files = [],
    market,

    // Hub accounts
    hubAccountIDs,
    hubAccountItems,

    // Booleans
    isStarted = false,
    isCompleted = false,
    isArchived = false,
    hasSavedBBRUnits = false,
    isTestDataUploaded = false,
    isRequisitionSent = false,
    isScreening = false,
    isSkipAmount = false,
    useMandatoryManualCaseNumbers = false,

    // Timings
    createdAt = null,
    lastUpdated = null,
    screeningYear = null,
    screeningMonth = null,
    screeningArchivedTime = null,
    screeningTimeTracking = [],
    screeningStartTime = null,
    screeningEndTime = null,
    screeningReportDeliveredDate = '',
    screeningRevisedDate = '',

    statsTracking = {
      isCreatedSet: false,
      isStartedSet: false,
      isCompletedSet: false,
      isRequisitionSentSet: false,
      isBillableSet: false,
    },

  }) {
    this.id = setIDOnCreate(id, DB_SCREENINGS)
    this.enterpriseID = enterpriseID
    this.createdByUserID = createdByUserID || store.getters.currentUser.id
    this.userID = userID
    this.appVersion = appVersion || store.getters.packageVersion
    this.accountID = accountID
    this.caseNumber = caseNumber
    this.customCaseNumber = customCaseNumber
    this.samplingID = samplingID
    this.countryCode = countryCode

    // Derived data
    this.address = {
      isBBRAddress: address?.isBBRAddress ?? true,
      address: address?.address || '',
      postalCode: address?.postalCode || '',
      city: address?.city || '',
    }
    this.projectType = projectType
    this.builderFullName = builderFullName
    this.selectedUnits = {
      count: selectedUnits.count || 0,
      ids: selectedUnits.ids || [],
      buildings: selectedUnits.buildings || [],
    }
    this.frontImage = frontImage
    this.skippedCategories = {
      ...skippedCategories,
    }
    this.requisitionFiles = requisitionFiles
    this.requisition = {
      laboratoryID: requisition?.laboratoryID || 'euroFins',
      deliveryOptionID: requisition?.deliveryOptionID || 'ADO-6',
      customOfferNumber: requisition?.customOfferNumber || '',
      eanNumber: requisition?.eanNumber || '',
      extraEmails: requisition?.extraEmails || '',
    }
    this.interConnectionGroupAmounts = interConnectionGroupAmounts
    this.reportNotes = reportNotes
    this.files = files
    this.market = {
      isApproved: market?.isApproved || false,
      contactEmail: market?.contactEmail || '',
      expiryDate: market?.expiryDate || '',
    }

    // Hub accounts
    this.hubAccountIDs = hubAccountIDs || []
    this.hubAccountItems = hubAccountItems || []

    // Booleans
    this.isStarted = isStarted
    this.isCompleted = isCompleted
    this.isArchived = isArchived
    this.hasSavedBBRUnits = hasSavedBBRUnits
    this.isTestDataUploaded = isTestDataUploaded
    this.isRequisitionSent = isRequisitionSent
    this.isScreening = isScreening
    this.isSkipAmount = isSkipAmount
    this.useMandatoryManualCaseNumbers = useMandatoryManualCaseNumbers

    // Timings
    this.createdAt = createdAt || firebase.firestore.FieldValue.serverTimestamp()
    this.lastUpdated = lastUpdated || firebase.firestore.FieldValue.serverTimestamp()
    this.screeningYear = screeningYear || new Date().getFullYear()
    this.screeningMonth = screeningMonth || new Date().getMonth() + 1
    this.screeningArchivedTime = screeningArchivedTime
    this.screeningTimeTracking = screeningTimeTracking
    this.screeningStartTime = screeningStartTime
    this.screeningEndTime = screeningEndTime
    this.screeningReportDeliveredDate = screeningReportDeliveredDate
    this.screeningRevisedDate = screeningRevisedDate

    // Stats tracking
    this.statsTracking = {
      isCreatedSet: statsTracking.isCreatedSet || false,
      isStartedSet: statsTracking.isStartedSet || false,
      isCompletedSet: statsTracking.isCompletedSet || false,
      isRequisitionSentSet: statsTracking.isRequisitionSentSet || false,
      isBillableSet: statsTracking.isBillableSet || false,
    }
  }

  get screeningOwnerHUBAccount() {
    const { hubAccountsAsObject } = store.getters

    const ownerItem = this.hubAccountItems.find(
      (x) => x.role === 'project-owner',
    )

    return ownerItem ? hubAccountsAsObject[ownerItem.hubAccountID] : null
  }

  // Setters
  setTestResultsUploadStatus() {
    const {
      standardSamplesStatus,
      externalSamplesStatus,
    } = store.getters

    this.isTestDataUploaded = standardSamplesStatus.isCompleted && externalSamplesStatus.isCompleted
  }

  // Getters
  getCaseNumber() {
    if (this.customCaseNumber) {
      return this.customCaseNumber
    }
    if (this.caseNumber) {
      return this.caseNumber
    }
    return ''
  }

  getSamplingID() {
    const {
      currentAccount,
      allUsers,
    } = store.getters

    const user = allUsers.find((x) => x.id === this.userID)
    if (
      !user
      || !currentAccount?.companyInitials
      || !this.samplingID
    ) {
      return ''
    }

    // Deprecated logic - only has numbers saved
    if (this.samplingID.toString().length === 4) {
      return `${ currentAccount.companyInitials }-${ user.initials }-${ this.samplingID }`
    }

    return this.samplingID
  }

  getCaseNumberAndSamplingIDText() {
    const caseNumber = this.getCaseNumber()
    const samplingID = this.getSamplingID()

    if (caseNumber && samplingID) {
      return `${ caseNumber } // ${ samplingID }`
    }

    if (caseNumber) {
      return caseNumber
    }

    if (samplingID) {
      return samplingID
    }

    return ''
  }

  getStatus() {
    // Not started
    if (!this.isStarted) {
      return {
        id: 'not-started',
        class: 'FlowClass1',
        text: mixWB('STATUS_NOT_STARTED'),
      }
    }

    // Started
    if (!this.isCompleted && !this.isTestDataUploaded) {
      return {
        id: 'started',
        class: 'FlowClass1',
        text: mixWB('STATUS_STARTED'),
      }
    }

    // Under revision
    if (!this.isCompleted) {
      return {
        id: 'under-revision',
        class: 'FlowClass1',
        text: mixWB('UNDER_REVISION'),
      }
    }

    // Awaiting analyzes
    if (!this.isTestDataUploaded) {
      return {
        id: 'awaiting-analyses',
        class: 'FlowClass2',
        text: mixWB('STATUS_AWAITING_TEST_RESULTS'),
      }
    }

    // Report ready
    return {
      id: 'report-ready',
      class: 'FlowClass3',
      text: mixWB('STATUS_REPORT_READY'),
    }
  }

  getAddressLine() {
    const { currentAccount, allCountries } = store.getters

    let address = `${
      this.address.address
    }, ${
      this.address.postalCode
    } ${
      this.address.city
    }`

    if (currentAccount.baseCountry !== this.countryCode) {
      const country = allCountries.find((x) => x.countryCode === this.countryCode)

      address += `, ${ mixWB(country.translation) }`
    }

    return address
  }

  getCountryName() {
    const { allCountries } = store.getters

    const country = allCountries.find((x) => x.countryCode === this.countryCode)
    return mixWB(country.translation)
  }

  // Methods
  updateScreeningTimeUsed() {
    // Add first entry
    if (!this.screeningTimeTracking.length) {
      const newInterval = {
        start: new Date().getTime(),
        last: new Date().getTime(),
      }
      this.screeningTimeTracking.push(newInterval)
      return
    }

    const intervalToUse = this.screeningTimeTracking[this.screeningTimeTracking.length - 1]
    const maxPause = 30 * 60 * 1000 // 30 min
    const currentTime = new Date().getTime()
    const diff = currentTime - intervalToUse.last

    // Pause exeeded - add new entry
    if (diff > maxPause) {
      const newInterval = {
        start: new Date().getTime(),
        last: new Date().getTime(),
      }
      this.screeningTimeTracking.push(newInterval)
      return
    }

    intervalToUse.last = currentTime
  }

  getScreeningTimeUsed() {
    // Handle old screenings
    if (!this.screeningTimeTracking.length && (this.screeningStartTime && this.screeningEndTime)) {
      return getTimeSpendText({
        start: this.screeningStartTime,
        end: this.screeningEndTime,
      })
    }

    if (!this.screeningTimeTracking.length) {
      return false
    }

    const accumulatedTimeUsed = this.screeningTimeTracking.reduce((prev, interval) => {
      const diff = interval.last - interval.start
      if (diff) {
        prev += diff
      }

      return prev
    }, 0)

    if (!accumulatedTimeUsed) {
      return false
    }

    return getTimeSpendText({
      duration: accumulatedTimeUsed,
    })
  }

  getBuildingNames() {
    const buildingNames = this.selectedUnits.buildings.reduce((prev, building) => {
      if (building.bbrBuildingNo) {
        prev.push(`${ mixWB('BUILDING') } ${ building.bbrBuildingNo }`)
      }
      else {
        prev.push(building.customTitle)
      }

      return prev
    }, [])

    return buildingNames.sort()
  }

  getMilvaOnlineLink() {
    // Open in dev
    return `${ HOST.online }/project/${ this.id }`
  }

  trackStats(name) {
    this.statsTracking[TRACKING_MAP[name]] = true
  }

  hasStatsTracking(name) {
    return this.statsTracking[TRACKING_MAP[name]]
  }

  static showInBackend(screeningID) {
    const url = `${ HOST.firestore }/~2FScreenings~2F${ screeningID }`
    window.open(url, '_blank')
  }

  // Getters for alternate texts between mapping and screening
  getCommentsOnProjectTitle() {
    return this.isScreening
      ? mixWB('COMMENTS_ON_THE_SCREENING')
      : mixWB('COMMENTS_ON_THE_MAPPING')
  }

  getCanBeFurtherExplainedInCommentsText() {
    return this.isScreening
      ? mixWB('ENTER_RESULT_EXPLANATION_SUB_SCREENING_TEXT')
      : mixWB('ENTER_RESULT_EXPLANATION_SUB_TEXT')
  }
}
