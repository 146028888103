import store from '@/store'
import { union } from 'lodash-es'

export const checkToAddPCBToType = (type, kindOfSample) => {
  const {
    currentScreening,
    screeningPCBScreenings,
    screeningRelatedScreenings,
  } = store.getters

  let pcbScreeningsToUse = []

  if (type.screeningID !== currentScreening.id) {
    const otherProjekt = screeningRelatedScreenings.find(
      (x) => x.data.id === type.screeningID,
    )

    pcbScreeningsToUse = otherProjekt?.pcbScreenings || []
  }
  else {
    pcbScreeningsToUse = screeningPCBScreenings
  }

  // Check for custom type
  if (type.type.customTypeName && kindOfSample === 'material') {
    return false
  }

  // Check if PCB is already in the list
  if (kindOfSample === 'material' && type.samples.sampleIDs.includes('ST-4')) {
    return false
  }
  if (kindOfSample === 'coating' && type.coating.samples.sampleIDs.includes('ST-4')) {
    return false
  }

  // Check category - only inside + installations + soft joints
  const category = type.getCategory()
  if (category.areaID !== 'AREA-2') {
    if (!['C-12', 'C-15'].includes(category.id)) {
      return false
    }
  }

  // Check material
  if (kindOfSample === 'material') {
    const material = type.getMaterial()
    if (!material || material?.options?.excludeFromPCBScreening) {
      return false
    }
  }

  // Get building IDs
  const typeUnits = type.getUnits()
  const buildingIDs = typeUnits.reduce((prev, unit) => {
    const buildingID = unit.getBuildingID()

    prev = union(prev, [buildingID])

    return prev
  }, [])

  // Check if type units requires PCB testing
  let needsPCBTesting = false
  buildingIDs.forEach((buildingID) => {
    if (needsPCBTesting) {
      return
    }

    const pcbScreening = pcbScreeningsToUse.find((x) => x.id === buildingID)

    if (pcbScreening && pcbScreening.needsTesting) {
      needsPCBTesting = true
    }
  })

  if (!needsPCBTesting) {
    return false
  }

  return true
}
