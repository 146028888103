export const accountChecklist = [
  'accountLoaded',
  'hubAccountsLoaded',
  'allUsersLoaded',
  'allResourcesLoaded',
]

export const allScreeningsChecklist = [
  'allScreeningsLoaded',
  'accountLoaded',
  'hubAccountsLoaded',
  'allUsersLoaded',
  'currentUserLoaded',
  'allResourcesLoaded',
]

export const singleScreeningChecklist = [
  'accountLoaded',
  'hubAccountsLoaded',
  'allUsersLoaded',
  'allResourcesLoaded',
  'currentScreeningLoaded',
  'relatedScreeningsLoaded',
  'currentScreeningMetaDataLoaded',
  'currentScreeningTypesLoaded',
  'currentScreeningInterConnectionsLoaded',
  'currentScreeningSamplesLoaded',
  'currentScreeningUnitsLoaded',
  'currentScreeningPCBScreeningLoaded',
  'addressImagesLoaded',
  'currentScreeningTestResultsLoaded',
]
