import { allScreeningsChecklist, singleScreeningChecklist } from './_checklists'

export const screeningRoutes = [
  // All screenings
  {
    path: '/screenings',
    name: 'AllScreenings',
    meta: {
      requiresAuth: true,
      checklist: [
        ...allScreeningsChecklist,
      ],
    },
    component: () => import('../views/AllScreenings.vue'),
  },

  // Single screening
  // {
  //   path: '/screenings/new',
  //   name: 'ScreeningsNew',
  //   meta: {
  //     requiresAuth: true,
  //     requiresActiveAccount: true,
  //     checklist: [
  //       ...allScreeningsChecklist,
  //     ],
  //   },
  //   component: () => import('../views/ScreeningsNew.vue'),
  // },
  {
    path: '/screenings/update/:screeningID',
    name: 'ScreeningsUpdate',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdate.vue'),
  },
  {
    path: '/screenings/update/:screeningID/project-details',
    name: 'ScreeningsUpdateProjectDetails',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdateProjectDetails.vue'),
  },
  {
    path: '/screenings/update/:screeningID/buildings',
    name: 'ScreeningsUpdateBuildings',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdateBuildings.vue'),
  },
  {
    path: '/screenings/update/:screeningID/builder',
    name: 'ScreeningsUpdateBuilder',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdateBuilder.vue'),
  },
  {
    path: '/screenings/update/:screeningID/category/:categoryID',
    name: 'ScreeningsUpdateCategory',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      requiresCategoryID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdateCategory.vue'),
  },
  {
    path: '/screenings/update/:screeningID/category/:categoryID/add-type',
    name: 'ScreeningsUpdateCategoryAddType',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      requiresCategoryID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdateCategoryAddOrEditType.vue'),
  },
  {
    path: '/screenings/update/:screeningID/category/:categoryID/edit-type/:addedTypeID',
    name: 'ScreeningsUpdateCategoryEditType',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      requiresCategoryID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdateCategoryAddOrEditType.vue'),
  },
  {
    path: '/screenings/update/:screeningID/samples',
    name: 'ScreeningsUpdateSamples',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdateSamples.vue'),
  },
  {
    path: '/screenings/update/:screeningID/pcb-screening-schema',
    name: 'ScreeningsUpdatePSCScreeningSchema',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdatePCBScreeningSchema.vue'),
  },
  {
    path: '/screenings/update/:screeningID/profile-images',
    name: 'ScreeningsUpdateProfileImages',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdateProfileImages.vue'),
  },
  {
    path: '/screenings/update/:screeningID/floor-plans',
    name: 'ScreeningsUpdateFloorPlans',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdateFloorPlans.vue'),
  },
  {
    path: '/screenings/update/:screeningID/amounts',
    name: 'ScreeningsUpdateAmounts',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdateAmounts.vue'),
  },
  {
    path: '/screenings/update/:screeningID/amounts/interconnections',
    name: 'ScreeningsUpdateAmountsInterconnections',
    meta: {
      requiresAuth: true,
      requiresActiveAccount: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/ScreeningsUpdateAmountsInterconnections.vue'),
  },
]
