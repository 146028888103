import { firebase, db } from '@/firebase/init'
import { DB_SAMPLES, DB_SCREENINGS, DB_TEST_RESULTS } from '@/globals/javascript/models/_helper'

export const samples = {
  getScreeningSamples: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningSamplesLoaded) {
      return
    }
    const unsubscribe = db.collection(DB_SCREENINGS)
      .doc(screeningID).collection(DB_SAMPLES)
      .onSnapshot((querySnapshot) => {
        const samples = []
        querySnapshot.forEach((doc) => {
          samples.push({
            ...doc.data(),
            id: doc.id,
          })
        })

        commit('updateCurrentScreeningSamples', { samples, unsubscribe })
      })
  },
  setScreeningSample: ({ getters, dispatch }, { sample, isNew }) => {
    db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_SAMPLES)
      .doc(sample.id)
      .set({
        ...sample,
        lastUpdated: isNew ? sample.lastUpdated : firebase.firestore.FieldValue.serverTimestamp(),
      })

    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })
  },
  deleteScreeningSample: ({ getters, dispatch }, sample) => {
    const {
      screeningSamples,
      currentScreening,
      screeningTestResults,
    } = getters

    const sampleIDsToBeDeleted = [sample.id]

    // Standard sample
    if (sample.sampleNumber) {
      // Find equivalent samples to delete as well
      screeningSamples.forEach((otherSample) => {
        if (
          !otherSample.otherScreeningID
          && otherSample.equivalentSampleNumber === sample.sampleNumber
        ) {
          sampleIDsToBeDeleted.push(otherSample.id)
        }
      })
    }

    const batch = db.batch()

    // Update batch with samples to be deleted
    const colRef = db
      .collection(DB_SCREENINGS)
      .doc(currentScreening.id)
      .collection(DB_SAMPLES)

    sampleIDsToBeDeleted.forEach((sampleID) => {
      const docRef = colRef.doc(sampleID)
      batch.delete(docRef)
    })

    // Update batch with test results to be deleted
    if (sample.sampleNumber) {
      const testResult = screeningTestResults.find((x) => x.sampleNumber === sample.sampleNumber)

      if (testResult) {
        const docRef = db
          .collection(DB_SCREENINGS)
          .doc(currentScreening.id)
          .collection(DB_TEST_RESULTS)
          .doc(testResult.id)
        batch.delete(docRef)
      }
    }

    // Commit batch
    batch.commit()

    dispatch('updateScreening', { setAsStarted: true, setAsNotCompleted: true })
  },
  updateAffectedSamples: ({ getters, dispatch }, type) => {
    const {
      screeningSamples,
      screeningTypes,
    } = getters

    /**
     * Material samples
     */
    const materialSample = screeningSamples.find(
      (x) => x.kindOfSample === 'material' && x.typeID === type.id,
    )

    if (materialSample) {
      const sampleIDs = type.getMaterialSampleList({ onlyIDs: true })

      // Check to delete sample
      if (!sampleIDs.length) {
        dispatch('deleteScreeningSample', materialSample)
      }

      // Check to update equivalent types
      if (materialSample.sampleNumber) {
        const equivalentSamples = screeningSamples.filter(
          (x) => !x.otherScreeningID
            && x.equivalentSampleNumber === materialSample.sampleNumber,
        )

        equivalentSamples.forEach((equivalentSample) => {
          const equivalentType = screeningTypes.find((x) => x.id === equivalentSample.typeID)

          dispatch('setTypeProps', {
            typeID: equivalentType.id,
            props: {
              samples: { ...type.samples },
            },
            updateScreening: false,
          })
        })
      }
    }

    /**
     * Coating samples
     */
    const standardSampleCoating = screeningSamples.find(
      (x) => x.sampleNumber && x.kindOfSample === 'coating' && x.typeID === type.id,
    )

    // When sample is found
    if (standardSampleCoating) {
      // Check to delete sample
      // - This is no required as coating will never not have a sample

      // Check to update equivalent samples
      const equivalentSamples = screeningSamples.filter(
        (x) => !x.otherScreeningID
          && x.equivalentSampleNumber === standardSampleCoating.sampleNumber,
      )

      equivalentSamples.forEach((equivalentSample) => {
        const equivalentType = screeningTypes.find((x) => x.id === equivalentSample.typeID)

        dispatch('setTypeProps', {
          typeID: equivalentType.id,
          props: {
            coating: { ...type.coating },
          },
          updateScreening: false,
        })
      })
    }

    /**
     * Dust samples
     */
    const standardSampleDust = screeningSamples.find(
      (x) => x.sampleNumber && x.kindOfSample === 'dust' && x.typeID === type.id,
    )

    // When sample is found
    if (standardSampleDust) {
      const sampleIDs = type.getDustSampleList({ onlyIDs: true })

      // Check to delete sample
      if (!sampleIDs.length) {
        dispatch('deleteScreeningSample', standardSampleDust)
      }

      // Check to update equivalent samples
      const equivalentSamples = screeningSamples.filter(
        (x) => !x.otherScreeningID
          && x.equivalentSampleNumber === standardSampleDust.sampleNumber,
      )
      equivalentSamples.forEach((equivalentSample) => {
        const equivalentType = screeningTypes.find((x) => x.id === equivalentSample.typeID)

        dispatch('setTypeProps', {
          typeID: equivalentType.id,
          props: {
            dust: { ...type.dust },
          },
          updateScreening: false,
        })
      })
    }
  },
}
