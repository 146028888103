import { mixWB } from '@/globals/javascript/_util/mixins'
import store from '@/store'

export default class RCategoryType {
  constructor({
    id,
    translation,
    categoryID,
    typeID,
    materialData,
    options,
  }) {
    this.id = id
    this.translation = translation || ''
    this.categoryID = categoryID || ''
    this.typeID = typeID
    this.materialData = materialData || {}
    this.options = {
      askForPlaster: options.askForPlaster || false,
      askForWallpaper: options.askForWallpaper || false,
      askForCoating: options.askForCoating || false,
      askForUnderlining: options.askForUnderlining || false,
      hideResale: options.hideResale || false,
      isActive: options.isActive || true,
      isArchived: options.isArchived || false,
    }
  }

  getTitle() {
    if (this.translation) {
      return mixWB(this.translation)
    }

    const type = this.getResourceType()
    return mixWB(type.translation)
  }

  getResourceType() {
    const { typesAsObject } = store.getters

    return typesAsObject[this.typeID]
  }
}
