import { sortBy, union } from 'lodash-es'
import { Building } from '@/globals/javascript/models/units/Building'
import { Apartment } from '@/globals/javascript/models/units/Apartment'
import { Floor } from '@/globals/javascript/models/units/Floor'
import { mixWB } from '@/globals/javascript/_util/mixins'
import Sample from '@/globals/javascript/models/Sample'

export const getters = {
  // Loaded getters
  allScreeningsLoaded: (state) => !!state.unsubscribeAllScreenings,
  addressImagesLoaded: (state) => !!state.unsubscribeAddressImages,
  currentScreeningLoaded: (state) => !!state.unsubscribeScreening,
  currentScreeningMetaDataLoaded: (state) => !!state.unsubscribeMetaData,
  currentScreeningSamplesLoaded: (state) => !!state.unsubscribeSamples,
  currentScreeningUnitsLoaded: (state) => !!state.unsubscribeUnits,
  currentScreeningPCBScreeningLoaded: (state) => !!state.unsubscribePCBScreenings,
  currentScreeningTypesLoaded: (state) => !!state.unsubscribeTypes,
  currentScreeningInterConnectionsLoaded: (state) => !!state.unsubscribeInterConnections,
  currentScreeningTestResultsLoaded: (state) => !!state.unsubscribeTestResults,
  relatedScreeningsLoaded: (state, getters) => {
    if (!getters.currentScreeningLoaded) {
      return false
    }
    if (!getters.currentScreeningData.enterpriseID) {
      return true
    }
    return !!state.unsubscribeRelatedScreenings
  },

  // Other getters
  currentScreening: (state) => state.currentScreening,
  currentScreeningData: (state) => state.currentScreening.data,
  skippedCategoryIDs: (state) => {
    if (!state.currentScreening || !state.currentScreening.data) {
      return []
    }
    return state.currentScreening.data.skippedCategoryIDs || []
  },
  skippedCategories: (state, getters) => {
    const {
      currentScreeningData,
      selectedUnits,
    } = getters

    const general = currentScreeningData.skippedCategories
      && currentScreeningData.skippedCategories.general
      ? currentScreeningData.skippedCategories.general
      : []

    return selectedUnits.reduce((prev, unit) => {
      prev[unit.id] = currentScreeningData.skippedCategories
      && currentScreeningData.skippedCategories[unit.id]
        ? currentScreeningData.skippedCategories[unit.id]
        : []
      return prev
    }, {
      general,
    })
  },
  allScreenings: (state) => state.allScreenings,
  metaData: (state) => state.currentScreening.metaData || {},
  projectType: (state) => {
    if (state.currentScreening.data) {
      return state.currentScreening.data.projectType
    }
    return null
  },
  combinedListOfUnits: (state, getters) => getters.screeningUnits.buildings.concat(
    getters.screeningUnits.floors,
    getters.screeningUnits.apartments,
  ),
  noOfSelectedUnits: (state) => {
    if (!state.currentScreening.data.selectedUnits) {
      return 0
    }
    return state.currentScreening.data.selectedUnits.count
  },
  selectedUnitIDs: (state, getters) => {
    const {
      currentScreeningData,
    } = getters

    return currentScreeningData?.selectedUnits?.ids || []
  },
  selectedUnits: (state, getters) => {
    const list = getters.combinedListOfUnits.filter(
      (x) => getters.selectedUnitIDs.includes(x.id),
    )

    return sortBy(list, ['typeSorting', 'data.buildingID', 'data.numericFloorValue'])
  },
  screeningUnits: (state) => {
    if (!state.currentScreening.units) {
      return { buildings: [], floors: [], apartments: [] }
    }
    const units = state.currentScreening.units.reduce((prev, unit) => {
      if (unit.type === 'building') {
        const building = new Building({
          ...unit,
        })
        prev.buildings.push(building)
      }
      if (unit.type === 'apartment') {
        const apartment = new Apartment({
          ...unit,
        })
        prev.apartments.push(apartment)
      }
      if (unit.type === 'floor') {
        const floor = new Floor({
          ...unit,
        })
        prev.floors.push(floor)
      }
      return prev
    }, { buildings: [], floors: [], apartments: [] })

    units.buildings = sortBy(units.buildings, ['data.sortingNumber'])
    units.floors = sortBy(units.floors, ['data.numericFloorValue'])
    units.apartments = sortBy(units.apartments, ['data.numericFloorValue', 'bbrData.dør'])

    return units
  },
  buildingsInUse: (state, getters) => {
    const {
      selectedUnits,
      combinedListOfUnits,
    } = getters

    let buildingIDs = []

    selectedUnits.forEach((unit) => {
      buildingIDs = union(buildingIDs, [unit.getBuildingID()])
    })

    return buildingIDs.map((x) => combinedListOfUnits.find((y) => y.id === x))
  },
  currentScreeningSelectedFilterUnitID: (state) => state.currentScreeningSelectedFilterUnitID,
  builderFullName: (state) => {
    if (state.currentScreening.data) return state.currentScreening.data.builderFullName
    return null
  },
  screeningTypes: (state) => state.currentScreening.types,
  typesWithSamples: (state, getters) => {
    const { screeningTypes } = getters

    if (!screeningTypes || !screeningTypes.length) {
      return []
    }

    return screeningTypes.filter((type) => {
      const materialSampleIDs = type.getMaterialSampleList({ onlyIDs: true })
      const coatingSampleIDs = type.getCoatingSampleList({ onlyIDs: true })
      const dustSampleIDs = type.getDustSampleList({ onlyIDs: true })

      return !!(materialSampleIDs.length || coatingSampleIDs.length || dustSampleIDs.length)
    })
  },
  screeningInterConnections: (state) => state.currentScreening.interConnections || [],
  addressImages: (state) => state.currentScreening.addressImages,
  screeningSamples: (state) => {
    if (!state.currentScreening.samples) {
      return []
    }
    return state.currentScreening.samples.map((sample) => new Sample({
      ...sample,
    }))
  },
  screeningSamplesAsObject: (state, getters) => {
    if (!getters.screeningSamples) {
      return {}
    }

    return getters.screeningSamples.reduce((prev, sample) => {
      prev[sample.id] = sample
      return prev
    }, {})
  },
  screeningPCBScreenings: (state) => state.currentScreening.pcbScreenings,
  screeningTestResults: (state) => {
    if (!state.currentScreening.testResults) {
      return null
    }

    return state.currentScreening.testResults
  },
  screeningRelatedScreenings: (state) => state.currentScreening.relatedScreenings,
  isFirstStepDone: (state, getters) => {
    if (!getters.noOfSelectedUnits) {
      return false
    }

    if (!getters.projectType) {
      return false
    }

    return true
  },
  caseNumber: (state) => {
    if (!state.currentScreening.data) {
      return false
    }

    return state.currentScreening.data.getCaseNumber()
  },
  personalSamplingID: (state) => {
    if (!state.currentScreening.data) {
      return ''
    }

    return state.currentScreening.data.getSamplingID()
  },
  caseNumberAndSamplingIDText: (
    state,
  ) => state.currentScreening.data.getCaseNumberAndSamplingIDText(),
  imageUploadFolder: (state) => {
    if (
      !state.currentScreening
      || !state.currentScreening.data
      || !state.currentScreening.id
    ) {
      return false
    }

    if (
      state.currentScreening.data.screeningYear === 2021
       && state.currentScreening.data.screeningMonth < 7
    ) {
      return `Screenings-${
        state.currentScreening.data.screeningYear
      }-${
        state.currentScreening.data.screeningMonth
      }/${
        state.currentScreening.id
      }/`
    }

    return `AccountScreenings/${
      state.currentScreening.data.accountID
    }/${
      state.currentScreening.data.screeningYear
    }-${
      state.currentScreening.data.screeningMonth
    }/${
      state.currentScreening.id
    }/`
  },
  currentCategory: (state, getters) => {
    const { categoriesAsObject, currentRoute } = getters

    if (!currentRoute.params || !currentRoute.params.categoryID) {
      return false
    }

    return categoriesAsObject[currentRoute.params.categoryID]
  },
  currentCategoryTitle: (state, getters) => {
    const { currentCategory } = getters

    if (!currentCategory) {
      return ''
    }
    return mixWB(currentCategory.translation)
  },
  addedTypesForCategory: (state, getters) => {
    const {
      screeningTypes,
      currentRoute,
      currentScreeningSelectedFilterUnitID,
    } = getters

    if (!currentRoute.params || !currentRoute.params.categoryID) {
      return []
    }

    if (!screeningTypes || !screeningTypes.length) {
      return []
    }

    if (currentScreeningSelectedFilterUnitID) {
      return screeningTypes.filter((x) => x.categoryID === currentRoute.params.categoryID
      && x.unitIDs.includes(currentScreeningSelectedFilterUnitID))
    }
    return screeningTypes.filter((x) => x.categoryID === currentRoute.params.categoryID)
  },
  nextAssessNumber: (state, getters) => {
    const { screeningSamples } = getters

    const assessNumbers = screeningSamples.reduce((prev, sample) => {
      if (sample.assessNumber) {
        prev.push(sample.assessNumber)
      }
      return prev
    }, []).sort((a, b) => a - b)

    if (!assessNumbers.length) {
      return 1
    }

    let nextNumber = null
    assessNumbers.forEach((number, index) => {
      if (nextNumber) {
        return
      }
      if (number !== index + 1) {
        nextNumber = index + 1
      }
    })

    return nextNumber || assessNumbers[assessNumbers.length - 1] + 1
  },
  currentEnterpriseID: (state) => state.currentScreening.currentEnterpriseID,
}
