import { copyProjectOptions } from '@/store/screenings/actions/copyProject'
import { forceUpdateStore } from '@/store/screenings/actions/forceUpdateStore'
import { samplesAndAnalysesStatus } from '@/store/screenings/getters/samplesAndAnalysesStatus/samplesAndAnalysesStatus'
import { getters } from './getters/getters'
import { mutations } from './mutations'
import { actions } from './actions/actions'
import { types } from './actions/types'
import { interConnections } from './actions/interConnections'
import { samples } from './actions/samples'
import { units } from './actions/units'

export const screenings = {
  state: {
    allScreenings: [],
    currentScreening: {
      id: null,
      data: null,
      metaData: null,
      buildings: null,
      units: null,
      types: null,
      interConnections: null,
      addressImages: null,
      samples: null,
      pcbScreenings: null,
      testResults: null,
      relatedScreenings: [],
      currentEnterpriseID: '',
    },
    currentScreeningSelectedFilterUnitID: null,

    // Snapshot listeners
    // - Current screening
    unsubscribeScreening: null,
    unsubscribeRelatedScreenings: null,
    unsubscribeMetaData: null,
    unsubscribeBuildings: null,
    unsubscribeUnits: null,
    unsubscribeTypes: null,
    unsubscribeInterConnections: null,
    unsubscribeAddressImages: null,
    unsubscribeSamples: null,
    unsubscribePCBScreenings: null,
    unsubscribeTestResults: null,

    // Current user
    unsubscribeAllScreenings: null,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
    ...types,
    ...interConnections,
    ...samples,
    ...units,
    ...copyProjectOptions,
    ...forceUpdateStore,
  },
  getters: {
    ...getters,
    ...samplesAndAnalysesStatus,
  },
}
