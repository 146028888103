import Vue from 'vue'
import App from '@/App'
import './registerServiceWorker'
import './globals/stylus/reset.styl'
import axios from 'axios'
import { mixins } from '@/globals/javascript/_util/mixins'

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

// Backup testing
import store from './store'
import router from './router'
import { auth } from './firebase/init'
import { setupPWAChanges } from './setupPWAChanges'
import { ENV } from './globals/javascript/_util/env'

Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.config.devtools = ENV.isStage || ENV.isDevelopment

Vue.mixin(mixins)

// Sentry
if (ENV.isProduction) {
  Sentry.init({
    dsn: 'https://3391d423fb5a441a834df785f3859df4@o460398.ingest.sentry.io/5460518',
    environment: process.env.NODE_ENV,
    release: `frontend@${ process.env.PACKAGE_VERSION }`,
    tracesSampleRate: 0.1,
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
        tracingOptions: {
          trackComponents: true,
        },
        logErrors: true,
      }),
      new Integrations.BrowserTracing(),
    ],
  })
}

// Apply pwa changes for next domain - set to true to force the changes (localhost only)
setupPWAChanges()

const render = () => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
}

const params = new URLSearchParams(window.location.search)

const authToken = params.get('auth')
// Check if we have a Service Account token to log in with
if (authToken) {
  auth.signInWithCustomToken(authToken)
    .then(render)
}
else {
  render()
}

