<template>
  <div class="MobileMenu">
    <!-- Header -->
    <div class="Header">
      <router-link
        class="LeftSide"
        :to="{ name: 'AccountUser', params: { userID: currentUser.id }}">
        <div class="Initials">{{ currentUser.initials }}</div>
        <span class="Name">{{ currentUser.fullName }}</span>
      </router-link>
      <div
        class="RightSide"
        :class="{ SettingsMenuActive: settingsMenuOpen }"
        @click="onToggleSettingsMenu">
        <div
          class="IconWrap">
          <SettingsIcon />
        </div>
      </div>
    </div>
    <div
      class="SettingsMenu"
      v-if="settingsMenuOpen">
      <div
        class="Section"
        @click="onCloseMenu">
        <router-link
          v-if="!isDemoUser"
          :to="{ name: 'AccountUser', params: { userID: currentUser.id }}">
          <NavItem
            :title="mixWB('MY_PROFILE')" />
        </router-link>
        <router-link
          v-if="currentUser.isAdmin || currentUser.isSuperUser"
          :to="{ name: 'Account'}">
          <NavItem
            :title="mixWB('ACCOUNT')" />
        </router-link>
        <div @click="onLangSelectClick">
          <NavItem :title="mixWB('SELECT_LANGUAGE')" />
        </div>
      </div>
      <div class="Section">
        <Dropdown
          name="allAccounts"
          :placeholder="mixWB('CHOOSE_ACCOUNT')"
          backgroundColor="white"
          v-if="currentUser.isAdmin"
          :options="allAccountsOptions"
          @on-update="onAccountChange" />
      </div>

      <div
        class="LogOut"
        @click="onLogOutClick">
        <div class="IconWrap">
          <LogoutIcon />
        </div>
        <span>{{ mixWB('LOG_OUT') }}</span>
      </div>
    </div>

    <!-- Body -->
    <div v-else>
      <!-- Screening options -->
      <div
        class="Section"
        @click="onCloseMenu">
        <router-link :to="{ name: 'AllScreenings' }">
          <NavItem
            :title="mixWB('ALL_PROJECTS')"
            icon="list" />
        </router-link>
      </div>

      <!-- Other -->
      <div class="Section Other">
        <div class="ToggleItem">
          <ToggleButton
            groupName="mobile-menu-options"
            value="show-uploads"
            :isSelected="showUploadStatusList"
            :text="mixWB('SHOW_UPLOADS')"
            @check="onToggleShowUploads" />
        </div>
        <div v-if="isUserAdmin">
          <router-link
            :to="{ name: 'ChangelogOverview' }">
            <NavItem :title="mixWB('EDIT_CHANGELOG')" />
          </router-link>
        </div>
        <div v-if="isUserAdmin">
          <a :href="adminLink">
            <NavItem :title="mixWB('ADMIN_LINK')" />
          </a>
        </div>
        <div v-if="isUserDeveloperAndAdmin">
          <router-link
            :to="{ name: 'MigrateAllProjects' }">
            <NavItem :title="mixWB('Migerer alle projekter')" />
          </router-link>
        </div>
      </div>

      <!-- Log out -->
      <div
        class="LogOut"
        @click="onLogOutClick">
        <div class="IconWrap">
          <LogoutIcon />
        </div>
        <span>{{ mixWB('LOG_OUT') }}</span>
      </div>
    </div>

    <div class="Bottom">
      <!-- Change log -->
      <span
        class="Changelog"
        @click="onChangelogClick">{{ mixWB('VIEW_LATEST_UPDATES') }}</span>

      <!-- Version -->
      <span class="PackageVersion">{{ getPackageVersionText() }}</span>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SettingsIcon from '@/assets/svg/settings.svg?inline'
import LogoutIcon from '@/assets/svg/logout.svg?inline'
import NavItem from '@/components/MobileMenu/NavItem.vue'
import ToggleButton from '@/components/FormElements/ToggleButton.vue'
import EventBus from '@/EventBus'
import Dropdown from '@/components/FormElements/Dropdown.vue'
import { ENV } from '@/globals/javascript/_util/env'

export default {
  name: 'MobileMenu',
  data() {
    return {
      settingsMenuOpen: false,
      adminLink: ENV.isStage || ENV.isDevelopment
        ? 'https://admin.dev-pro.milva.dk/admin'
        : 'https://admin.pro.milva.dk/admin',
    }
  },
  computed: {
    ...mapGetters([
      'isPageLoading',
      'currentUser',
      'currentUserRestrictions',
      'packageVersion',
      'showUploadStatusList',
      'allAccounts',
      'currentUserAccountId',
      'isDemoUser',
    ]),
    showNewOption() {
      if (this.isPageLoading || !this.currentUserRestrictions.canStartScreenings) {
        return false
      }
      return true
    },
    isUserAdmin() {
      return this.currentUser.isAdmin
    },
    isUserDeveloperAndAdmin() {
      return this.currentUser.isAdmin && this.currentUser.isDeveloper
    },
    allAccountsOptions() {
      return this.allAccounts.map((account) => ({
        value: account.id,
        name: account.name,
      }))
    },
  },
  methods: {
    onToggleSettingsMenu() {
      this.settingsMenuOpen = !this.settingsMenuOpen
    },
    onToggleShowUploads() {
      this.$store.dispatch('toggleShowUploadStatusList')

      setTimeout(() => {
        this.onCloseMenu()
      }, 150)
    },
    onLangSelectClick() {
      EventBus.$emit('show-lang-select')
    },
    onCloseMenu() {
      this.$emit('close-side-pane')
    },
    onLogOutClick() {
      this.$router.push({ name: 'LogOut' })
    },
    onChangelogClick() {
      EventBus.$emit('show-change-log')
      this.onCloseMenu()
    },
    getPackageVersionText() {
      return `${ this.mixWB('VERSION_X', [this.packageVersion]) } @ ${ process.env.NODE_ENV }`
    },
    onAccountChange({ value }) {
      this.$store.dispatch('accountChange', { accountID: value })
    },
  },
  components: {
    SettingsIcon,
    LogoutIcon,
    NavItem,
    ToggleButton,
    Dropdown,
  },
}
</script>

<style lang="stylus" scoped>
  .Header
    display flex
    align-items center
    justify-content space-between
    height 60px
    padding-left 20px
    border-bottom 1px solid $color_grey_lighter
    .LeftSide
      display flex
      align-items center
      .Initials
        circle(36px)
        background-color $color_primary
        flex-center-children()
        margin-right 10px
        color #fff
        font-weight bold
      .Name
        display block
        font-weight bold
    .RightSide
      display flex
      justify-content flex-end
      align-items center
      flex-shrink 0
      padding 0 20px
      height 100%
      cursor pointer
      .IconWrap
        box(20px)
      svg
        transition all 300ms ease
      &.SettingsMenuActive svg
        fill black
        transform rotate(90deg)

  .Section
    border-top 5px solid $color_grey_lighter
    &.Other
      > div
        border-bottom 1px solid $color_grey_lighter
        &:last-child
          border-bottom none

  .ToggleItem
    display flex
    align-items center
    min-height 60px
    padding-left 20px

  .LogOut
    display flex
    align-items center
    justify-content center
    height 60px
    cursor pointer
    border-top 5px solid $color_grey_lighter
    .IconWrap
      box(20px)
      margin-right 10px

  .Bottom
    position absolute
    bottom 10px
    left 0
    width 100%
    span
      display block
      text-align center
      font-size 0.765rem
      line-height 1
    .Changelog
      text-decoration underline
      margin-bottom 10px
      color $color_salmon_light
      cursor pointer
    .PackageVersion
      text-align center
      padding-left 10px
      padding-right 10px
      width 100%
      color $color_grey
  >>> .Dropdown
    margin-bottom 0
    select
      height 60px
      border none
    .Placeholder
      left 10px
      top 8px
      color $color_grey_dark
</style>
