import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

import { screeningRoutes } from '@/router/screening'
import { resultRoutes } from '@/router/result'
import { accountRoutes } from '@/router/account'
import {
  waitForInitialized,
  checkAllIsLoaded,
  checkAllIsLoadedPromise,
  waitForCurrentUser,
  waitForLanguages,
} from '@/router/helper'
import { accountChecklist, allScreeningsChecklist, singleScreeningChecklist } from './_checklists'

Vue.use(VueRouter)

const routes = [
  ...screeningRoutes,
  ...resultRoutes,
  ...accountRoutes,

  // General
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'SignIn',
    meta: {
      requiresGuest: true,
    },
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    meta: {
      requiresAuth: true,
      checklist: [
        ...accountChecklist,
      ],
    },
    component: () => import('../views/ChangePassword.vue'),
  },
  {
    path: '/log-out',
    name: 'LogOut',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/LogOut.vue'),
  },

  // Requisition
  {
    path: '/requisition/:screeningID',
    name: 'RequisitionFlow',
    meta: {
      requiresAuth: true,
      requiresScreeningID: true,
      checklist: [
        ...singleScreeningChecklist,
      ],
    },
    component: () => import('../views/RequisitionFlow/RequisitionFlow.vue'),
    redirect: { name: 'RequisitionCreation' },
    children: [
      {
        path: 'create',
        name: 'RequisitionCreation',
        meta: {
          requiresAuth: true,
          requiresScreeningID: true,
          checklist: [
            ...singleScreeningChecklist,
          ],
        },
        component: () => import('../views/RequisitionFlow/RequisitionCreation.vue'),
      },
      {
        path: 'approve',
        name: 'RequisitionApprove',
        meta: {
          requiresAuth: true,
          requiresScreeningID: true,
          checklist: [
            ...singleScreeningChecklist,
          ],
        },
        component: () => import('../views/RequisitionFlow/RequisitionApprove.vue'),
      },
    ],
  },

  // Admin
  {
    path: '/admin/changelog-overview',
    name: 'ChangelogOverview',
    meta: {
      requiresAuth: true,
      checklist: [
        ...allScreeningsChecklist,
      ],
    },
    component: () => import('../views/Admin/ChangelogOverview.vue'),
  },
  {
    path: '/admin/migrate-all-projects',
    name: 'MigrateAllProjects',
    meta: {
      requiresAuth: true,
      checklist: [
        ...allScreeningsChecklist,
      ],
    },
    component: () => import('../views/Admin/MigrateAllProjects.vue'),
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: '',
})

// Check for newer version
router.beforeEach(async (to, from, next) => {
  // Never use serviceWorker in development
  if (process.env.NODE_ENV === 'development') {
    next()
    return
  }

  // If first load from.name will be null - thus avoid ekstra sw update on first load
  if (from.name === null) {
    next()
    return
  }

  // eslint-disable-next-line max-len
  // Trying to re-register our SW on route changes to force updates if chache or SW file has changed
  const reg = await navigator.serviceWorker.register(
    `${ process.env.BASE_URL }service-worker.js`,
  )

  reg.update()

  next()
})

// Check logged in or not
router.beforeEach((to, from, next) => {
  const waitForLoad = setInterval(() => {
    const { isAppInitialized } = store.getters

    if (!isAppInitialized) return

    clearInterval(waitForLoad)
    const { isLoggedIn } = store.getters

    // Make sure user is logged in
    if (!isLoggedIn && to.meta.requiresAuth) {
      router.push({ name: 'SignIn' })
      return
    }

    // Make sure user is not logged in
    if (isLoggedIn && to.meta.requiresGuest) {
      router.push({ name: 'AllScreenings' })
      return
    }

    // Check user expiry date
    const { currentUser } = store.getters
    if (isLoggedIn && currentUser?.expiryDate && to.name !== 'LogOut') {
      const now = new Date().getTime()
      const expiryDate = new Date(currentUser.expiryDate).getTime()

      if (now > expiryDate) {
        router.push({ name: 'LogOut', query: { reason: 'demo-expired' } })
        return
      }
    }

    // Set scroll position
    store.dispatch('updateScrollBeforeLeave')

    next()
  }, 50)
})

// Check for requirements
router.beforeEach((to, from, next) => {
  // Makes sure that screening ID is set
  if (to.meta.requiresScreeningID) {
    // Check for ID in URL
    if (!to.params.screeningID) {
      router.push({ name: 'AllScreenings' })
      return
    }
  }

  // Make sure that category ID is set
  if (to.meta.requiresCategoryID) {
    // Check for ID in URL
    if (!to.params.categoryID) {
      router.push({ name: 'ScreeningsUpdate', params: { screeningID: to.params.screeningID } })
      return
    }
  }

  next()
})

// Do checklist
router.beforeEach(async (to, from, next) => {
  await waitForInitialized(store)

  const checklist = to.meta.checklist || []

  // Add check that are always required
  checklist.push('languagesLoaded', 'translationsLoaded', 'countriesLoaded')

  if (checklist.length) {
    if (checklist.includes('languagesLoaded')) {
      store.dispatch('getLanguages')
    }
    if (checklist.includes('translationsLoaded')) {
      await waitForLanguages(store)
      store.dispatch('getTranslations')
    }
    if (checklist.includes('countriesLoaded')) {
      store.dispatch('getCountries')
    }
    if (checklist.includes('accountLoaded')) {
      await waitForCurrentUser(store)
      store.dispatch('getAccount')
    }
    if (checklist.includes('hubAccountsLoaded')) {
      store.dispatch('getHubAccounts')
    }
    if (checklist.includes('allUsersLoaded')) {
      store.dispatch('getAllUsers')
    }
    if (checklist.includes('allResourcesLoaded')) {
      store.dispatch('getAllResources')
    }
    if (checklist.includes('allScreeningsLoaded')) {
      store.dispatch('getAllScreenings')
    }
    if (checklist.includes('currentScreeningLoaded')) {
      store.dispatch('getCurrentScreening', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningMetaDataLoaded')) {
      store.dispatch('getScreeningMetaData', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningTypesLoaded')) {
      store.dispatch('getScreeningTypes', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningInterConnectionsLoaded')) {
      store.dispatch('getScreeningInterconnections', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningSamplesLoaded')) {
      store.dispatch('getScreeningSamples', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningUnitsLoaded')) {
      store.dispatch('getScreeningUnits', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningPCBScreeningLoaded')) {
      store.dispatch('getScreeningPCBScreenings', to.params.screeningID)
    }
    if (checklist.includes('addressImagesLoaded')) {
      store.dispatch('getScreeningAddressImages', to.params.screeningID)
    }
    if (checklist.includes('currentScreeningTestResultsLoaded')) {
      store.dispatch('getCurrentScreeningTestResults', to.params.screeningID)
    }
  }

  const answer = checkAllIsLoaded({ store, checklist })
  if (answer) {
    store.dispatch('setPageLoad', false)
    next()
    return
  }

  // Wait until all requirements are loaded
  store.dispatch('setPageLoad', true)
  await checkAllIsLoadedPromise({ store, checklist })

  if (to.meta.requiresActiveAccount) {
    const { isActive } = store.getters.currentAccount
    if (!isActive) {
      router.push({ name: 'AllScreenings' })
      return
    }
  }

  if (to.meta.requiresScreeningID) {
    // Make sure we are allowed to view this screening
    const accountID = store.getters.currentAccount.id
    const screeningAccountId = store.getters.currentScreening.data.accountID

    if (screeningAccountId && screeningAccountId !== accountID) {
      router.push({ name: 'AllScreenings' })
      return
    }
  }

  next()
})

// Check for super user access
router.beforeEach((to, from, next) => {
  const user = store.getters.currentUser
  if (to.meta.requiresSuperUser) {
    if (!user.isSuperUser && !user.isAdmin) {
      router.push({ name: 'AllScreenings' })
      return
    }
  }

  if (from.name !== 'ChangePassword' && to.name !== 'ChangePassword' && user?.mustUpdatePassword) {
    router.push({ name: 'ChangePassword' })
    return
  }

  next()
})

// // Check to add hotjar
// router.beforeEach((to, from, next) => {
//   // Check for LIVE site
//   // if (process.env.NODE_ENV !== 'production') {
//   //   next()
//   //   return
//   // }

//   // Check for logged in user
//   const { isLoggedIn } = store.getters
//   if (!isLoggedIn) {
//     next()
//     return
//   }

//   // Check for Holdensen account
//   const user = store.getters.currentUser
//   // if (user.accountID === 'bpZulvjI5VnFL9zVO0zb') {
//   //   next()
//   //   return
//   // }

//   if (!window.hj) {
//     // Add script
//     const hotjarScript = document.createElement('script')
//     hotjarScript.setAttribute('src', `${ process.env.BASE_URL }scripts/hotjar.js`)
//     hotjarScript.setAttribute('crossorigin', 'anonymous')
//     document.head.appendChild(hotjarScript)

//     // Add event (may not work due to free plan)
//     const account = store.getters.currentAccount
//     // eslint-disable-next-line func-names
//     window.hj = window.hj || function () {
//       // eslint-disable-next-line prefer-rest-params
//       (window.hj.q = window.hj.q || []).push(arguments)
//     }
//     window.hj('event', 'hotjar_started')
//     window.hj('event', `Account: ${ account.name }`)
//     window.hj('event', `Account ID: ${ account.id }`)
//     window.hj('event', `User: ${ user.fullName }`)
//     window.hj('event', `User email: ${ user.email }`)
//     window.hj('event', `User ID: ${ user.id }`)
//   }

//   next()
// })

router.afterEach(() => {
  store.dispatch('setPageLoad', false)

  // Check to show changelog - no longer required
  // if (to.meta.requiresAuth && !['LogOut', 'ChangePassword'].includes(to.name)) {
  //   requestAnimationFrame(() => {
  //     EventBus.$emit('check-to-show-changelog')
  //   })
  // }

  // Update user last seen timestamp - no longer required
  // const user = store.getters.currentUser
  // const thirtyMintuesAgo = moment().subtract(30, 'minutes')
  // if (user && (!user.lastSeen || moment(user.lastSeen.toDate()) < thirtyMintuesAgo)) {
  //   store.dispatch('updateUserLastSeen')
  // }

  // Check to set selected unit
  store.dispatch('checkToSetSelectedUnit')
})

export default router
