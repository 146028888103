import { ENV } from '@/globals/javascript/_util/env'

export const setupPWAChanges = () => {
  // Find current case
  const isDev = ENV.isDevelopment || ENV.isStage

  if (!isDev) {
    return
  }

  // Get needed data
  const newPath = '/v2-dev'
  const title = 'Milva Pro V2 (dev)'

  // Update title
  const siteTitle = document.head.querySelector('title')
  siteTitle.innerText = title

  // Update manifest
  const siteManifest = document.head.querySelector('link[rel="manifest"]')
  siteManifest.setAttribute('href', '/v2-dev/manifest.json')

  // Update icons
  const siteIcons = document.head.querySelectorAll(
    'link[rel="apple-touch-icon"], link[rel="icon"], meta[name="msapplication-TileImage"]',
  )

  siteIcons.forEach((icon) => {
    const attributeName = icon.hasAttribute('content') ? 'content' : 'href'
    const oldValue = icon.getAttribute(attributeName)

    const newValue = `${ newPath }${ oldValue }`
    icon.setAttribute(attributeName, newValue)
  })
}
