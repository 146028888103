/* eslint-disable no-use-before-define */
import store from '@/store'
import { storage } from '@/firebase/init'
import moment from 'moment-with-locales-es6'

function wordbook(translations, key, params = []) {
  if (!key || !translations) {
    return ''
  }

  const translation = translations[key] || false

  let textToUse

  if (!translation) {
    textToUse = key.replace('W: ', '')
  }
  else {
    textToUse = translation
  }

  params.forEach((param) => {
    textToUse = textToUse.replace('%s', param)
  })

  return textToUse
}

export const mixins = {
  methods: {
    mixWBBase(key, params = []) {
      const { baseTranslations } = store.getters

      return wordbook(baseTranslations, key, params)
    },
    mixWB(key, params = []) {
      const { translations } = store.getters

      return wordbook(translations, key, params)
    },
    mixGetDownloadURL({ path }) {
      return new Promise((resolve, reject) => {
        storage.ref().child(path).getDownloadURL()
          .then((url) => {
            resolve(url)
          })
          .catch(() => {
            reject()
          })
      })
    },
    mixGetDate(dateObject, formatting = 'lll') {
      if (!dateObject) {
        return ''
      }
      const theDate = dateObject.toDate
        ? moment(dateObject.toDate())
        : moment(dateObject)
      theDate.locale('da-DK')
      const formattedDate = theDate.format(formatting)
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
    },
    mixGetImageSizeItem({ imageObject, size }) {
      return imageObject.sizes
        ? imageObject.sizes.find((x) => x.name === size)
        : null
    },
    mixGetSizeWordbook(size) {
      if (size === 'small') {
        return mixWB('SMALL')
      }
      return mixWB('BASE')
    },
    mixGetSampleStatusWordbook(status) {
      if (status === 'skip-asbest') {
        return mixWB('ASBESTOS')
      }
      if (status === 'skip-hazardous-waste') {
        return mixWB('HAZARDOUS_SMALL_AMOUNT')
      }
      if (status === 'skip-hazardous-asbestos-waste') {
        return mixWB('HAZARDOUS_ASBESTOS_SMALL_AMOUNT')
      }
      if (status === 'auto-assess-asbestos-perforated-ceiling-tiles') {
        return mixWB('ASBESTOS_PERFORATED_CEILING_TILES')
      }
      return mixWB('OTHER')
    },
    mixCompareVersionsNewerOnly(versionA, versionB) {
      if (!versionA || !versionB) {
        return false
      }

      const versionAArray = versionA.split('.')
      const versionBArray = versionB.split('.')

      let isVersionANewer = null
      versionBArray.forEach((num, index) => {
        if (isVersionANewer !== null) {
          return
        }
        if (Number(num) === Number(versionAArray[index])) {
          return
        }
        isVersionANewer = Number(num) < Number(versionAArray[index])
      })

      if (isVersionANewer === null) {
        return false
      }

      return isVersionANewer
    },
    mixCompareVersionsSameOrNewer({
      versionA,
      versionB,
    }) {
      if (versionA === versionB) {
        return true
      }

      const versionAArray = versionA.split('.')
      const versionBArray = versionB.split('.')

      let isSameOrNewer = true
      let stopLoop = false
      versionAArray.forEach((num, index) => {
        if (stopLoop) {
          return
        }
        if (Number(num) > Number(versionBArray[index])) {
          stopLoop = true
        }
        if (Number(num) < Number(versionBArray[index])) {
          isSameOrNewer = false
          stopLoop = true
        }
      })

      return isSameOrNewer
    },
    mixFormatNumber(number, decimals = false) {
      if (Number.isNaN(Number(number))) {
        return number
      }

      const userLang = store.getters.selectedLanguage.locale
        || navigator.language
        || navigator.userLanguage

      // Find decimals
      let decimalsToUse = decimals
      if (decimals === false) {
        const numberArray = number.toString().split('.')
        if (numberArray.length > 1) {
          decimalsToUse = numberArray[1].length
        }
        else {
          decimalsToUse = 0
        }
      }

      return parseFloat(parseFloat(number).toFixed(decimalsToUse)).toLocaleString(
        userLang,
        {
          minimumFractionDigits: decimals,
        },
      )
    },
    mixFormatPhoneNumber(number) {
      if (number.toString().length === 8) {
        const array = number.split('')
        return array.reduce((prev, char, index) => {
          prev += char

          if (index !== array.length - 1 && index % 2 !== 0) {
            prev += ' '
          }
          return prev
        }, '')
      }
      return number
    },
    mixGetReportTitle() {
      const { currentScreeningData } = store.getters
      const projectAddress = currentScreeningData.address

      return `Milva miljørapport - ${
        projectAddress.address
      }, ${
        projectAddress.postalCode
      } ${
        projectAddress.city
      } - ${
        store.getters.caseNumber
      }`
    },
    mixGetListAsText({
      idList, objectWithAllIDs, includeAnd = false, useBaseTranslation = false,
    }) {
      return idList.reduce((prev, id, index) => {
        const objectTranslation = useBaseTranslation
          ? mixWBBase(objectWithAllIDs[id].translation)
          : mixWB(objectWithAllIDs[id].translation)

        if (index === 0) {
          prev += objectTranslation
          return prev
        }

        if (includeAnd && index === idList.length - 1) {
          prev = `${
            prev
          } ${
            useBaseTranslation ? mixWBBase('AND') : mixWB('AND')
          } ${
            objectTranslation.toLowerCase()
          }`
          return prev
        }

        prev = `${ prev }, ${ objectTranslation.toLowerCase() }`

        return prev
      }, '')
    },
    mixGetUnitTitlesAsList({ unitIDs, listOfUnits }) {
      return unitIDs.map((unitID) => {
        const unit = listOfUnits.find((x) => x.id === unitID)

        return unit.getFullTitle()
      })
    },
  },
}

export const { mixWB } = mixins.methods
export const { mixWBBase } = mixins.methods
export const { mixFormatNumber } = mixins.methods
export const { mixFormatPhoneNumber } = mixins.methods
export const { mixGetDownloadURL } = mixins.methods
export const { mixGetSizeWordbook } = mixins.methods
export const { mixGetListAsText } = mixins.methods
export const { mixGetUnitTitlesAsList } = mixins.methods
export const { mixCompareVersionsNewerOnly } = mixins.methods
export const { mixCompareVersionsSameOrNewer } = mixins.methods

/**
 * Centralized setup for handling beforeunload event with custom clean option
 * @param {string} showPrompt - name of reactive property to be read
 * @param {string} callbackOnLeave - name of method to used in component for custom cleanup on leave
 * @returns setup for beforeunload handling
 */
export const mixBeforeUnload = ({ showPrompt, callbackOnLeave, warningText }) => {
  const promptText = warningText || mixins.methods.mixWB('LEAVING_PAGE_WARNING_TEXT')

  return {
    methods: {
      onBeforeLeavingPage(event) {
        if (!this[showPrompt]) {
          return undefined
        }

        event.returnValue = promptText
        return event.returnValue
      },
    },
    beforeRouteLeave(to, from, next) {
      if (!this[showPrompt]) {
        next()

        return
      }

      const answer = window.confirm(promptText)
      if (answer) {
        next()
      }
    },
    created() {
      window.addEventListener('beforeunload', this.onBeforeLeavingPage)
    },
    beforeDestroy() {
      if (callbackOnLeave) {
        this[callbackOnLeave]()
      }

      window.removeEventListener('beforeunload', this.onBeforeLeavingPage)
    },
  }
}
