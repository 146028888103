/* eslint-disable no-use-before-define */
import EventBus from '@/EventBus'
import { storage } from '@/firebase/init'
import { keys } from '@/globals/javascript/_util/keys'
import { mixGetSizeWordbook } from '@/globals/javascript/_util/mixins'
import { getLocalStorageValue, setLocalStorageValue } from '@/globals/javascript/_util/util'
import { clone } from 'lodash-es'
import * as Sentry from '@sentry/browser'

export const ImageUpload = {
  state: {
    imageUploadStatuses: {
      INITIAL: 'initial',
      UPLOADING: 'uploading',
      SUCCESS: 'success',
      FAILED: 'failed',
    },
    imageUploadList: [],
    showUploadStatusList: false,
  },
  mutations: {
    setPendingUploadListOnLoad: (state) => {
      let pendingUploadList = getLocalStorageValue({
        key: keys.LS_PENDING_IMAGES_FOR_UPLOAD,
        defaultValue: [],
      })

      // Remove all unimportant items
      pendingUploadList = pendingUploadList.filter((x) => !['success', 'failed'].includes(x.status))

      // Set rest to failed
      pendingUploadList = pendingUploadList.map((item) => {
        item.status = state.imageUploadStatuses.FAILED
        return item
      })

      // Set store
      state.imageUploadList = pendingUploadList

      // Set local storage
      setLocalStorageValue({
        key: keys.LS_PENDING_IMAGES_FOR_UPLOAD,
        value: JSON.stringify(state.imageUploadList),
      })

      // Show list if any important items
      if (state.imageUploadList.length) {
        state.showUploadStatusList = true
        setLocalStorageValue({
          key: keys.LS_SHOW_UPLOADING_STATUS_LIST,
          value: state.showUploadStatusList,
        })
        requestAnimationFrame(() => {
          EventBus.$emit('expand-upload-list')
        })
      }
    },
    setShowUploadStatusList: (state, answer) => {
      state.showUploadStatusList = answer
      setLocalStorageValue({ key: keys.LS_SHOW_UPLOADING_STATUS_LIST, value: answer })
    },
    addToPendingUploadList: (state, { imageObject, place }) => {
      const newItems = []
      const rawUploadItem = {
        text: '',
        path: '',
        status: '',
        uploadedPercentage: 0,
      }

      // Add base size
      const baseItem = clone(rawUploadItem)
      baseItem.text = `${ place } - ${ mixGetSizeWordbook('base') }`
      baseItem.path = imageObject.base.path
      baseItem.status = state.imageUploadStatuses.INITIAL
      newItems.unshift(baseItem)

      // Add other sizes
      imageObject.sizes.forEach((size) => {
        const sizeItem = clone(rawUploadItem)
        sizeItem.text = `${ place } - ${ mixGetSizeWordbook(size.name) }`
        sizeItem.path = size.path
        sizeItem.status = state.imageUploadStatuses.INITIAL
        newItems.unshift(sizeItem)
      })

      // Set store
      state.imageUploadList = newItems.concat(state.imageUploadList)

      // Set local storage
      setLocalStorageValue({
        key: keys.LS_PENDING_IMAGES_FOR_UPLOAD,
        value: JSON.stringify(state.imageUploadList),
      })
    },
    setPendingUploadItemPercentage: (state, { path, percentage }) => {
      const item = state.imageUploadList.find((x) => x.path === path)

      item.status = state.imageUploadStatuses.UPLOADING
      item.uploadedPercentage = percentage
    },
    setPendingUploadItemState: (state, { path, status }) => {
      const item = state.imageUploadList.find((x) => x.path === path)

      item.status = status

      // Set localStorage
      setLocalStorageValue({
        key: keys.LS_PENDING_IMAGES_FOR_UPLOAD,
        value: JSON.stringify(state.imageUploadList),
      })
    },
    clearUploadList: (state) => {
      state.imageUploadList = state.imageUploadList.filter((x) => !['success', 'failed'].includes(x.status))

      // Set localStorage
      setLocalStorageValue({
        key: keys.LS_PENDING_IMAGES_FOR_UPLOAD,
        value: JSON.stringify(state.imageUploadList),
      })
    },
  },
  actions: {
    setupUploadStatusListOnLoad: ({ commit }) => {
      // Set list state
      const answer = getLocalStorageValue({
        key: keys.LS_SHOW_UPLOADING_STATUS_LIST,
        defaultValue: false,
      })
      commit('setShowUploadStatusList', answer)

      // Set list items
      commit('setPendingUploadListOnLoad')
    },
    toggleShowUploadStatusList: ({ commit, getters }) => {
      commit('setShowUploadStatusList', !getters.showUploadStatusList)
    },
    async uploadImage({ commit, getters }, {
      imageObject,
      file,
      base64URL,
      place = '',
      makeSquared = false,
      makePortrait = false,
    }) {
      // Push to pending upload list
      commit('addToPendingUploadList', { imageObject, place })

      // 1. Get dataURL
      const fileType = 'image/jpeg'
      let dataURL = base64URL ?? await getDataURL({ file })
      let canvas = null

      // 2. Resize image
      const response = await getResizedDataURL({
        dataURL,
        fileType,
        minSize: imageObject.base.minSize,
      })
      dataURL = response.dataURL
      canvas = response.canvas

      // 3. Make image portrait
      if (makePortrait) {
        const response = await getPortraitDataURL({ dataURL, fileType })
        if (response.dataURL) {
          dataURL = response.dataURL
          canvas = response.canvas
        }
      }

      // 4. Make squared
      if (makeSquared) {
        const response = await getSquaredDataURL({ dataURL, fileType })
        if (response.dataURL) {
          dataURL = response.dataURL
          canvas = response.canvas
        }
      }

      // Emit final data url is created
      EventBus.$emit('image-data-url-created', { basePath: imageObject.base.path, dataURL })

      // 4. Upload image
      uploadBlob(imageObject, canvas, commit, getters)
    },
    clearUploadList: ({ commit }) => {
      commit('clearUploadList')
    },
  },
  getters: {
    imageUploadStatuses: (state) => state.imageUploadStatuses,
    imageUploadList: (state) => state.imageUploadList,
    showUploadStatusList: (state) => state.showUploadStatusList,
  },
}

// Helpers
const getDataURL = ({ file }) => new Promise((resolve) => {
  const reader = new FileReader()
  reader.onload = (e) => {
    resolve(e.target.result)
  }
  reader.readAsDataURL(file)
})

const getResizedDataURL = ({ dataURL, fileType, minSize }) => new Promise((resolve) => {
  const img = document.createElement('img')
  img.onload = () => {
    const imageWidth = img.width
    const imageHeight = img.height

    // Set size
    let ratio = 1
    if (imageWidth <= imageHeight) {
      ratio = minSize / imageWidth
    }
    else {
      ratio = minSize / imageHeight
    }

    const canvasWidth = imageWidth * ratio
    const canvasHeight = imageHeight * ratio

    const canvas = document.createElement('canvas')
    canvas.width = canvasWidth
    canvas.height = canvasHeight
    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight)

    const dataURL = canvas.toDataURL(fileType)
    resolve({ dataURL, canvas })
  }
  img.src = dataURL
})

const getPortraitDataURL = ({ dataURL, fileType }) => new Promise((resolve) => {
  const img = document.createElement('img')
  img.onload = () => {
    const imageWidth = img.width
    const imageHeight = img.height

    if (imageHeight >= imageWidth) {
      resolve('already-portrait')
      return
    }

    const degrees = 270
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (degrees === 90 || degrees === 270) {
      canvas.width = img.height
      canvas.height = img.width
    }
    else {
      canvas.width = img.width
      canvas.height = img.height
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height)
    if (degrees === 90 || degrees === 270) {
      ctx.translate(img.height / 2, img.width / 2)
    }
    else {
      ctx.translate(img.width / 2, img.height / 2)
    }
    ctx.rotate((degrees * Math.PI) / 180)
    ctx.drawImage(img, -img.width / 2, -img.height / 2)

    const dataURL = canvas.toDataURL(fileType)
    resolve({ dataURL, canvas })
  }
  img.src = dataURL
})

const getSquaredDataURL = ({ dataURL, fileType }) => new Promise((resolve) => {
  const img = document.createElement('img')
  img.onload = () => {
    const imageWidth = img.width
    const imageHeight = img.height

    if (imageHeight === imageWidth) {
      resolve('already-squared')
      return
    }

    const minimumSize = Math.min(img.width, img.height)
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    canvas.height = minimumSize
    canvas.width = minimumSize
    context.save()

    context.translate(
      -(img.width - canvas.width) / 2,
      -(img.height - canvas.height) / 2,
    )
    context.drawImage(
      img,
      0, 0, img.width, img.height,
    )
    context.restore()

    const dataURL = canvas.toDataURL(fileType)
    resolve({ dataURL, canvas })
  }
  img.src = dataURL
})

const uploadBlob = (imageObject, canvas, commit, getters) => {
  const { path } = imageObject.base

  canvas.toBlob((blob) => {
    // Create the file metadata
    const metadata = {
      cacheControl: 'public,max-age=10800', // 3 hours
    }

    // Upload file and metadata
    const uploadTask = storage.ref().child(path).put(blob, metadata)

    EventBus.$emit('image-upload-started', { path })

    // Follow upload progress
    uploadTask.on('state_changed', (state) => {
      commit('setPendingUploadItemPercentage', {
        path,
        percentage: Math.ceil((state.bytesTransferred / state.totalBytes) * 100),
      })
    }, (err) => {
      commit('setPendingUploadItemState', { path, status: getters.imageUploadStatuses.FAILED })
      commit('setShowUploadStatusList', true)
      requestAnimationFrame(() => {
        EventBus.$emit('expand-upload-list')
      })

      EventBus.$emit('image-upload-failed', { path })

      Sentry.captureException(err)
    }, () => {
      commit('setPendingUploadItemState', { path, status: getters.imageUploadStatuses.SUCCESS })

      EventBus.$emit('image-uploaded', { path })
    })
  }, 'image/jpeg', 0.70)
}
