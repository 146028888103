import { db } from '@/firebase/init'
import Screening from '@/globals/javascript/models/Screening'
import EventBus from '@/EventBus'
import { DB_SCREENINGS, DB_UNITS } from '@/globals/javascript/models/_helper'

export const units = {
  getScreeningUnits: ({ commit, getters }, screeningID) => {
    if (getters.currentScreeningUnitsLoaded) {
      return
    }

    const unsubscribe = db
      .collection(DB_SCREENINGS)
      .doc(screeningID)
      .collection(DB_UNITS)
      .onSnapshot((querySnapshot) => {
        const units = []
        querySnapshot.forEach((doc) => {
          units.push(doc.data())
        })
        commit('updateCurrentScreeningUnits', { units, unsubscribe })
      })
  },
  async saveScreeningUnits({ getters, dispatch }, { combinedList, updateSelectedUnits = true }) {
    const { currentScreeningData } = getters
    const { units } = getters.currentScreening

    // Setup data
    const colRef = db
      .collection(DB_SCREENINGS)
      .doc(getters.currentScreening.id)
      .collection(DB_UNITS)

    // Check for existing units and delete these
    if (!currentScreeningData.hasSavedBBRUnits && units.length) {
      // Create batches for units to be deleted
      const unitsCopy = [...units]
      const deleteBatchList = []

      while (unitsCopy.length !== 0) {
        const newList = unitsCopy.splice(0, 250)
        const batch = db.batch()

        newList.forEach((unit) => {
          const docRef = colRef.doc(unit.id)
          batch.delete(docRef)
        })

        deleteBatchList.push(batch)
      }

      // Run all batches
      const deleteBatchPromises = []
      deleteBatchList.forEach((batch) => {
        const batchPromise = batch.commit()
        deleteBatchPromises.push(batchPromise)
      })

      const results = await Promise.all(deleteBatchPromises.map((p) => p.catch((e) => e)))

      // Detect any errors
      const errorFound = results.find((result) => (result instanceof Error))

      if (errorFound) {
        // Emit and throw error
        EventBus.$emit('all-units-save-error')
        throw new Error('ERROR: Unable to delete units')
      }
    }

    // Create unit batches
    const combinedListCopy = [...combinedList]
    const batchList = []

    while (combinedListCopy.length !== 0) {
      const newList = combinedListCopy.splice(0, 250)
      const batch = db.batch()

      newList.forEach((unit) => {
        const docRef = colRef.doc(unit.id)
        batch.set(docRef, { ...unit })
      })

      batchList.push(batch)
    }

    // Run all batches
    const batchPromises = []
    batchList.forEach((batch) => {
      const batchPromise = batch.commit()
      batchPromises.push(batchPromise)
    })

    const results = await Promise.all(batchPromises.map((p) => p.catch((e) => e)))

    // Detect any errors
    const errorFound = results.find((result) => (result instanceof Error))

    if (errorFound) {
      // Emit and throw error
      EventBus.$emit('all-units-save-error')
      throw new Error('ERROR: Unable to create units')
    }

    // Update screening
    const screening = new Screening({
      ...getters.currentScreeningData,
    })

    screening.hasSavedBBRUnits = true

    // Update selected units
    if (updateSelectedUnits) {
      const {
        selectedIDs,
        selectedBuildings,
      } = combinedList.reduce((prev, unit) => {
        if (!unit.isSelected) {
          return prev
        }

        prev.selectedIDs.push(unit.id)

        if (unit.type !== 'building') {
          return prev
        }

        const shallowBuilding = {
          id: unit.id,
          type: unit.type,
          bbrBuildingNo: unit?.bbrData?.Bygningsnr || '',
          customTitle: unit?.manualData?.title || '',
        }

        prev.selectedBuildings.push(shallowBuilding)

        return prev
      }, {
        selectedIDs: [],
        selectedBuildings: [],
      })

      const {
        outsideCategoryIDs,
        insideCategoryIDs,
      } = getters.categoriesAsArray.reduce((prev, category) => {
        if (category.areaID === 'AREA-1') {
          prev.outsideCategoryIDs.push(category.id)
        }
        if (category.areaID === 'AREA-2') {
          prev.insideCategoryIDs.push(category.id)
        }
        return prev
      }, {
        outsideCategoryIDs: [],
        insideCategoryIDs: [],
      })

      // Set building parts as skipped
      combinedList.forEach((unit) => {
        if (!unit.isSelected) {
          return
        }

        // - Check to skip inner building parts for buildings
        if (unit.type === 'building') {
          const hasSelectedChild = !!combinedList.find((x) => x.type !== 'building' && x.data.buildingID === unit.id && x.isSelected)
          if (hasSelectedChild) {
            screening.skippedCategories[unit.id] = [
              ...new Set([
                ...screening.skippedCategories[unit.id] ?? [],
                ...insideCategoryIDs,
              ]),
            ]
          }
          return
        }

        // - Skip outer building parts for floors and apartments
        if (['floor', 'apartment'].includes(unit.type)) {
          screening.skippedCategories[unit.id] = [
            ...new Set([
              ...screening.skippedCategories[unit.id] ?? [],
              ...outsideCategoryIDs,
            ]),
          ]
        }
      })

      screening.selectedUnits.count = selectedIDs.length
      screening.selectedUnits.ids = selectedIDs
      screening.selectedUnits.buildings = selectedBuildings
    }

    dispatch('updateScreening', { screening, setAsNotCompleted: true })

    // Emit all done event
    EventBus.$emit('all-units-saved')
  },
  removeScreeningUnits: ({ getters, dispatch }, {
    removedUnits = [],
    updateScreening = true,
  }) => {
    if (!removedUnits.length) {
      return
    }

    // Remove units
    removedUnits.forEach(
      (unit) => {
        db.collection(DB_SCREENINGS)
          .doc(getters.currentScreening.id)
          .collection(DB_UNITS)
          .doc(unit.id)
          .delete()
      },
    )

    if (updateScreening) {
      dispatch('updateScreening', { setAsNotCompleted: true })
    }
    EventBus.$emit('all-units-saved')
  },
}
