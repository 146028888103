import Vue from 'vue'
import { mapGetters } from 'vuex'
import router from '@/router'
import store from './store'

const EventBus = new Vue({
  store,
  router,
  name: 'event-bus',
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'screeningTypes',
      'categoriesAsArray',
      'skippedCategories',
      'currentScreeningSelectedFilterUnitID',
    ]),
    currentRoute() {
      return router.currentRoute.name
    },
    nextCategory() {
      const data = {
        path: null,
        nextCategoryID: null,
        isCompleted: false,
        isActiveStep: router.currentRoute.name === 'ScreeningsUpdateCategory',
      }

      const currentCategoryID = this.$route.params.categoryID || ''

      let nextCategoryID = ''
      this.categoriesAsArray.forEach((category) => {
        if (nextCategoryID) {
          return
        }

        if (category.id === currentCategoryID) {
          return
        }

        // Check if category has any types
        let hasTypes = null
        if (this.currentScreeningSelectedFilterUnitID) {
          hasTypes = this.screeningTypes.find(
            (x) => x.categoryID === category.id
            && x.unitIDs.includes(this.currentScreeningSelectedFilterUnitID),
          )
        }
        else {
          hasTypes = this.screeningTypes.find((x) => x.categoryID === category.id)
        }

        if (hasTypes) {
          return
        }

        // Set skipped status
        let isSkipped = this.skippedCategories.general.includes(category.id)
        if (!isSkipped && this.currentScreeningSelectedFilterUnitID) {
          isSkipped = this.skippedCategories[
            this.currentScreeningSelectedFilterUnitID
          ].includes(category.id)
        }
        if (!isSkipped) {
          isSkipped = !Object.keys(this.skippedCategories).find(
            (key) => key !== 'general' && !this.skippedCategories[key].includes(category.id),
          )
        }

        if (isSkipped) {
          return
        }

        nextCategoryID = category.id
      })

      if (!nextCategoryID) {
        data.isCompleted = true
        return data
      }

      // Set next path
      data.path = {
        name: 'ScreeningsUpdateCategory',
        params: {
          screeningID: this.currentScreening.id,
          categoryID: nextCategoryID,
        },
      }
      data.nextCategoryID = nextCategoryID

      return data
    },
  },
})

export default EventBus
